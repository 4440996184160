

import { Fragment, useRef, useState,useEffect,useContext } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon,PencilSquareIcon  } from '@heroicons/react/24/outline'
import { UserContext } from '../../contexts/user.context'
import { formatTin } from '../../utils/ValidationField'
import { SentenceCaseText } from '../../utils/ValidationField';
import Swal from 'sweetalert2';
const WithHeldRates =() =>{
    const [open, setOpen] = useState(true);

    const cancelButtonRef = useRef(null);
    const { company_id,Trade_name,Tin,Reg_address} = useContext(UserContext);


    
   
    const [Searchdata, setInputValue] = useState('');
    const [isNodata, setNodata] = useState(false);

    
    const [allList, setallList] = useState([]);

    const [classListRates, setClassRates] = useState([]);

    useEffect( () => {
        // setLoad(false);
    
        LoadAllList();
        LoadCategoryList();
    }, []); 

         
    const LoadCategoryList = async () => {

      console.log(company_id);

      const postData = {
        "company_id": company_id,
      };
     
      
      
      const requestOptions = {
        method: 'POST',
        headers: 
        { 'Content-Type': 'application/json',
        'Accept': 'application/json', 
         'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
        body: JSON.stringify(postData)
      };
  
   await fetch(process.env.REACT_APP_API_URL+'api/setting/withholding/category', requestOptions)
      .then( async response => 
        {
          // Check the status code
          if (response.status === 422) {

            //  setNodata(false);
             
          }else{
            const data = await response.json();
            setClassRates(data.data); 
       
     
          }
        }
        
      )
      .then(data => {
        // Handle the response
     
    
        setLoad(true);
      })
      .catch(error => {
        // Handle any errors
     
      });
      setLoad(true);
  } 


    const LoadAllList = async () => {



        const postData = {
          "search":Searchdata,
          "company_id": company_id,
          "type": 'expense',
      
        };
       
        
        
        const requestOptions = {
          method: 'POST',
          headers: 
          { 'Content-Type': 'application/json',
          'Accept': 'application/json', 
           'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
          body: JSON.stringify(postData)
        };
    
     await fetch(process.env.REACT_APP_API_URL+'api/setting/classification/list', requestOptions)
        .then( async response => 
          {
         
            if (response.status === 422) {
              // API call succeeded
  
             // const data = await response.json();
           
              // handleData(data.message);
               setNodata(false);
               
            }else{
              const data = await response.json();
             
              setallList(data.data); 
              console.log(data.data);

              

            
            }
          }
          
        )
        .then(data => {
          // Handle the response
       
      
          setLoad(true);
        })
        .catch(error => {
          // Handle any errors
       
        });
        setLoad(true);
    } 
    const handlePercentageChange = (id, value) => {
        setallList(prevList =>
            prevList.map(item =>
                item.id === id ? { ...item, percentage: value } : item
            )
        );
    };


    const hadleChangeCategory_id = (id, cat_id,value,text) => {
    
      if(text=='Others'){
        document.getElementById(id+"rate").readOnly = false;
      }else{
        document.getElementById(id+"rate").readOnly = true;
      }
   
      setallList(prevList =>
          prevList.map(item =>
              item.id === id ? { ...item, withhold_cat_id: cat_id } : item
          )
      );
      setallList(prevList =>
        prevList.map(item =>
            item.id === id ? { ...item, percentage: value } : item
        )
    );

      console.log(allList);
  };

    // Handler to update ATC
    const handleAtcChange = (id, value) => {
        setallList(prevList =>
            prevList.map(item =>
                item.id === id ? { ...item, atc: value } : item
            )
        );
    };
    const SaveWithHeldRate =  () => {

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
      })
      

      swalWithBootstrapButtons.fire({
        title: 'Are you sure?',
        text: "Do you want to save",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Save',
        cancelButtonText: 'Cancel',
        reverseButtons: true
      }).then(async (result) => {
        if (result.isConfirmed) {

          let ClassRates =[];

          allList.forEach(element => {
    
            const object = {
               class: element.class,
            percentage: (document.getElementById(element.id+'rate').value !='' && document.getElementById(element.id+'rate').value !=null)? parseFloat(document.getElementById(element.id+'rate').value) :0,
            atc: document.getElementById(element.id+'atc').value,
            withhold_cat_id: element.withhold_cat_id
          };
            ClassRates.push(object);
          });

          console.log(ClassRates);
          
          const postData = {
            "class":ClassRates,
            "company_id":company_id
          };

          const requestOptions = {
            method: 'POST',
            headers: 
            { 'Content-Type': 'application/json',
            'Accept': 'application/json', 
             'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
            body: JSON.stringify(postData)
          };
  
          await fetch(process.env.REACT_APP_API_URL+'api/setting/classification/update/rate', requestOptions)
          .then( async response => 
            {
                console.log(response);
              // Check the status code
              if (response.status === 422) {
                // API call succeeded
  
                const data = await response.json();
            
              
              }else{
                const data = await response.json();
                console.log(data);
              
                Swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  title: 'Update rate successfully',
                  showConfirmButton: false,
                  timer: 1500
              })  
              }
            }
            
          )
          .then(data => {
            // Handle the response
            console.log(data);
          })
          .catch(error => {
            // Handle any errors
            console.error(error);
          });

        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
  
        }
      })
      console.log(allList);

     
    }
    const [isLoad, setLoad] = useState(true);
    return (
        <div>
        <div className="sm:flex sm:items-center mt-4 mb-5">
            <div className="sm:flex-auto">
                <h1 className="text-2xl font-semibold leading-6 text-gray-900 capitalize ">Withholding Rates</h1>
        
            </div>

        </div>
        <div className= {isLoad? 'mt-2 flow-root  ':'mt-2 flow-root uppercase rounded shadow animate-pulse md:p-6 dark:border-gray-700'}>
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                            <tr>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                              Expense Account
                            </th>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                              Category
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Percentage
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                ATC
                            </th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                        {allList.map((Classification) => (
                        <tr key={Classification.id}>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 ">
                            {Classification.class}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <select  
                              className=" p-2.5 block w-full
                                rounded-md border-0 py-1.5 text-gray-900 
                                shadow-sm ring-1 ring-inset ring-gray-300
                                placeholder:text-gray-400 focus:ring-2
                                focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                name="modalVatclass"
                                onChange={(e) => {
                                  const selectedOption = e.target.selectedOptions[0];
                                  const value = e.target.value; // Get the value
                                  const title = selectedOption.getAttribute('title'); // Get the title
                                  const text = selectedOption.text; // Get the text
                                  hadleChangeCategory_id(Classification.id, value, title, text); // Pass all values
                                }}
                                required>
                                    <option ></option>
                                    {classListRates.map((Category) => (
                                       <>
                                            <option value={Category.id} title={Category.rate}
                                            
                                            selected={parseInt(Classification.withhold_cat_id) === parseInt(Category.id)} // Conditional selection

                                            >{Category.category}</option>
                                       </>
                                    ))}
                              </select>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                <input
                                 id={Classification.id + "rate"}
                                 name={Classification.id + "rate"}
                                 type="number"
                                 value={Classification.percentage}
                                 onChange={(e) => handlePercentageChange(Classification.id, e.target.value)}
  
                                className="px-4 block w-100 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                <input
                                 id={Classification.id + "atc"}
                                 name={Classification.id + "atc"}
                                 type="text"
                                 value={Classification.atc}
                                 onChange={(e) => handleAtcChange(Classification.id, e.target.value)}
                                className="px-4 block w-100 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </td>
                        </tr>))}
                        </tbody>
                        </table>
                    </div>
                    <div className="mt-6 flex items-center justify-end gap-x-6">
                      
                        <button
                        type="button"
                        onClick={SaveWithHeldRate}
                        className="rounded-md bg-indigo-600 px-4 py-4 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                        Save
                        </button>
                    </div>
                </div>
            </div>
            </div>
      </div>);

}
export default WithHeldRates;