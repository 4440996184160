

import { Fragment, useRef, useState,useEffect,useContext } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon,PencilSquareIcon  } from '@heroicons/react/24/outline'
import { UserContext } from '../../contexts/user.context'
import { formatTin,formatAmountDisplay } from '../../utils/ValidationField'
const TaxModulePrint =(props) =>{
    const [open, setOpen] = useState(true);

    const cancelButtonRef = useRef(null);
    const { company_id,Trade_name,Tin,Reg_address} = useContext(UserContext);

  
    const items = Array.from({ length: props.CountAdded }, (_, index) => index + 1);


    const [suggestions, setSuggestions] = useState([]);

    const [count, setCount] = useState(0);
    const defaultFormFields = {
        // selectype: '',
        seller:'',
        address:'',
        tin1: '',
        tin2: '',
        fromPeriod:'',
        toPeriod:'',
      };
      const [formFields,setFormFields ] = useState(defaultFormFields);
      const { seller,address,tin1,tin2,fromPeriod,toPeriod} = formFields;


      const handleChange = (event) => {
        const { name, value } = event.target;

        if(name==='seller'){
          SearchSupplier();

          const selectedValue = event.target.value.toUpperCase();
          const selectedItem = suggestions.find(item => item.description.toUpperCase() === selectedValue);
          if (selectedItem) {
              const selectedId = selectedItem.id;
              // Now you have the selected ID
              console.log("Selected ID:", selectedId);
              formFields.tin1=selectedItem.tin;
              formFields.tin2=selectedItem.branch;
              formFields.address=selectedItem.address;
           

           
              // You can use this selectedId however you need
          }else{
              formFields.tin1='';
              formFields.tin2='';
              formFields.address='';
          }

   
        }
      
      const formattedValue =
    
      name === 'tin1' ? formatTin(value) :value;
     
     
    
      
        setFormFields({ ...formFields, [name]: formattedValue });

      };
      

      const SearchSupplier = async () => {



        const postData = {
          "searchdata":seller,
          "company_id": company_id,
      
        };
       
        
        
        const requestOptions = {
          method: 'POST',
          headers: 
          { 'Content-Type': 'application/json',
          'Accept': 'application/json', 
           'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
          body: JSON.stringify(postData)
        };
    
     await fetch(process.env.REACT_APP_API_URL+'api/setting/supplier/search_list', requestOptions)
        .then( async response => 
          {
             console.log(response);
            // Check the status code
            if (response.status === 422) {
  
               
            }else{
              const data = await response.json();
        
  
              setSuggestions(data.data)
              
            }
          }
          
        )
        .then(data => {
          // Handle the response
       
      
        //   setLoad(true);
        })
        .catch(error => {
          // Handle any errors
       
        });
        // setLoad(true);
    } 
  
    const loadPdf = async () => {

        console.log(count);
        setOpen(false);

        
            try {
              // URL of the PDF file
                        const pdfUrl = process.env.PUBLIC_URL + '/2307-PRIVATE-EMPLOYEE-2.pdf';
                
                    // Initialize PDF.js
                    const pdfjsLib = window['pdfjs-dist/build/pdf'];

                    // Set worker source for PDF.js
                    pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js';
            
                    // Fetch PDF document
                    const pdf = await pdfjsLib.getDocument(pdfUrl).promise;
            
                    // Fetch the first page of the PDF (page index starts from 1)
                    const page = await pdf.getPage(1);
            
                    // Render PDF page on a canvas
                    const canvas = document.createElement('canvas');
                    const context = canvas.getContext('2d');
                    const viewport = page.getViewport({ scale: 1.5 });
                    canvas.width = viewport.width;
                    canvas.height = viewport.height;
                    await page.render({ canvasContext: context, viewport: viewport }).promise;
            
                    // Append canvas to the specified element (pdfViewer)
                    const pdfViewer = document.getElementById('pdfViewer');
                    
                    if(count==0){
                        pdfViewer.appendChild(canvas);
                    }
                 
            
                    // Print the content of the specified element (pdfViewer)
                    window.print();
                    } catch (error) {
                    console.error('Error loading or printing PDF:', error);
                    }
        };

        useEffect(() => {
            // Run the effect only once when the component mounts
            if (count === 0) {
                loadPdf();
                setCount(1); // Update the count to avoid reloading PDF
            }
        }, [count]); // Dependency on count
    
        const sumItem = (data, key) => {
            return data.reduce((total, item) => parseFloat(total) + parseFloat(item[key]), 0);
        };
        
    return (
        <div>
       {/* <button className='print-hide' onClick={loadPdf}>Print</button> */}

        <div className="sm:flex sm:items-center mt-4">
          <div className="sm:flex-auto">
          <div className="px-4 sm:px-6 lg:px-8 " id="pdfViewer">
                    <div className="grid grid-cols-12 px-2">
                            <div className="col-span-3">
                                <div className="flex items-end">
                                    <div>
                                        <p style={{ fontSize: '10px', marginTop: '10px' }} className="bg-gray-300">
                                            For BIR <br />
                                            Use Only
                                        </p>
                                    </div>
                                    <div className="ml-2">
                                        <p style={{ fontSize: '10px' }}>
                                            BCS/ <br />
                                            Item
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-span-4 col-start-5">
                                <div className="flex">
                                    <div>
                                        <img className="h-auto w-auto" src={process.env.PUBLIC_URL + '/bir_logo.png'} id="logoBir"style={{ height: '40px'}} />
                                    </div>
                                    <div className="fontsize4px font-semibold pl-2 text-center">
                                        Republic of the Philippines <br />
                                        Department of Finance <br />
                                        Bureau of Internal Revenue
                                    </div>
                                </div>
                            </div>
                            </div>
                        <div className="border border-black">
                            <div className="grid grid-cols-12 px-2">
                                <div className="col-span-3 nowrap border-r border-black">
                                    <div className="text-center -mt-2">
                                        <p className="calibri text-xl text-black capitalize" style={{ fontSize: '10px' }}>
                                            BIR Form No.
                                        </p>
                                        <p className="calibri text-3xl text-black font-bold -mt-2" >
                                            2307
                                        </p>
                                        <p className="calibri text-xl text-black capitalize -mt-2" style={{ fontSize: '10px' }}>
                                            July 2008 (ENCS)
                                        </p>
                                    </div>
                                </div>
                                <div className="col-span-6">
                                    <p className="text-4xl text-center calibri font-semibold nowrap capitalize mt-1" style={{ fontSize: '20px' }}>
                                        Certificate of Creditable Tax <br />
                                        Withheld at Source
                                    </p>
                                </div>
                                <div className="col-span-3 border-l border-black">
                                    <img className="h-12 w-full mx-auto" src={process.env.PUBLIC_URL + '/qrcode.png'} />
                                    <p className="text-right" style={{ fontSize: '10px' }}>
                                        2307 01/18ENCS
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="border-l border-r border-b border-black fontsize4px text-xs pl-2">
                                Fill in all applicable spaces. Mark all appropriate boxes with an "X"
                        </div>
                        <div className="grid grid-cols-12 cccc h-5 border-r border-b border-black border-l" style={{ fontSize: '8px' }}>
                            <div className="col-span-6">
                                <div className="grid grid-cols-12">
                                    <div className="col-span-1 pl-1 fontsize4px">
                                        <strong>
                                            1
                                        </strong>
                                    </div>
                                    <div className="col-span-2 nowrap">
                                        <p className="fontsize4px">
                                            For the Period
                                        </p>
                                    </div>
                                    <div className="col-span-6 col-start-5">
                                        <div className="flex">
                                            <div className="mr-1">
                                                From
                                            </div>
                                            <div>
                                              
                                                <div className="flex bg-white border border-black" style={{ fontSize: '12px', height: '18px' }}>
                                                    <div className="w-4">
                                                    {props.from_date.substring(5,7)}
                                                    </div>
                                                    <div className="w-4 borderLine_left_short_2 pr-4">  </div>
                                                    <div className="w-4 border-l border-black">{props.from_date.substring(8,10)}</div>
                                                    <div className="w-4 borderLine_left_short_2"></div>
                                                    <div className="w-4 border-l border-black"></div>
                                                    <div className="w-4 borderLine_left_short_2">{props.from_date.substring(0,4)}</div>
                                                    <div className="w-4 borderLine_left_short_2"></div>
                                                    <div className="w-4 borderLine_left_short_2"></div>
                                                </div>
                                            </div>
                                            <div className="ml-2">
                                                (MM/DD/YYYY)
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-6">
                                <div className="col-span-6">
                                    <div className="grid grid-cols-12">
                                        <div className="col-span-8 col-start-4">
                                            <div className="flex" style={{ fontSize: '8px' }}>
                                                <div className="mr-1">
                                                    To
                                                </div>
                                                <div>
                                                    <div className="flex bg-white border border-black" style={{ fontSize: '12px', height: '18px' }}>
                                                        <div className="w-4">
                                                            <p className="w-4 h-3 text-center">
                                                                {props.to_date.substring(5,7)}
                                                            </p>
                                                        </div>
                                                        <div className="w-4 borderLine_left_short_2"></div>
                                                        <div className="w-4 border-l border-black">{props.to_date.substring(8,10)}</div>
                                                        <div className="w-4 borderLine_left_short_2"></div>
                                                        <div className="w-4 border-l border-black"></div>
                                                        <div className="w-4 borderLine_left_short_2">{props.to_date.substring(0,4)}</div>
                                                        <div className="w-4 borderLine_left_short_2"></div>
                                                        <div className="w-4 borderLine_left_short_2"></div>
                                                    </div>
                                                </div>
                                                <div className="ml-2">
                                                    (MM/DD/YYYY)
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ fontSize: '8px',backgroundColor: '#cccccc' }} className="h-3 text-center  mb border-l border-r border-b border-black">
                            <strong>
                                Part I – Payee Information
                            </strong>
                        </div>
                        <div className="grid grid-cols-12 cccc h-5 border-r border-b border-black border-l border-t" style={{ fontSize: '7px' }}>
                            <div className="col-span-12">
                                <div className="grid grid-cols-12">
                                    <div className="col-span-1 pl-1 fontsize4px">
                                        <strong>
                                            2
                                        </strong>
                                    </div>
                                    <div className="col-span-2 nowrap">
                                        <p className="fontsize4px">
                                            Taxpayer Identification Number (TIN)
                                        </p>
                                    </div>
                                  
                                    <div className="col-span-4 col-start-5">
                                        <div className="flex">
                                            <div>
                                                <div className="flex bg-white border border-black" style={{ fontSize: '12px', height: '18px' }}>
                                                    <div className="w-4">
                                                        <p className="w-4 h-3 text-center"> {Tin.substring(0, 3)}</p>
                                                    </div>
                                                    <div className="w-4 borderLine_left_short_2"></div>
                                                    <div className="w-4 borderLine_left_short_2"></div>
                                                    <div className="w-4 borderLine_left_short_2 border-l border-black shadecolor">
                                                        <p className="font-bold text-center">-</p>
                                                    </div>
                                                    <div className="w-4 border-l border-black text-center">{Tin.substring(4, 7)}</div>
                                                    <div className="w-4 borderLine_left_short_2"></div>
                                                    <div className="w-4 borderLine_left_short_2"></div>
                                                    <div className="w-4 border-l border-black shadecolor">
                                                        <p className="font-bold text-center">-</p>
                                                    </div>
                                                    <div className="w-4 border-l border-black text-center">{Tin.substring(8, 11)}</div>
                                                    <div className="w-4 borderLine_left_short_2"></div>
                                                    <div className="w-4 borderLine_left_short_2"></div>
                                                    <div className="w-4 border-l border-black shadecolor">
                                                        <p className="font-bold text-center">-</p>
                                                    </div>
                                                    <div className="w-4 border-l border-black text-center">{Tin.substring(12, 15)}</div>
                                                    <div className="w-4 borderLine_left_short_2"></div>
                                                    <div className="w-4 borderLine_left_short_2"></div>
                                                    <div className="w-4 borderLine_left_short_2"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="grid grid-cols-12 cccc border-r border-b border-black border-l border-t" style={{ fontSize: '8px' ,backgroundColor: '#cccccc'}}>
                            <div className="col-span-12">
                                <div className="flex">
                                    <div className="pl-1 pr-4">
                                        <strong>
                                            3
                                        </strong>
                                    </div>
                                    <div>
                                        Payee’s Name (Last Name, First Name, Middle Name for Individual OR Registered Name for Non-Individual)
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-12">
                                <div className="px-2">
                                    <div className="w-full h-4 bg-white mr-4 border border-black pl-2">
                                        {Trade_name}
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-12">
                                <div className="grid grid-cols-12">
                                    <div className="col-span-10">
                                        <div className="flex">
                                            <div className="pl-1 pr-4">
                                                <strong>
                                                    4
                                                </strong>
                                            </div>
                                            <div>
                                                Registered Address
                                            </div>
                                        </div>
                                        <div className="px-2">
                                            <div className="w-full h-4 bg-white mr-4 border border-black pl-2">
                                                {Reg_address}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-span-2">
                                        <div className="px-2">
                                            <strong>
                                                4A ZIP Code <br />
                                            </strong>
                                            <div className="w-full h-4 bg-white mr-4 border border-black">
                                                <div className="flex">
                                                    <div className="w-3/12">
                                                        <p className="w-3/12 h-3 text-center"></p>
                                                    </div>
                                                    <div className="w-3/12 borderLine_left_short_2"></div>
                                                    <div className="w-3/12 borderLine_left_short_2"></div>
                                                    <div className="w-3/12 borderLine_left_short_2"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-12 mb">
                                <div className="flex">
                                    <div className="pl-1 pr-4">
                                        <strong>
                                            5
                                        </strong>
                                    </div>
                                    <div>
                                        Foreign Address, if applicable
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-12 mb-2">
                                <div className="px-2">
                                    <div className="w-full h-4 bg-white mr-4 border border-black"></div>
                                </div>
                            </div>
                        </div>
                        <div className="border-black border-r border-l">
                            <p className="text-center " style={{ fontSize: '8px',backgroundColor: '#cccccc'  }}>
                                <strong>
                                Part II – Payor Information
                                </strong>
                            </p>
                            </div>
                            <div className="grid grid-cols-12  h-5 border-r border-b border-black border-l border-t" style={{ fontSize: '7px',backgroundColor: '#cccccc'  }}>
                            <div className="col-span-12">
                                <div className="grid grid-cols-12">
                                <div className="col-span-1 pl-1 fontsize4px">
                                    <strong>
                                    6
                                    </strong>
                                </div>
                                <div className="col-span-2 nowrap">
                                    <p className="fontsize4px">
                                    Taxpayer Identification Number (TIN)
                                    </p>
                                </div>
                                <div className="col-span-4 col-start-5">
                                    <div className="flex">
                                    <div>
                                        <div className="flex bg-white border border-black" style={{ fontSize: '12px', height: '18px' }}>
                                        <div className="w-4">
                                            <p className="w-4 h-3 text-center">
                                            
                                            </p>
                                        </div>
                                        <div className="w-4 borderLine_left_short_2 text-center">
                                            {props.tin1.substring(0, 3)}
                                        </div>
                                        <div className="w-4 borderLine_left_short_2">
                                            
                                        </div>
                                        <div className="w-4 borderLine_left_short_2 border-l border-black shadecolor">
                                            <p className="font-bold text-center">
                                            -
                                            </p>
                                        </div>
                                        <div className="w-4 border-l border-black px-2 text-center">
                                        {props.tin1.substring(4, 7)}
                                        </div>
                                        <div className="w-4 borderLine_left_short_2">
                                            
                                        </div>
                                        <div className="w-4 borderLine_left_short_2">
                                            
                                        </div>
                                        <div className="w-4 border-l border-black shadecolor">
                                            <p className="font-bold text-center">
                                            -
                                            </p>
                                        </div>
                                        <div className="w-4 border-l border-black text-center">
                                             {props.tin1.substring(8, 11)}
                                        </div>
                                        <div className="w-4 borderLine_left_short_2">
                                            
                                        </div>
                                        <div className="w-4 borderLine_left_short_2">
                                        
                                        </div>
                                        <div className="w-4 border-l border-black shadecolor">
                                            <p className="font-bold text-center">
                                            -
                                            </p>
                                        </div>
                                        <div className="w-4 border-l border-black text-center">
                                            {props.tin2}
                                        </div>
                                        <div className="w-4 borderLine_left_short_2">
                                        
                                        </div>
                                        <div className="w-4 borderLine_left_short_2">
                                        
                                        </div>
                                        <div className="w-4 borderLine_left_short_2">
                                        
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="grid grid-cols-12  border-r border-b border-black border-l border-t" style={{ fontSize: '8px',backgroundColor: '#cccccc' }}>
                            <div className="col-span-12">
                                <div className="flex">
                                <div className="pl-1 pr-4">
                                    <strong>
                                    7
                                    </strong>
                                </div>
                                <div>
                                    Payor’s Name (Last Name, First Name, Middle Name for Individual OR Registered Name for Non-Individual)
                                </div>
                                </div>
                            </div>
                            <div className="col-span-12">
                                <div className="px-2">
                                <div className="w-full h-4 bg-white mr-4 border border-black px-2">
                                    {props.seller}
                                </div>
                                </div>
                            </div>
                            <div className="col-span-12 mb-2">
                                <div className="grid grid-cols-12">
                                <div className="col-span-10">
                                    <div className="flex">
                                    <div className="pl-1 pr-4">
                                        <strong>
                                        8
                                        </strong>
                                    </div>
                                    <div>
                                        Registered Address
                                    </div>
                                    </div>
                                    <div className="px-2">
                                    <div className="w-full h-4 bg-white  border border-black px-2">
                                    {props.address}
                                    </div>
                                    </div>
                                </div>
                                <div className="col-span-2">
                                    <div className="px-2">
                                    <strong>
                                        8A ZIP Code <br />
                                    </strong>
                                    <div className="w-full h-4 bg-white mr-4 border border-black">
                                        <div className="flex">
                                        <div className="w-3/12">
                                            <p className="w-3/12 h-3 text-center">
                                            </p>
                                        </div>
                                        <div className="w-3/12 borderLine_left_short_2">
                                        </div>
                                        <div className="w-3/12 borderLine_left_short_2">
                                        </div>
                                        <div className="w-3/12 borderLine_left_short_2">
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="border-black border-2">
                            <p className="text-center " style={{ fontSize: '8px',backgroundColor: '#cccccc'  }}>
                                <strong>
                                Part III – Details of Monthly Income Payments and Taxes Withheld
                                </strong>
                            </p>
                            </div>


                            <table className="min-w-full divide-y divide-gray-300" style={{ fontSize: "10px" }}>
                            <thead className="bg-gray-50">
                                <tr className="cccc border-black border-2" style={{ backgroundColor: '#cccccc'  }}>
                                <th scope="col" className="w-48 border-r-2 border-black" style={{ width: "35%" }}>
                                    Income Payments Subject to Expanded <br />
                                    Withholding Tax
                                </th>
                                <th scope="col" className="w-32 border-r border-black">
                                    <p className="px-4">
                                    ATC
                                    </p>
                                </th>
                                <th scope="col" className="border border-black border-2 w-full cccc" style={{ backgroundColor: '#cccccc'  }}>
                                    <div className="flex border-black border-b">
                                    <div className="mx-auto">
                                        AMOUNT OF INCOME PAYMENTS
                                    </div>
                                    </div>
                                    <div className="flex h-10">
                                    <div scope="col" className="w-1/3 border-r border-black nowrap">
                                        <p className="pt-4">
                                        1st Month of the <br />
                                        Quarter
                                        <br />
                                        </p>
                                    </div>
                                    <div scope="col" className="w-1/3 border-r border-black nowrap">
                                        <p className="pt-4">
                                        2nd Month of the <br />
                                        Quarter
                                        <br />
                                        </p>
                                    </div>
                                    <div scope="col" className="w-1/3 border-r border-black nowrap">
                                        <p className="pt-4">
                                        3rd Month of the <br />
                                        Quarter
                                        <br />
                                        </p>
                                    </div>
                                    <div scope="col" className="w-1/3 border-l border-black nowrap">
                                        <p className="pt-4">
                                        Total
                                        </p>
                                    </div>
                                    </div>
                                </th>
                                <th scope="col" className="w-20 border-l border-black nowrap">
                                    Tax Withheld for the <br />
                                    Quarter
                                </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                            {props.TaxList.map((item, index) => (
                             <tr key={index}>
                                <td className="whitespace-nowrap  pl-1  text-xs font-medium text-gray-900  opacity-100  border-b border-l border-black" style={{ width: "404px" }}>
                                    {item.classification}
                                </td>
                                <td className="whitespace-nowrap text-center text-xs font-medium text-gray-900 opacity-100 border-b border-l border-black" style={{ opacity: "0 !important" }}>
                                {item.atc}
                                </td>
                                <td className="whitespace-nowrap text-xs  text-right pr-4 font-medium text-gray-900 opacity-100 border-b border-l border-black" style={{ width: "404px", height: "5px", position: "relative", padding: "0" }}>
                                    <div style={{ display: "flex", height: "100%", margin: "0" }}>
                                        <div style={{ flex: 1, borderRight: "1px solid black", paddingRight: "2px"}}>
                                        {(item.quarter_1>0)? formatAmountDisplay(item.quarter_1) :''}  
                                        </div>
                                        <div style={{ flex: 1, borderRight: "1px solid black", paddingRight: "2px" }}>
                                        {(item.quarter_2>0)? formatAmountDisplay(item.quarter_2) :''}   
                                        </div>
                                        <div style={{ flex: 1, borderRight: "1px solid black", paddingRight: "2px" }}>
                                        {(item.quarter_3>0)? formatAmountDisplay(item.quarter_3) :''}   
                                        </div>
                                        <div style={{ flex: 1, paddingRight: "2px" }}>
                                        {(item.total>0)? formatAmountDisplay(item.total) :''}  
                                        </div>
                                    </div>
                                    <div style={{ position: "absolute", bottom: "0", right: "0", borderBottom: "1px solid black" }}>
                                       
                                    </div>
                                </td>

                                <td className="whitespace-nowrap  text-right text-xs font-medium text-gray-900  opacity-100 border-b border-l border-black border-r" style={{ width: "404px" }}>
                                {(item.total>0)? formatAmountDisplay(item.with_hold) :''}  
                                </td>
                                </tr>
                                ))}
                                {items.map(number => (
                                  <tr key={number}>
                                 <td className="whitespace-nowrap py-2 pl-2 pr-2 text-sm font-medium text-gray-900 sm:pl-6 opacity-100  border-b border-l border-black" style={{ width: "404px" }}>
                                     
                                 </td>
                                 <td className="whitespace-nowrap py-2 pl-2 pr-2 text-sm font-medium text-gray-900 sm:pl-6 opacity-100 border-b border-l border-black" style={{ opacity: "0 !important" }}>
                             
                                 </td>
                                 <td className="whitespace-nowrap text-sm font-medium text-gray-900 opacity-100 border-b border-l border-black" style={{ width: "404px", height: "5px", position: "relative", padding: "0" }}>
                                     <div style={{ display: "flex", height: "100%", margin: "0" }}>
                                         <div style={{ flex: 1, borderRight: "1px solid black", padding: "0" }}>
                                             
                                         </div>
                                         <div style={{ flex: 1, borderRight: "1px solid black", padding: "0" }}>
                             
                                         </div>
                                         <div style={{ flex: 1, borderRight: "1px solid black", padding: "0" }}>
                                             
                                         </div>
                                         <div style={{ flex: 1, padding: "0" }}>
                                             
                                         </div>
                                     </div>
                                     <div style={{ position: "absolute", bottom: "0", right: "0", borderBottom: "1px solid black" }}></div>
                                 </td>
                             
                                 <td className="whitespace-nowrap py-2 pl-2 pr-2 text-sm font-medium text-gray-900 sm:pl-6 opacity-100 border-b border-l border-black border-r" style={{ width: "404px" }}>
                             
                                 </td>
                                 </tr>
                                ))}
                                <tr >
                                <td className="whitespace-nowrap text-sm font-medium text-gray-900 opacity-100  border-b border-l border-black" style={{ width: "404px",backgroundColor: '#cccccc' }}>
                                   total
                                </td>
                                <td className="whitespace-nowrap py-2 pl-2 pr-2 text-xs font-medium text-gray-900 sm:pl-6 opacity-100 border-b border-l border-black" style={{ opacity: "0 !important" }}>
                                  
                                </td>
                                <td className="whitespace-nowrap text-xs font-medium text-right text-gray-900 opacity-100 border-b border-l border-black" style={{ width: "404px", height: "5px", position: "relative", padding: "0" }}>
                                    <div style={{ display: "flex", height: "100%", margin: "0" }}>
                                        <div style={{ flex: 1, borderRight: "1px solid black", padding: "0" }}>
                                            {formatAmountDisplay(sumItem(props.TaxList, 'quarter_1'))}
                                        </div>
                                        <div style={{ flex: 1, borderRight: "1px solid black", padding: "0" }}>
                                           {formatAmountDisplay(sumItem(props.TaxList, 'quarter_2'))}
                                        </div>
                                        <div style={{ flex: 1, borderRight: "1px solid black", padding: "0" }}>
                                            {formatAmountDisplay(sumItem(props.TaxList, 'quarter_3'))}
                                        </div>
                                        <div style={{ flex: 1, padding: "0" }}>
                                        {formatAmountDisplay(sumItem(props.TaxList, 'total'))}
                                        </div>
                                    </div>
                                    <div style={{ position: "absolute", bottom: "0", right: "0", borderBottom: "1px solid black" }}>
                                      
                                    </div>
                                </td>

                                <td className="whitespace-nowrap tsx vvvvvvbbbbsxsgxtext-xs font-medium text-gray-900 sm:pl-6 opacity-100 border-b border-l border-black border-r" style={{ width: "404px" }}>
                                {formatAmountDisplay(sumItem(props.TaxList, 'with_hold'))}
                                </td>
                                </tr>   
                            
                                <tr >
                                <td className="whitespace-nowrap text-xs font-medium text-gray-900 opacity-100  border-b border-l border-black" style={{ width: "404px",backgroundColor: '#cccccc' }}>
                                Money Payments Subject to Withholding of <br/>
                                Business Tax (Government & Private)
                                </td>
                                <td className="whitespace-nowrap py-2 pl-2 pr-2 text-sm font-medium text-gray-900 sm:pl-6 opacity-100 border-b border-l border-black" style={{ opacity: "0 !important" }}>

                                </td>
                                <td className="whitespace-nowrap text-sm font-medium text-gray-900 opacity-100 border-b border-l border-black" style={{ width: "404px", height: "5px", position: "relative", padding: "0" }}>
                                    <div style={{ display: "flex", height: "100%", margin: "0" }}>
                                        <div style={{ flex: 1, borderRight: "1px solid black", padding: "0" }}>
                                            
                                        </div>
                                        <div style={{ flex: 1, borderRight: "1px solid black", padding: "0" }}>

                                        </div>
                                        <div style={{ flex: 1, borderRight: "1px solid black", padding: "0" }}>
                                            
                                        </div>
                                        <div style={{ flex: 1, padding: "0" }}>
                                           
                                        </div>
                                    </div>
                                    <div style={{ position: "absolute", bottom: "0", right: "0", borderBottom: "1px solid black" }}></div>
                                </td>

                                <td className="whitespace-nowrap py-2 pl-2 pr-2 text-sm font-medium text-gray-900 sm:pl-6 opacity-100 border-b border-l border-black border-r" style={{ width: "404px" }}>

                                </td>
                                </tr>   
                                <tr >
                                <td className="whitespace-nowrap py-2 pl-2 pr-2 text-sm font-medium text-gray-900 sm:pl-6 opacity-100  border-b border-l border-black" style={{ width: "404px" }}>
                                   
                                </td>
                                <td className="whitespace-nowrap py-2 pl-2 pr-2 text-sm font-medium text-gray-900 sm:pl-6 opacity-100 border-b border-l border-black" style={{ opacity: "0 !important" }}>

                                </td>
                                <td className="whitespace-nowrap text-sm font-medium text-gray-900 opacity-100 border-b border-l border-black" style={{ width: "404px", height: "5px", position: "relative", padding: "0" }}>
                                    <div style={{ display: "flex", height: "100%", margin: "0" }}>
                                        <div style={{ flex: 1, borderRight: "1px solid black", padding: "0" }}>
                                            
                                        </div>
                                        <div style={{ flex: 1, borderRight: "1px solid black", padding: "0" }}>

                                        </div>
                                        <div style={{ flex: 1, borderRight: "1px solid black", padding: "0" }}>
                                            
                                        </div>
                                        <div style={{ flex: 1, padding: "0" }}>
                                           
                                        </div>
                                    </div>
                                    <div style={{ position: "absolute", bottom: "0", right: "0", borderBottom: "1px solid black" }}></div>
                                </td>

                                <td className="whitespace-nowrap py-2 pl-2 pr-2 text-sm font-medium text-gray-900 sm:pl-6 opacity-100 border-b border-l border-black border-r" style={{ width: "404px" }}>

                                </td>
                                </tr>   
                                <tr >
                                <td className="whitespace-nowrap py-2 pl-2 pr-2 text-sm font-medium text-gray-900 sm:pl-6 opacity-100  border-b border-l border-black" style={{ width: "404px" }}>
                                   
                                </td>
                                <td className="whitespace-nowrap py-2 pl-2 pr-2 text-sm font-medium text-gray-900 sm:pl-6 opacity-100 border-b border-l border-black" style={{ opacity: "0 !important" }}>

                                </td>
                                <td className="whitespace-nowrap text-sm font-medium text-gray-900 opacity-100 border-b border-l border-black" style={{ width: "404px", height: "5px", position: "relative", padding: "0" }}>
                                    <div style={{ display: "flex", height: "100%", margin: "0" }}>
                                        <div style={{ flex: 1, borderRight: "1px solid black", padding: "0" }}>
                                            
                                        </div>
                                        <div style={{ flex: 1, borderRight: "1px solid black", padding: "0" }}>

                                        </div>
                                        <div style={{ flex: 1, borderRight: "1px solid black", padding: "0" }}>
                                            
                                        </div>
                                        <div style={{ flex: 1, padding: "0" }}>
                                           
                                        </div>
                                    </div>
                                    <div style={{ position: "absolute", bottom: "0", right: "0", borderBottom: "1px solid black" }}></div>
                                </td>

                                <td className="whitespace-nowrap py-2 pl-2 pr-2 text-sm font-medium text-gray-900 sm:pl-6 opacity-100 border-b border-l border-black border-r" style={{ width: "404px" }}>

                                </td>
                                </tr>   
                                
                                <tr >
                                <td className="whitespace-nowrap py-2 pl-2 pr-2 text-sm font-medium text-gray-900 sm:pl-6 opacity-100  border-b border-l border-black" style={{ width: "404px" }}>
                                   
                                </td>
                                <td className="whitespace-nowrap py-2 pl-2 pr-2 text-sm font-medium text-gray-900 sm:pl-6 opacity-100 border-b border-l border-black" style={{ opacity: "0 !important" }}>

                                </td>
                                <td className="whitespace-nowrap text-sm font-medium text-gray-900 opacity-100 border-b border-l border-black" style={{ width: "404px", height: "5px", position: "relative", padding: "0" }}>
                                    <div style={{ display: "flex", height: "100%", margin: "0" }}>
                                        <div style={{ flex: 1, borderRight: "1px solid black", padding: "0" }}>
                                            
                                        </div>
                                        <div style={{ flex: 1, borderRight: "1px solid black", padding: "0" }}>

                                        </div>
                                        <div style={{ flex: 1, borderRight: "1px solid black", padding: "0" }}>
                                            
                                        </div>
                                        <div style={{ flex: 1, padding: "0" }}>
                                           
                                        </div>
                                    </div>
                                    <div style={{ position: "absolute", bottom: "0", right: "0", borderBottom: "1px solid black" }}></div>
                                </td>

                                <td className="whitespace-nowrap py-2 pl-2 pr-2 text-sm font-medium text-gray-900 sm:pl-6 opacity-100 border-b border-l border-black border-r" style={{ width: "404px" }}>

                                </td>
                                </tr>   
                                
                                <tr >
                                <td className="whitespace-nowrap py-2 pl-2 pr-2 text-sm font-medium text-gray-900 sm:pl-6 opacity-100  border-b border-l border-black" style={{ width: "404px" }}>
                                   
                                </td>
                                <td className="whitespace-nowrap py-2 pl-2 pr-2 text-sm font-medium text-gray-900 sm:pl-6 opacity-100 border-b border-l border-black" style={{ opacity: "0 !important" }}>

                                </td>
                                <td className="whitespace-nowrap text-sm font-medium text-gray-900 opacity-100 border-b border-l border-black" style={{ width: "404px", height: "5px", position: "relative", padding: "0" }}>
                                    <div style={{ display: "flex", height: "100%", margin: "0" }}>
                                        <div style={{ flex: 1, borderRight: "1px solid black", padding: "0" }}>
                                            
                                        </div>
                                        <div style={{ flex: 1, borderRight: "1px solid black", padding: "0" }}>

                                        </div>
                                        <div style={{ flex: 1, borderRight: "1px solid black", padding: "0" }}>
                                            
                                        </div>
                                        <div style={{ flex: 1, padding: "0" }}>
                                           
                                        </div>
                                    </div>
                                    <div style={{ position: "absolute", bottom: "0", right: "0", borderBottom: "1px solid black" }}></div>
                                </td>

                                <td className="whitespace-nowrap py-2 pl-2 pr-2 text-sm font-medium text-gray-900 sm:pl-6 opacity-100 border-b border-l border-black border-r" style={{ width: "404px" }}>

                                </td>
                                </tr>   
                                
                                <tr >
                                <td className="whitespace-nowrap py-2 pl-2 pr-2 text-sm font-medium text-gray-900 sm:pl-6 opacity-100  border-b border-l border-black" style={{ width: "404px" }}>
                                   
                                </td>
                                <td className="whitespace-nowrap py-2 pl-2 pr-2 text-sm font-medium text-gray-900 sm:pl-6 opacity-100 border-b border-l border-black" style={{ opacity: "0 !important" }}>

                                </td>
                                <td className="whitespace-nowrap text-sm font-medium text-gray-900 opacity-100 border-b border-l border-black" style={{ width: "404px", height: "5px", position: "relative", padding: "0" }}>
                                    <div style={{ display: "flex", height: "100%", margin: "0" }}>
                                        <div style={{ flex: 1, borderRight: "1px solid black", padding: "0" }}>
                                            
                                        </div>
                                        <div style={{ flex: 1, borderRight: "1px solid black", padding: "0" }}>

                                        </div>
                                        <div style={{ flex: 1, borderRight: "1px solid black", padding: "0" }}>
                                            
                                        </div>
                                        <div style={{ flex: 1, padding: "0" }}>
                                           
                                        </div>
                                    </div>
                                    <div style={{ position: "absolute", bottom: "0", right: "0", borderBottom: "1px solid black" }}></div>
                                </td>

                                <td className="whitespace-nowrap py-2 pl-2 pr-2 text-sm font-medium text-gray-900 sm:pl-6 opacity-100 border-b border-l border-black border-r" style={{ width: "404px" }}>

                                </td>
                                </tr>   
                                
                                <tr >
                                <td className="whitespace-nowrap py-2 pl-2 pr-2 text-sm font-medium text-gray-900 sm:pl-6 opacity-100  border-b border-l border-black" style={{ width: "404px" }}>
                                   
                                </td>
                                <td className="whitespace-nowrap py-2 pl-2 pr-2 text-sm font-medium text-gray-900 sm:pl-6 opacity-100 border-b border-l border-black" style={{ opacity: "0 !important" }}>

                                </td>
                                <td className="whitespace-nowrap text-sm font-medium text-gray-900 opacity-100 border-b border-l border-black" style={{ width: "404px", height: "5px", position: "relative", padding: "0" }}>
                                    <div style={{ display: "flex", height: "100%", margin: "0" }}>
                                        <div style={{ flex: 1, borderRight: "1px solid black", padding: "0" }}>
                                            
                                        </div>
                                        <div style={{ flex: 1, borderRight: "1px solid black", padding: "0" }}>

                                        </div>
                                        <div style={{ flex: 1, borderRight: "1px solid black", padding: "0" }}>
                                            
                                        </div>
                                        <div style={{ flex: 1, padding: "0" }}>
                                           
                                        </div>
                                    </div>
                                    <div style={{ position: "absolute", bottom: "0", right: "0", borderBottom: "1px solid black" }}></div>
                                </td>

                                <td className="whitespace-nowrap py-2 pl-2 pr-2 text-sm font-medium text-gray-900 sm:pl-6 opacity-100 border-b border-l border-black border-r" style={{ width: "404px" }}>

                                </td>
                                </tr>   
                                
                                <tr >
                                <td className="whitespace-nowrap py-2 pl-2 pr-2 text-sm font-medium text-gray-900 sm:pl-6 opacity-100  border-b border-l border-black" style={{ width: "404px" }}>
                                   
                                </td>
                                <td className="whitespace-nowrap py-2 pl-2 pr-2 text-sm font-medium text-gray-900 sm:pl-6 opacity-100 border-b border-l border-black" style={{ opacity: "0 !important" }}>

                                </td>
                                <td className="whitespace-nowrap text-sm font-medium text-gray-900 opacity-100 border-b border-l border-black" style={{ width: "404px", height: "5px", position: "relative", padding: "0" }}>
                                    <div style={{ display: "flex", height: "100%", margin: "0" }}>
                                        <div style={{ flex: 1, borderRight: "1px solid black", padding: "0" }}>
                                            
                                        </div>
                                        <div style={{ flex: 1, borderRight: "1px solid black", padding: "0" }}>

                                        </div>
                                        <div style={{ flex: 1, borderRight: "1px solid black", padding: "0" }}>
                                            
                                        </div>
                                        <div style={{ flex: 1, padding: "0" }}>
                                           
                                        </div>
                                    </div>
                                    <div style={{ position: "absolute", bottom: "0", right: "0", borderBottom: "1px solid black" }}></div>
                                </td>

                                <td className="whitespace-nowrap py-2 pl-2 pr-2 text-sm font-medium text-gray-900 sm:pl-6 opacity-100 border-b border-l border-black border-r" style={{ width: "404px" }}>

                                </td>
                                </tr>   
                                
                                <tr >
                                <td className="whitespace-nowrap py-2 pl-2 pr-2 text-sm font-medium text-gray-900 sm:pl-6 opacity-100  border-b border-l border-black" style={{ width: "404px" }}>
                                   
                                </td>
                                <td className="whitespace-nowrap py-2 pl-2 pr-2 text-sm font-medium text-gray-900 sm:pl-6 opacity-100 border-b border-l border-black" style={{ opacity: "0 !important" }}>

                                </td>
                                <td className="whitespace-nowrap text-sm font-medium text-gray-900 opacity-100 border-b border-l border-black" style={{ width: "404px", height: "5px", position: "relative", padding: "0" }}>
                                    <div style={{ display: "flex", height: "100%", margin: "0" }}>
                                        <div style={{ flex: 1, borderRight: "1px solid black", padding: "0" }}>
                                            
                                        </div>
                                        <div style={{ flex: 1, borderRight: "1px solid black", padding: "0" }}>

                                        </div>
                                        <div style={{ flex: 1, borderRight: "1px solid black", padding: "0" }}>
                                            
                                        </div>
                                        <div style={{ flex: 1, padding: "0" }}>
                                           
                                        </div>
                                    </div>
                                    <div style={{ position: "absolute", bottom: "0", right: "0", borderBottom: "1px solid black" }}></div>
                                </td>

                                <td className="whitespace-nowrap py-2 pl-2 pr-2 text-sm font-medium text-gray-900 sm:pl-6 opacity-100 border-b border-l border-black border-r" style={{ width: "404px" }}>

                                </td>
                                </tr>   
                                
                                <tr >
                                <td className="whitespace-nowrap py-2 pl-2 pr-2 text-sm font-medium text-gray-900 sm:pl-6 opacity-100  border-b border-l border-black" style={{ width: "404px" }}>
                                   
                                </td>
                                <td className="whitespace-nowrap py-2 pl-2 pr-2 text-sm font-medium text-gray-900 sm:pl-6 opacity-100 border-b border-l border-black" style={{ opacity: "0 !important" }}>

                                </td>
                                <td className="whitespace-nowrap text-sm font-medium text-gray-900 opacity-100 border-b border-l border-black" style={{ width: "404px", height: "5px", position: "relative", padding: "0" }}>
                                    <div style={{ display: "flex", height: "100%", margin: "0" }}>
                                        <div style={{ flex: 1, borderRight: "1px solid black", padding: "0" }}>
                                            
                                        </div>
                                        <div style={{ flex: 1, borderRight: "1px solid black", padding: "0" }}>

                                        </div>
                                        <div style={{ flex: 1, borderRight: "1px solid black", padding: "0" }}>
                                            
                                        </div>
                                        <div style={{ flex: 1, padding: "0" }}>
                                           
                                        </div>
                                    </div>
                                    <div style={{ position: "absolute", bottom: "0", right: "0", borderBottom: "1px solid black" }}></div>
                                </td>

                                <td className="whitespace-nowrap py-2 pl-2 pr-2 text-sm font-medium text-gray-900 sm:pl-6 opacity-100 border-b border-l border-black border-r" style={{ width: "404px" }}>

                                </td>
                                </tr>   
                                
                                <tr >
                                <td className="whitespace-nowrap py-2 pl-2 pr-2 text-sm font-medium text-gray-900 sm:pl-6 opacity-100  border-b border-l border-black" style={{ width: "404px" }}>
                                   
                                </td>
                                <td className="whitespace-nowrap py-2 pl-2 pr-2 text-sm font-medium text-gray-900 sm:pl-6 opacity-100 border-b border-l border-black" style={{ opacity: "0 !important" }}>

                                </td>
                                <td className="whitespace-nowrap text-sm font-medium text-gray-900 opacity-100 border-b border-l border-black" style={{ width: "404px", height: "5px", position: "relative", padding: "0" }}>
                                    <div style={{ display: "flex", height: "100%", margin: "0" }}>
                                        <div style={{ flex: 1, borderRight: "1px solid black", padding: "0" }}>
                                            
                                        </div>
                                        <div style={{ flex: 1, borderRight: "1px solid black", padding: "0" }}>

                                        </div>
                                        <div style={{ flex: 1, borderRight: "1px solid black", padding: "0" }}>
                                            
                                        </div>
                                        <div style={{ flex: 1, padding: "0" }}>
                                           
                                        </div>
                                    </div>
                                    <div style={{ position: "absolute", bottom: "0", right: "0", borderBottom: "1px solid black" }}></div>
                                </td>

                                <td className="whitespace-nowrap py-2 pl-2 pr-2 text-sm font-medium text-gray-900 sm:pl-6 opacity-100 border-b border-l border-black border-r" style={{ width: "404px" }}>

                                </td>
                                </tr>  
                                <tr >
                                <td className="whitespace-nowrap text-sm font-medium text-gray-900 opacity-100  border-b border-l border-black" style={{ width: "404px",backgroundColor: '#cccccc' }}>
                                   total
                                </td>
                                <td className="whitespace-nowrap py-2 pl-2 pr-2 text-sm font-medium text-gray-900 sm:pl-6 opacity-100 border-b border-l border-black" style={{ opacity: "0 !important" }}>

                                </td>
                                <td className="whitespace-nowrap text-sm font-medium text-gray-900 opacity-100 border-b border-l border-black" style={{ width: "404px", height: "5px", position: "relative", padding: "0" }}>
                                    <div style={{ display: "flex", height: "100%", margin: "0" }}>
                                        <div style={{ flex: 1, borderRight: "1px solid black", padding: "0" }}>
                                            
                                        </div>
                                        <div style={{ flex: 1, borderRight: "1px solid black", padding: "0" }}>

                                        </div>
                                        <div style={{ flex: 1, borderRight: "1px solid black", padding: "0" }}>
                                            
                                        </div>
                                        <div style={{ flex: 1, padding: "0" }}>
                                           
                                        </div>
                                    </div>
                                    <div style={{ position: "absolute", bottom: "0", right: "0", borderBottom: "1px solid black" }}></div>
                                </td>

                                <td className="whitespace-nowrap py-2 pl-2 pr-2 text-sm font-medium text-gray-900 sm:pl-6 opacity-100 border-b border-l border-black border-r" style={{ width: "404px" }}>

                                </td>
                                </tr>    
                                
                                
                            
                            </tbody>
                        </table>
                        <div className="h-10 border border-black cccc nowrap font-bold" style={{ fontSize: "8px",backgroundColor: '#cccccc' }}>
                            <p className="text-center">
                                   We declare under the penalties of perjury that this certificate has been made in good faith, verified by us, and to the best of our knowledge and belief, is true and
                                correct, pursuant to the provisions of the National Internal Revenue Code, as amended, and the regulations issued under authority thereof. Further, we give our consent to
                                the processing of our information as contemplated under the *Data Privacy Act of 2012 (R.A. No. 10173) for legitimate and lawful purposes.

                            </p>
                        </div>
                        <div class="h-8 border border-black">

                        </div>
                        <div class="h-6 border border-black cccc nowrap font-bold"  style={{ fontSize: "8px" ,backgroundColor: '#cccccc' }}>
                                <p class="text-center font-bold">
                                Signature over Printed Name of Payor/Payor’s Authorized Representative/Tax Agent <br/>
                                    (Indicate Title/Designation and TIN)
                                </p>
                            </div>
                            <div className="col-span-12 border-black border-2 h-7 font-bold" style={{ fontSize: "8px",backgroundColor: '#cccccc' }}>
                                    <div className="grid grid-cols-12">
                                        <div className="col-span-4">
                                            <div className="flex">
                                                <div className="nowrap">
                                                    <p className="px-1">
                                                        Tax Agent Accreditation No./ <br />
                                                        Attorney’s Roll No. (if applicable)
                                                    </p>
                                                </div>
                                                <div>
                                                    <div className="border border-black w-28 h-5"></div>
                                                </div>
                                            </div>    
                                        </div>
                                        <div className="col-span-3 col-start-5">
                                            <div className="flex">
                                                <div>
                                                    <p className="nowrap pr-2">
                                                        Date of Issue <br />
                                                        (MM/DD/YYYY)
                                                    </p>
                                                </div>
                                                <div>
                                                    <div className="flex  bg-white border border-black" style={{ fontSize: "12px", height: "18px" }}>
                                                        <div className="w-4">
                                                            <p className="w-4 h-3 text-center"></p>
                                                        </div>   
                                                        <div className="w-4 borderLine_left_short_2"></div>  
                                                        <div className="w-4 border-l border-black"></div>
                                                        <div className="w-4 borderLine_left_short_2"></div> 
                                                        <div className="w-4 border-l border-black"></div>
                                                        <div className="w-4 borderLine_left_short_2"></div>
                                                        <div className="w-4 borderLine_left_short_2"></div>
                                                        <div className="w-4 borderLine_left_short_2"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-span-3 col-start-9">
                                            <div className="flex">
                                                <div>
                                                    <p className="nowrap pr-2">
                                                        Date of Issue <br />
                                                        (MM/DD/YYYY)
                                                    </p>
                                                </div>
                                                <div>
                                                    <div className="flex  bg-white border border-black" style={{ fontSize: "12px", height: "18px" }}>
                                                        <div className="w-4">
                                                            <p className="w-4 h-3 text-center"></p>
                                                        </div>   
                                                        <div className="w-4 borderLine_left_short_2"></div>  
                                                        <div className="w-4 border-l border-black"></div>
                                                        <div className="w-4 borderLine_left_short_2"></div> 
                                                        <div className="w-4 border-l border-black"></div>
                                                        <div className="w-4 borderLine_left_short_2"></div>
                                                        <div className="w-4 borderLine_left_short_2"></div>
                                                        <div className="w-4 borderLine_left_short_2"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="border border-black cccc" style={{ fontSize: '9px' ,backgroundColor: '#cccccc' }}>
                                <p className="text-center">
                                    <strong>
                                        CONFORME:
                                    </strong>
                                </p>
                            </div>
                        <div className="h-10 border border-black"></div>
                        <div className="h-7 border border-black cccc nowrap font-bold" style={{ fontSize: '9px' ,backgroundColor: '#cccccc'}}>
                            <p className="text-center">
                                Signature over Printed Name of Payor/Payor’s Authorized Representative/Tax Agent <br />
                                (Indicate Title/Designation and TIN)
                            </p>
                        </div>
                            <div className="col-span-12 border-black border-2 h-8 font-bold" style={{ fontSize: '9px',backgroundColor: '#cccccc' }}>
                                <div className="grid grid-cols-12">
                                    <div className="col-span-4">
                                        <div className="flex">
                                            <div className="nowrap">
                                                <p className="px-1">
                                                    Tax Agent Accreditation No./ <br />
                                                    Attorney’s Roll No. (if applicable)
                                                </p>
                                            </div>
                                            <div>
                                                <div className="border border-black w-28 h-5"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-span-3 col-start-5">
                                        <div className="flex">
                                            <div>
                                                <p className="nowrap pr-2">
                                                    Date of Issue <br />
                                                    (MM/DD/YYYY)
                                                </p>
                                            </div>
                                            <div>
                                                <div className="flex bg-white border border-black" style={{ fontSize: '12px', height: '18px' }}>
                                                    <div className="w-4">
                                                        <p className="w-4 h-3 text-center"></p>
                                                    </div>
                                                    <div className="w-4 borderLine_left_short_2"></div>
                                                    <div className="w-4 border-l border-black"></div>
                                                    <div className="w-4 borderLine_left_short_2"></div>
                                                    <div className="w-4 border-l border-black"></div>
                                                    <div className="w-4 borderLine_left_short_2"></div>
                                                    <div className="w-4 borderLine_left_short_2"></div>
                                                    <div className="w-4 borderLine_left_short_2"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-span-3 col-start-9">
                                        <div className="flex">
                                            <div>
                                                <p className="nowrap pr-2">
                                                    Date of Issue <br />
                                                    (MM/DD/YYYY)
                                                </p>
                                            </div>
                                            <div>
                                                <div className="flex bg-white border border-black" style={{ fontSize: '12px', height: '18px' }}>
                                                    <div className="w-4">
                                                        <p className="w-4 h-3 text-center"></p>
                                                    </div>
                                                    <div className="w-4 borderLine_left_short_2"></div>
                                                    <div className="w-4 border-l border-black"></div>
                                                    <div className="w-4 borderLine_left_short_2"></div>
                                                    <div className="w-4 border-l border-black"></div>
                                                    <div className="w-4 borderLine_left_short_2"></div>
                                                    <div className="w-4 borderLine_left_short_2"></div>
                                                    <div className="w-4 borderLine_left_short_2"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p className="nowrap" style={{ fontSize: '10px' }}>
                                *NOTE: The BIR Data Privacy is in the BIR website (www.bir.gov.ph)
                            </p>
                {/* Repeat this block as needed */}


                    </div>


                  




                    </div>
                    
                </div>
      
     
    
       
      </div>);

}
export default TaxModulePrint;