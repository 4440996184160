
import { PencilSquareIcon,ExclamationTriangleIcon,Cog6ToothIcon, UserIcon, BuildingStorefrontIcon, BookmarkSlashIcon, BookmarkSquareIcon, BookOpenIcon, BookmarkIcon } from '@heroicons/react/20/solid'
import './sweetbutton.css'; 
import {
  TagIcon
} from '@heroicons/react/24/outline'


const SettingNavigation =(props) =>{

    const secondaryNavigation = [
     
        { name: 'Sales Account', href: '/setting/classification/sales', icon: TagIcon, current: props.pageIndex === 1 },
        { name: 'Expense Account', href:'/setting/classification/', icon: TagIcon, current: props.pageIndex === 0  },
        { name: 'General Account', href:'/setting/general/account/', icon: TagIcon, current: props.pageIndex === 5  },
        { name: 'User', href: '/setting/user', icon: UserIcon, current: props.pageIndex === 2 },
        { name: 'Supplier', href: '/setting/supplier', icon: BuildingStorefrontIcon, current: props.pageIndex === 3  },
        { name: 'Withholding Categories Rates', href: '/setting/withholding/category', icon: BookmarkIcon, current: props.pageIndex === 4  },
      ]
      function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
      }
      
    return (
        <nav className="flex-none px-4 sm:px-6 lg:px-0">
        <ul role="list" className="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col border-spacing-2 ">
        {secondaryNavigation.map((item) => (
            <li key={item.name}>
            <a
                href={item.href}
                className={classNames(
                item.current
                    ? 'bg-gray-50 text-indigo-600'
                    : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                'group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold border-gray border'
                )}
            >
                <item.icon
                className={classNames(
                    item.current ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600 border-gray border',
                    'h-6 w-6 shrink-0'
                )}
                aria-hidden="true"
                />
                {item.name}
            </a>
            </li>
        ))}
        </ul>
    </nav>
                    
    );
}
export default SettingNavigation;