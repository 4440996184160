

import { Fragment, useRef, useState,useEffect,useContext } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon,PencilSquareIcon  } from '@heroicons/react/24/outline'
import { PlusIcon } from '@heroicons/react/20/solid'
import { UserContext } from '../../../contexts/user.context';

import Swal from 'sweetalert2';
const GeneralJournalLayout =() =>{
    const [open, setOpen] = useState(true);

    const cancelButtonRef = useRef(null);
    const { company_id,Trade_name,Tin,Reg_address} = useContext(UserContext);
    const [isLoad, setLoad] = useState(true);



    return (
        <div>
        <div className="sm:flex sm:items-center mt-4 mb-5">
            <div className="sm:flex-auto">
                <h1 className="text-2xl font-semibold leading-6 text-gray-900 capitalize ">General Journal</h1>
        
            </div>

        </div>
        <div className= 'mt-2 flow-root'>
          <div className='flex gap-4'>
                <div className=''>
                    <label htmlFor="username" className="block text-sm font-bold leading-6 text-gray-900">
                        Debit
                    </label>
                </div>
                <div className='w-2/4'>
                <div className='flex gap-24'>
                    <div className='w-1/4'>
                        <div className="flex rounded-md shadow-sm ring-1 ring-inset  focus-within:ring-indigo-600 sm:max-w-md">
                    
                            <input
                                id="debit_account"
                                name="debit_account"
                                type="text"
                                placeholder='Account Title'
                                className="px-4 block flex-1 -0 bg-transparent py-1.5 text-gray-900  sm:text-sm sm:leading-6 "
                            />
                        </div>
                    </div>
                    <div >
                        <div className="flex items-center space-x-2">
                            <input
                                id="debit_amount"
                                name="debit_amount"
                                type="text"
                                placeholder=' Amount'
                                className="flex-1 bg-transparent py-1.5 pl-4 text-gray-900 sm:text-sm sm:leading-6 border-blue-500 border"
                            />
                            <span>
                                <button
                                    type="button"
                                    className="rounded-full bg-indigo-600 p-1 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    <PlusIcon aria-hidden="true" className="h-5 w-5" />
                                </button>
                            </span>
                        </div>

                    </div>
                </div>
                </div>
          </div>
        </div>
        <div className= 'mt-2 flow-root'>
        <div className='flex gap-4 '>
                <div className=''>
                    <label htmlFor="username" className="block text-sm font-bold leading-6 text-gray-900">
                        Credit
                    </label>
                </div>
                <div className='w-2/4 ml-12'>
                <div className='flex gap-24'>
                    <div className='w-1/4'>
                        <div className="flex rounded-md shadow-sm ring-1 ring-inset  focus-within:ring-indigo-600 sm:max-w-md">
                    
                            <input
                                id="credit_account"
                                name="credit_account"
                                type="text"
                                placeholder='Account Title'
                                className="px-4 block flex-1 -0 bg-transparent py-1.5 text-gray-900  sm:text-sm sm:leading-6 "
                            />
                        </div>
                    </div>
                    <div >
                        <div className="flex items-center space-x-2">
                            <input
                                id="credit_amount"
                                name="credit_amount"
                                type="text"
                                placeholder=' Amount'
                                className="flex-1 bg-transparent py-1.5 pl-4 text-gray-900 sm:text-sm sm:leading-6 border-blue-500 border"
                            />
                            <span>
                                <button
                                    type="button"
                                    className="rounded-full bg-indigo-600 p-1 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    <PlusIcon aria-hidden="true" className="h-5 w-5" />
                                </button>
                            </span>
                        </div>

                    </div>
                </div>
                </div>
          </div>
        </div>
      </div>);

}
export default GeneralJournalLayout;