import { Routes, Route, Navigate} from 'react-router-dom';
import Dashboard from './routes/dashboard/dashboard.route';
import StepOneReg from './routes/registration/steponeReg.route';
import Login from './routes/guest/login.route';
import PrivateRoutes from './utils/PrivateRoute';
import UserPage from './routes/user/userpage.route';
import FAExpensePage from './routes/expenseverification/faexpensepage.route';
import SummaryPage from './routes/expense/summarypage.route';
import RptExpensePage from './routes/report/expense/rptexpensepage.route';
import ExpenseTransactionEncoding from './routes/expensetransaction/encodingreceipt.route';
import ExpenseTransactionList from './routes/expensetransaction/listencoding.route';
import ClassificationSettingPage from './routes/setting/classificationsettingpage.route';
import RptExpenseSubstantiationPage from './routes/report/expense/rptexpensesubstantiationpage.route';
import RptDisapprovePage from './routes/report/expense/rptdisapprovepage.route';
import ChangePassword from './routes/changepassword/changepassword.route';
import SalesReceipt from './routes/salestransaction/salesreceipt.route';
import SalesReceiptList from './routes/salestransaction/salesreceiptlist.route';
import SalesVerificationPage from './routes/salesverification/salesverification.route';
import VerifyPage from './routes/verify/verifypage.route';
import AlreadyVerifiedPage from './routes/verify/alreadyverifiedpage.route';
import RptSalesApprovePage from './routes/report/expense/rptsalesapprovepage.route';
import RptSalesDisapprovePage from './routes/report/expense/rptsalesdisapprovepage.route';
import RptSalesSummaryPage from './routes/report/expense/rptsalesummarypage.route';
import SupplierSettingPage from './routes/setting/suppliersettingpage.route';
import RptPurchaseTransactionPage from './routes/report/expense/rptpurchasetransactionpage.route';
import ClassificationSettingSalesPage from './routes/setting/classificationsettingsalespage.route';
import TaxWithheldPage from './routes/tax/taxwitheldpage.route';
import RptSummarySellPage from './routes/report/sales/rptsummarysellpage.route';
import TaxReturnPage from './routes/taxreturn/taxreturnpage.route';
import ProfilePage from './routes/profile/profile.route';
import HistoryPage from './routes/history/historypage.route';
import WithHoldingRatesPage from './routes/tax/witholdingratespage.route';
import WithholdingCategoryRatesPage from './routes/setting/withholdingcategoryratespage.route';
import MaintananceRoute from './routes/guest/maintenance.route';
import SettingGeneralAccountPage from './routes/setting/generalaccountsettingpage.route';
import GeneralJournalPage from './routes/generalledger/generaljournal/generaljournalpage.route';
const App = () => {

  // const { currentUser } = useContext(UserContext);
  return (
      <Routes>
        <Route element={<PrivateRoutes/>}>
          <Route path='/dashboard' element={ <Dashboard />}/>
          <Route path='/expense/verification' element={ <FAExpensePage />}/>
          <Route path='/expense' element={ <SummaryPage />}/>
          <Route path='/expense/receipt' element={ <ExpenseTransactionEncoding />}/>
          <Route path='/expense/list' element={ <ExpenseTransactionList />}/>
          <Route path='/expense/verification' element={ <FAExpensePage />}/>
          <Route path='/report/expense' element={ <RptExpensePage />}/>
          <Route path='/report/disapproved' element={ <RptDisapprovePage />}/>
          <Route path='/report/substantiation' element={ <RptExpenseSubstantiationPage />}/>
          <Route path='/setting/user' element={ <UserPage />}/>
          <Route path='/setting/classification' element={ <ClassificationSettingPage />}/>
          <Route path='/setting/classification/sales' element={ <ClassificationSettingSalesPage />}/>
          <Route path='/setting/withholding/category' element={ <WithholdingCategoryRatesPage />}/>
          <Route path='/setting/general/account' element={ <SettingGeneralAccountPage />}/>

          <Route path='/report/sell/transaction' element={ <WithholdingCategoryRatesPage />}/>

          <Route path='/setting/supplier' element={ <SupplierSettingPage />}/>
          <Route path='/change/password' element={ <ChangePassword />}/>
          <Route path='/sales/receipt' element={ <SalesReceipt />}/>
          <Route path='/sales/list' element={ <SalesReceiptList />}/>
          <Route path='/sales/verification' element={ <SalesVerificationPage />}/>
          <Route path='/report/sales' element={ <RptSalesApprovePage />}/>
          <Route path='/report/sales/disapproved' element={ <RptSalesDisapprovePage />}/>
          <Route path='/report/sales/summary' element={ <RptSalesSummaryPage />}/>
          <Route path='/report/purchase/transaction' element={ <RptPurchaseTransactionPage />}/>
          <Route path='/report/sell/transaction' element={ <RptSummarySellPage />}/>
          
          <Route path='/tax/2307' element={ <TaxWithheldPage />}/>
          <Route path='/witholding/rates' element={ <WithHoldingRatesPage />}/>
          
          <Route path='/tax/payout/:type' element={ <TaxReturnPage />}/>
          
          <Route path='/profile' element={ <ProfilePage />}/>
          <Route path='/history' element={ <HistoryPage />}/>


          <Route path='/general/journal' element={ <GeneralJournalPage />}/>
          
          
        </Route>
        <Route path='/' element={ <Login />}/>
        <Route path='/step' element={ <StepOneReg />}/>
        <Route path='/verify/:id' element={ <VerifyPage />}/>
        <Route path='/verified' element={ <AlreadyVerifiedPage />}/>
        <Route path='/maintainance' element={ <MaintananceRoute />}/>
        
      </Routes>
    );
}
export default App;

