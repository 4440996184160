

import { Fragment, useRef, useState,useEffect,useContext } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon,PencilSquareIcon  } from '@heroicons/react/24/outline'
import { UserContext } from '../../contexts/user.context'
import { formatTin } from '../../utils/ValidationField'
import Swal from 'sweetalert2';
import './Loader.css'; // Import the CSS file
import TaxModulePrint from './taxmoduleprint.layout'
const Taxwithheld =() =>{
    const [open, setOpen] = useState(true);
    const [LoaderShow, setLoaderShow] = useState(false);

    const [PdfShow, setPdfShow] = useState(false);

    const cancelButtonRef = useRef(null);
    const { company_id,Trade_name,Tin,Reg_address} = useContext(UserContext);
    

    const currentYear = new Date().getFullYear();
    const [Year, setYear] = useState(currentYear);

    const [CountAdded, setCountAdded] = useState(13);

    const handleChangeYear = (e) => {
        // Ensure only numbers and a length of 4 are allowed
        const value = e.target.value;
        if (/^\d{0,4}$/.test(value)) {
            setYear(value);
        }
    };
   
    const [suggestions, setSuggestions] = useState([]);

    const [count, setCount] = useState(0);
    const defaultFormFields = {
        // selectype: '',
        seller:'',
        address:'',
        tin1: '',
        tin2: '',
        fromPeriod:'',
        toPeriod:'',
        SelectedMonth: '',
        Type: 'VT',
      };
      const [formFields,setFormFields ] = useState(defaultFormFields);
      const { seller,address,tin1,tin2,fromPeriod,toPeriod,SelectedMonth,Type} = formFields;


      const handleChange = (event) => {
        const { name, value } = event.target;

        if(name==='seller'){
          SearchSupplier();

          const selectedValue = event.target.value.toUpperCase();
          const selectedItem = suggestions.find(item => item.description.toUpperCase() === selectedValue);
          if (selectedItem) {
              const selectedId = selectedItem.id;
              // Now you have the selected ID
              console.log("Selected ID:", selectedId);
              formFields.tin1=selectedItem.tin;
              formFields.tin2=selectedItem.branch;
              formFields.address=selectedItem.address;
           

           
              // You can use this selectedId however you need
          }else{
              formFields.tin1='';
              formFields.tin2='';
              formFields.address='';
          }

   
        }
      
      const formattedValue =
    
      name === 'tin1' ? formatTin(value) :value;
     
     
    
      
        setFormFields({ ...formFields, [name]: formattedValue });

      };
      

      const SearchSupplier = async () => {



        const postData = {
          "searchdata":seller,
          "company_id": company_id,
        };
       

        const requestOptions = {
          method: 'POST',
          headers: 
          { 'Content-Type': 'application/json',
          'Accept': 'application/json', 
           'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
          body: JSON.stringify(postData)
        };
    
     await fetch(process.env.REACT_APP_API_URL+'api/setting/supplier/search_list', requestOptions)
        .then( async response => 
          {
             console.log(response);
            // Check the status code
            if (response.status === 422) {
  
               
            }else{
              const data = await response.json();
        
  
              setSuggestions(data.data)
             
            }
          }
          
        )
        .then(data => {
          // Handle the response
       
      
        //   setLoad(true);
        })
        .catch(error => {
          // Handle any errors
       
        });
        // setLoad(true);
    } 


    const [TaxList, setTaxList] = useState([]);


    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
    const CreateModule=async () =>{
        setCountAdded(13);
        setTaxList([]);
        
       
        if(SelectedMonth=='' || Year==''){
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Please complete the data',
              
              })

              return;
          }


        const postData = {
            "SelectedMonth":SelectedMonth,
            "company_id": company_id,
            "Year":Year,
            "seller":seller,
            "Type": Type
          };
          console.log(postData);

         

          console.log(JSON.parse(localStorage.getItem('jwtToken')).value);
          setLoaderShow(true);
          setOpen(false);
          await delay(5000);
          
          const requestOptions = {
            method: 'POST',
            headers: 
            {
            'Content-Type': 'application/json',
            'Accept': 'application/json', 
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`,
             },
            body: JSON.stringify(postData)
          };
      
       await fetch(process.env.REACT_APP_API_URL+'api/tax/module/2307', requestOptions)
          .then( async response => 
            {
               console.log(response);
              // Check the status code
              if (response.status === 422) {
        
                console.log(response);
                 
              }else{


                const data = await response.json();

                
                console.log(data);
                 

                formFields.fromPeriod=data.from_date;
                formFields.toPeriod=data.to_date;
                
              
              
                setCountAdded(13-combineSums(data.data).length)
                setTaxList(combineSums(data.data));
              
                setLoaderShow(false);
                setPdfShow(true)
              }
            }
            
          )
          .then(data => {
            // Handle the response
         
        
          //   setLoad(true);
          })
          .catch(error => {
            // Handle any errors
         
          });
    }
    const combineSums = (data) => {
        const combined = data.reduce((acc, item) => {
            const existing = acc.find(entry => entry.classification === item.classification);
            if (existing) {
                existing.quarter_1 += item.quarter_1;
                existing.quarter_2 += item.quarter_2;
                existing.quarter_3 += item.quarter_3;
                existing.total += item.total;
                existing.with_hold += item.with_hold;
            } else {
                acc.push({ classification: item.classification, quarter_1: item.quarter_1, quarter_2: item.quarter_2, quarter_3: item.quarter_3, total: item.total, with_hold: item.with_hold });
            }
            return acc;
        }, []);
        
        return combined.filter(entry => entry.total !== 0);
    };
    
   
    return (
      
        <div>
            {
                (PdfShow)?

                <TaxModulePrint from_date={fromPeriod} to_date={toPeriod} tin1={tin1} tin2={tin2} seller={seller} address={address} TaxList={TaxList} CountAdded={CountAdded} Year={Year}/>
                :''
            }
            { 
            
            (PdfShow)?
             '':
        
                <div className="px-4 sm:px-6 lg:px-8 ">
                    <div className='flex mt-4'>
                        <div>
                            <button    
                            onClick={() => setOpen(true)} 
                            type="button" className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-4 py-4 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                            <svg  className="-ml-0.5 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>

                            Generate
                            </button>
                        </div>
                                {/* <div className='w-9/12'>
                                <form >
                                <div class="relative mt-4 w-3/5 mx-auto">
                                    <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                        <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                                        </svg>
                                    </div>
                                    <input type="search"
                                    id="default-search" 
                                    class=" block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                                    placeholder="Search..." 
                                    
                                    />
                                    <button type="submit"   class=" text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Search</button>
                                </div>
                            </form>
                            </div> */}
                    </div>
                </div>
                }
                {
                    (LoaderShow)?
                    <>
                               <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center transition-opacity">
                                <div className="loader flex flex-wrap justify-center">
                                    <div className="box box-1">
                                    <div className="side-left"></div>
                                    <div className="side-right"></div>
                                    <div className="side-top"></div>
                                    </div>
                                    <div className="box box-2">
                                    <div className="side-left"></div>
                                    <div className="side-right"></div>
                                    <div className="side-top"></div>
                                    </div>
                                    <div className="box box-3">
                                    <div className="side-left"></div>
                                    <div className="side-right"></div>
                                    <div className="side-top"></div>
                                    </div>
                                    <div className="box box-4">
                                    <div className="side-left"></div>
                                    <div className="side-right"></div>
                                    <div className="side-top"></div>
                                    </div>
                                </div>
                            </div> 
                    </>
                    :''
                }
   

              
          <Transition.Root show={open} as={Fragment}>
                    <Dialog as="div" className="relative z-10 " initialFocus={cancelButtonRef} onClose={setOpen}>
                        <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <div className="fixed inset-0 z-10 overflow-y-auto mt-10">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                               <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                <button
                                    type="button"
                                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    onClick={() => setOpen(false)}
                                >
                                    <span className="sr-only">Close</span>
                                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                                </div>
                                <div>
                                    <div className="grid grid-cols-12 gap-2 ">
      
                                        <div className="sm:col-span-6 
                                        ">
                                            <label htmlFor="seller" className="block text-sm font-medium leading-6 text-gray-900">
                                                Seller
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                type="text"
                                                name="seller"
                                                id="seller"
                                                autoComplete="seller"
                                                value={seller}
                                                required
                                            
                                                list="suggestions" 
                                                onChange={handleChange}
                                                className=" p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                />
                                                <datalist id="suggestions">
                                                {suggestions.map((item) => (
                                                <option key={item.id} title={item.id} value={item.description} />
                                                ))}
                                            </datalist>
                                            </div>
                                        </div>
                                        <div className="sm:col-span-4 ">
                                           <label htmlFor="tin1" className="block text-sm font-medium leading-6 text-gray-900">
                                              TIN
                                          </label>

                                          <div className="mt-2">
                                          <input
                                              type="text"
                                              name="tin1"
                                              id="tin1"
                                              autoComplete="tin1"
                                              value={tin1}
                                              required
                                              onChange={handleChange}
                                              maxlength="11"
                                              readOnly
                                         
                                              className="uppercase p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                              />

                                          </div>
                                        </div>
                                        <div className="sm:col-span-2">
                                        <label htmlFor="tin2" className="block text-sm font-medium leading-6 text-gray-900 ">
                                              Branch
                                          </label>

                                          <div className="mt-2">
                                          <input
                                              type="text"
                                              name="tin2"
                                              id="tin2"
                                              maxlength="3"
                                              autoComplete="tin2"
                                              value={tin2}
                                              required
                                              onChange={handleChange}
                                              readOnly
                                         
                                              placeholder="001"
                                              className="uppercase p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                              />

                                          </div>
                                        </div>
                                        <div className="sm:col-span-full">
                                            <label htmlFor="seller" className="block text-sm font-medium leading-6 text-gray-900">
                                                Address
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                type="text"
                                                name="address"
                                                id="selleaddressr"
                                                autoComplete="address"
                                                value={address}
                                                required
                                                onChange={handleChange}
                                                readOnly
                                            
                                                className=" p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                />
                                            </div>
                                        </div>
                                        <div className="sm:col-span-6 
                                        ">
                                            <label htmlFor="fromPeriod" className="block text-sm font-medium leading-6 text-gray-900">
                                                Select Date 
                                            </label>
                                            <div className="mt-2">
                                            <select  
                                                className="uppercase p-2.5 block w-full
                                                rounded-md border-0 py-1.5 text-gray-900 
                                                shadow-sm ring-1 ring-inset ring-gray-300
                                                placeholder:text-gray-400 focus:ring-2
                                                focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                
                                                name="SelectedMonth"
                                                id="SelectedMonth"
                                                value={SelectedMonth}
                                                autoComplete="SelectedMonth"
                                                onChange={handleChange}
                                
                                                >
                                                <option value="">Select Date</option>
                                                <option value="January - March">1st Quarter</option>
                                                <option value="April - June">2nd Quarter</option>
                                                <option value="July - September">3rd Quarter</option>
                                                <option value="October - December">4th Quarter</option>
                                                <option value="January">January</option>
                                                <option value="February">February</option>
                                                <option value="March">March</option>
                                                <option value="April">April</option>
                                                <option value="May">May</option>
                                                <option value="June">June</option>
                                                <option value="July">July</option>
                                                <option value="August">August</option>
                                                <option value="September">September</option>
                                                <option value="October">October</option>
                                                <option value="November">November</option>
                                                <option value="December">December</option>
                                        
                                                </select>
                                            
                                            </div>
                                        </div>
                                        <div className="sm:col-span-6 
                                        ">
                                            <label htmlFor="toPeriod" className="block text-sm font-medium leading-6 text-gray-900">
                                                Year 
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                id="Year"
                                                type="number"
                                                value={Year}
                                                onChange={handleChangeYear}
                                                min="1900"
                                                max="2099"
                                                placeholder="YYYY"
                                                className="uppercase p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                />
                                          
                                            </div>
                                        </div>
                                        <div className="sm:col-span-6 
                                        ">
                                            <label htmlFor="fromPeriod" className="block text-sm font-medium leading-6 text-gray-900">
                                                Type
                                            </label>
                                            <div className="mt-2">
                                            <select  
                                                className="uppercase p-2.5 block w-full
                                                rounded-md border-0 py-1.5 text-gray-900 
                                                shadow-sm ring-1 ring-inset ring-gray-300
                                                placeholder:text-gray-400 focus:ring-2
                                                focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                
                                                name="Type"
                                                id="Type"
                                                value={Type}
                                                autoComplete="Type"
                                                onChange={handleChange}>    
                                                <option value="VT">VT</option>
                                                <option value="NVT">NVT</option>
                                                </select>
                                            
                                            </div>
                                        </div>
                                     </div>
                                     <div className="flex items-center justify-end border-t border-gray-900/10  py-4  uppercase">
                                            <button
                                             onClick={() => CreateModule()} 
                                            type="submit"
                                            className="block rounded-md  bg-green-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 uppercase"
                                            >
                                            Generate
                                            </button>
                                        </div>
                                </div>
              
                            </Dialog.Panel>
                            </Transition.Child>
                        </div>
                        </div>
                    </Dialog>
         </Transition.Root>
        </div>
     );

}
export default Taxwithheld;