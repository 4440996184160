import { useEffect,useState,useContext } from 'react'
import { ExclamationTriangleIcon,PencilSquareIcon,Cog6ToothIcon,LockClosedIcon } from '@heroicons/react/20/solid'
import PaginatedTable from '../../components/pagination/paginatedtable.component'
import { UserContext } from '../../contexts/user.context'
import {
  TagIcon,
  BuildingStorefrontIcon,
  UserIcon
} from '@heroicons/react/24/outline'
import SettingNavigation from '../setting/SettingNavigation.layout'
import './sweetbutton.css'; //
import Swal from 'sweetalert2';
const UserList =(props) =>{


    const secondaryNavigation = [
      { name: 'Classification Expense', href: '/setting/classification', icon: TagIcon, current: false },
      { name: 'Classification Sales', href: '/setting/classification/sales', icon: TagIcon, current: false },
      { name: 'User', href: '/setting/user', icon: UserIcon, current: true },
      { name: 'Supplier', href: '/setting/supplier', icon: BuildingStorefrontIcon, current: false },
    ]
    
    const pages = [
        { name: 'User', href: '#', current: false },
      ]
    
      
      function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
      }
      
      

    const [userList, setUserList] = useState([]);
    const { company_id } = useContext(UserContext);
    const [isCompletesubmit, setCompletesubmit] = useState(false);


    
    const [Searchdata, setInputValue] = useState('');

    const [Pagination, setPagination]= useState([]);


    const handleInputChange = (event) => {
        setInputValue(event.target.value);
      };

    useEffect( () => {
        setLoad(false);

        LoadUserList();
        setLoad(true);
       
    }, []); 

    const handlePageChangeParent = (newPage) => {
      // LoadPendingList(newPage);
      setUserList([]);
      LoadUserList(newPage)

      console.log(newPage);
    };


    const handleSubmitSearch = (event) => {
        event.preventDefault();
        setNodata(true);
        LoadUserList();
      };
 
  const LoadUserList = async (page = 1) => {
        console.log(company_id);
      const postData = {
        "company_id": company_id,
        "searchdata":Searchdata,
        "page":page
      };
  
      console.log(postData);
      const requestOptions = {
        method: 'POST',
        headers: 
      { 'Content-Type': 'application/json',
      'Accept': 'application/json', 
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
        body: JSON.stringify(postData)
      };
  
     await fetch(process.env.REACT_APP_API_URL+'api/user/list', requestOptions)
        .then( async response => 
          {
              console.log(response.status);
            // Check the status code
            if (response.status === 422) {
              // API call succeeded
  
              const data = await response.json();
          
              // handleData(data.message);
              setNodata(false);
            }else{
              const data = await response.json();
               setUserList(data.data); 
               setPagination(data.pagination)
            //  navigate('/');
            }
          }
          
        )
        .then(data => {
          // Handle the response
       
          console.log(data);
          setLoad(true);
        })
        .catch(error => {
          // Handle any errors
       
        });
    } 

    const [isLoad, setLoad] = useState(false);
    const [isNodata, setNodata] = useState(false);

    
    const ResetPassword =  (user_id) => {
        
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
      })
      

   
 
      swalWithBootstrapButtons.fire({

        text: "Do you want to reset ?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
        reverseButtons: true
      }).then(async (result) => {
        if (result.isConfirmed) {
  
          setCompletesubmit(true);

     
          const postData = {
            "user_id": user_id,
          };
 
          const requestOptions = {
            method: 'POST',
            headers: 
            { 'Content-Type': 'application/json',
            'Accept': 'application/json', 
             'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
            body: JSON.stringify(postData)
          };
  
          await fetch(process.env.REACT_APP_API_URL+'api/reset/password', requestOptions)
          .then( async response => 
            {
                console.log(response);
              // Check the status code
              if (response.status === 422) {
                // API call succeeded
  
                const data = await response.json();
            
                
  
              
              }else{
                const data = await response.json();
                console.log(data.message);  
                // setCompletesubmit(false);
                
                // Swal.fire({
                //     position: 'top-end',
                //     icon: 'success',
                //     title: 'Email sent! Please check your inbox or spam folder.',
                //     showConfirmButton: false,
                //     timer: 6000
                // })  
                
  
                setCompletesubmit(false);
              }
            }
            
          )
          .then(data => {
            // Handle the response
            console.log(data);
          })
          .catch(error => {
            // Handle any errors
            console.error(error);
          });
  
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
  
        }
      })
     

    }

    return (
        <div >
          <div className={isCompletesubmit ? "opacity-20" : ""}>
          <nav className="flex mt-4" aria-label="Breadcrumb">
            <ol role="list" className="flex items-center space-x-4">
                <li>
                <div>
                    <a href="#" className="text-gray-400 hover:text-gray-500">
                    <Cog6ToothIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                    <span className="sr-only">Home</span>
                    </a>
                </div>
                </li>
            {pages.map((page) => (
            <li key={page.name}>
                <div className="flex items-center">
                <svg
                    className="h-5 w-5 flex-shrink-0 text-gray-300"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                >
                    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                </svg>
                <a
                    href={page.href}
                    className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                    aria-current={page.current ? 'page' : undefined}
                >
                    {page.name}
                </a>
                </div>
            </li>
            ))}
        </ol>
        </nav>

        <div className="flex gap-4">
        <div className="w-1/5">
                <aside className="flex  border-b border-gray-900/5 py-4 lg:block lg:w-64 lg:flex-none lg:border-0 lg:py-20">
                     <SettingNavigation pageIndex={2}/>
                    </aside>

             </div>
             <div className="w-4/5">
                  <main>
                      <div className="sm:flex sm:items-center mt-4">
                      <div className="sm:flex-auto">
                        <h1 className="text-base font-semibold leading-6 text-gray-900">Users</h1>
                      
                      </div>
                      <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                        <button
                          type="button"
                          onClick={props.GotoAddUser}
                          className=" block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          Add User
                        </button>
                      </div>
                    </div>

                              
                  <form onSubmit={handleSubmitSearch}>
                      <div class="relative mt-4 w-1/3">
                          <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                              <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                              </svg>
                          </div>
                          <input type="search"
                          id="default-search" 
                          class="uppercase block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                          placeholder="Search user..." 
                          value={Searchdata}
                          onChange={handleInputChange}
                          />
                          <button type="submit"     class="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Search</button>
                      </div>
                  </form>

                    <div className= {isLoad? 'mt-2 flow-root  ':'mt-2 flow-root  rounded shadow animate-pulse md:p-6 dark:border-gray-700'}>
                      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                              <thead className="bg-gray-50">
                                <tr>
                                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    Name
                                  </th>
                                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Email
                                  </th>
                                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Contact
                                  </th>
                                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Role
                                  </th>
                                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Status
                                  </th>
                                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                    <span className="sr-only">Edit</span>
                                  </th>
                                  
                                </tr>
                              </thead>
                              <tbody className="divide-y divide-gray-200 bg-white">
                                {userList.map((user) => (
                                  <tr key={user.email}>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                        {user.first_name} {user.middle_name} {user.last_name} {user.suffix}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.email}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.mobile}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.role}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                      {
                                        (user.status==1)? <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                        Active
                                      </span> :
                                        <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">
                                        Inactive
                                        </span>
                                      }
                                    
                                    </td>
                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                          <div className='flex gap-4'>
                                              <div>
                                                  <button   
                                                    onClick={() =>ResetPassword(user.user_id)}   className="rounded-full bg-gray-500 p-1 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                    >
                                                        <LockClosedIcon className="h-5 w-5" aria-hidden="true" />
                                                    <span class="sr-only">{user.id}</span></button>
                                              </div>
                                              <div>
                                                  <button   
                                                    onClick={() =>props.GotoEditUser(
                                                      user.email.toLowerCase(),
                                                      user.first_name,
                                                      user.middle_name,
                                                      user.last_name,
                                                      user.suffix,
                                                      user.mobile,
                                                      user.role,
                                                      user.user_id,
                                                      user.privelege
                                                    )}   className="rounded-full bg-gray-500 p-1 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                    >
                                                        <PencilSquareIcon className="h-5 w-5" aria-hidden="true" />
                                                    <span class="sr-only">{user.id}</span></button>
                                              </div>
                                          </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            {userList.length===0 && isNodata?
                            <div className="rounded-md bg-yellow-50 p-4">
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                    <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                                    </div>
                                    <div className="ml-3">
                                    
                                    <div className="mt-2 text-sm text-yellow-700 ">
                                        <p>
                                                Data Not Found
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            :
                            ''}
                            <div className="container mx-auto p-4">
                              <PaginatedTable data={userList} pagination={Pagination} handlePageChangeParent={handlePageChangeParent}/>
                            </div>
                      

                          </div>
                        </div>
                      </div>
                    </div>
                      
                  </main>
             </div>
          </div>
        
          </div>
          {isCompletesubmit?
                  <div role="status" class="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2" >
                      <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                      <span class="sr-only">Loading...</span>
                  </div>   
              :''}
        
      </div>);

}
export default UserList;