import { createContext, useState , useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
export const UserContext = createContext({
  setCurrentUser: () => null,
  currentUser: null,
  Tax_types: []
});

export const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState((localStorage.getItem('useSession') !==null)?localStorage.getItem('useSession') : null);

  const [JWTtoken, setJWTtoken] = useState((localStorage.getItem('jwtToken') !==null)?localStorage.getItem('jwtToken') : null);

  const [username, setUsername] =useState((localStorage.getItem('username') !==null)?localStorage.getItem('username') : null);

  const [company_id, setCompany_id] =useState((localStorage.getItem('company_id') !==null)?localStorage.getItem('company_id') : null);

  const [user_id, setUser_id] =useState((localStorage.getItem('user_id') !==null)?localStorage.getItem('user_id') : null);


  const [is_vat, setIs_vat] =useState((localStorage.getItem('is_vat') !==null)?localStorage.getItem('is_vat') : null);

  const [privelegeUser, setPrivelegeUser] =useState((localStorage.getItem('privelege') !==null)?localStorage.getItem('privelege') : null);


  const [positionUser, setpositionUser] =useState((localStorage.getItem('position') !==null)?localStorage.getItem('position') : null);

  const [ModeEncoder, setModeEncoder] =useState((localStorage.getItem('ModeEncoder') !==null)?localStorage.getItem('ModeEncoder') : 'ChangeMode');

  const [Tax_types, setTax_types] =useState((localStorage.getItem('Tax_types') !==null)?localStorage.getItem('Tax_types') : null)

  const [Trade_name, setTrade_name] =useState((localStorage.getItem('trade_name') !==null)?localStorage.getItem('trade_name') : null)

  const [Tin, setTin] =useState((localStorage.getItem('tin') !==null)?localStorage.getItem('tin') : null)

  const [Reg_address, setReg_address] =useState((localStorage.getItem('reg_address') !==null)?localStorage.getItem('reg_address') : null)

  const [VersionApp, setVersionApp] =useState((localStorage.getItem('VersionApp') !==null)?localStorage.getItem('VersionApp') : null)

  const [MaintenanceMode, setMaintenanceMode] =useState((localStorage.getItem('MaintenanceMode') !==null)?localStorage.getItem('MaintenanceMode') : null)

  const navigate = useNavigate();


  useEffect(() => {
    fetch_setup();
    Check_Token_Is_Valid();
    const storedData = localStorage.getItem('useSession');

    if (storedData) {
      const data = JSON.parse(storedData);
      const currentTime = new Date().getTime();

      if (currentTime < data.expiresAt) {
        // Data has not expired, you can use it
        console.log(data.value);
      } else {
        // Data has expired, remove it from localStorage
        handleRemove();
      }
    }

  }, []);


  // const setTax_typesUpdate = () => {
  //   setTax_types((localStorage.getItem('Tax_types') !==null)?localStorage.getItem('Tax_types') : null);
  // };

  const handleRemove = () => {
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('useSession');
    setCurrentUser(null);
    setJWTtoken(null);
  };


  const Check_Token_Is_Valid = async () => {

    const tokenData = localStorage.getItem('jwtToken');
    
    // Check if the token data is null or not
    if (!tokenData) {
       
        handleRemove(); // or any other action you want to take
        return; // Exit the function early
    }

      
   
    const requestOptions = {
      method: 'POST',
      headers: 
      { 'Content-Type': 'application/json',
      'Accept': 'application/json', 
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
    };

    await fetch(process.env.REACT_APP_API_URL+'api/check_is_login', requestOptions)
    .then( async response => 
      {
          console.log(response.status);
        // Check the status code
        if (response.status === 401) {
          handleRemove();
        }
      }
      
    )
    .then(data => {
      // Handle the response
      console.log(data);
    })
    .catch(error => {
      // Handle any errors
      console.error(error);
    });
        
      
    }


    
  const fetch_setup = async () => {

   

      
   
    const requestOptions = {
      method: 'POST',
      headers: 
      { 'Content-Type': 'application/json',
      'Accept': 'application/json' },
    };

    await fetch(process.env.REACT_APP_API_URL+'api/fetch/setup', requestOptions)
    .then( async response => 
      {
 
        if (response.status === 201) {

          const data = await response.json();
          setVersionApp(data.version);
          setMaintenanceMode(data.maintenance);

          if(data.maintenance==1){
            navigate('/maintainance');
          }
          console.log(data.maintenance);
        }   
      }
      
    )
    .then(data => {
      // Handle the response
      console.log(data);
    })
    .catch(error => {
      // Handle any errors
      console.error(error);
    });
        
      
    }

  //call localstorage
  
  const Login = (responseJWT,username,company_id,user_id,is_vat,privelege,position,tax_types,trade_name,tin,reg_address) => {
    // Here you would validate the user's credentials with your API or backend.
    // For the sake of this example, we'll just set the user object to a dummy value.

    setCompany_id(company_id);
    localStorage.setItem('company_id', company_id);

    setTrade_name(trade_name);
    localStorage.setItem('trade_name', trade_name);


    setTin(tin);
    localStorage.setItem('tin', tin);

    setReg_address(reg_address);
    localStorage.setItem('reg_address', reg_address);


    setUser_id(user_id);

    localStorage.setItem('user_id', user_id);
    
    setUsername(username);
    localStorage.setItem('username', username);

    setTax_types(tax_types);
     localStorage.setItem('Tax_types', JSON.stringify(tax_types));
     

    setIs_vat(is_vat);
    localStorage.setItem('is_vat', is_vat);

    setPrivelegeUser(privelege);
    localStorage.setItem('privelege', privelege);

    setpositionUser(position);
    localStorage.setItem('position', position);

    const data = {
      value: responseJWT,
      expiresAt: new Date().getTime() + 24 * 60 * 60 * 1000 // Set expiration time to 1 hour from now
    };

    localStorage.setItem('jwtToken', JSON.stringify(data));


    setCurrentUser({
      sessionIdentifier: JSON.stringify(data)
    });
    
    localStorage.setItem('useSession', JSON.stringify(data));


   
  };
  const SignOutAPi = async () => {

    try {
      await fetch(process.env.REACT_APP_API_URL+'/api/authentication/signout', {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        }
     
      })
      

    } catch (error) {
      console.error(error);
    }
  };
  const Logout = () => {
    setCurrentUser(null);
    handleRemove();
    SignOutAPi();

  };


  return <UserContext.Provider value={{ currentUser,JWTtoken,username,company_id,user_id,is_vat,privelegeUser,positionUser,ModeEncoder,Tax_types,Trade_name,Tin,Reg_address,VersionApp,MaintenanceMode,setModeEncoder, Login, Logout }}>{children}</UserContext.Provider>;
};