import { UserContext } from '../../contexts/user.context'
import { useState,useEffect,useContext } from 'react'
import {
    ArrowPathIcon,
    ChartPieIcon,
    Cog6ToothIcon,
    DocumentDuplicateIcon,
    HomeIcon,
    PencilSquareIcon,
    CheckBadgeIcon,
    UsersIcon,
    ArrowLeftCircleIcon,
    CurrencyDollarIcon,
    CalculatorIcon,
    ReceiptPercentIcon,
    PlusCircleIcon,
  } from '@heroicons/react/24/outline'
  import { Disclosure } from '@headlessui/react'
  import { ChevronRightIcon, DocumentTextIcon } from '@heroicons/react/20/solid'
import { FlagIcon, FolderIcon } from '@heroicons/react/24/solid'
import { useNavigate } from 'react-router-dom'



  
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }


const SidebarNavigation =(props) =>{


  const { positionUser,privelegeUser } = useContext(UserContext);

  
  const navigate = useNavigate();
  const { ModeEncoder, setModeEncoder} = useContext(UserContext);

  const { VersionApp} = useContext(UserContext);

  const { Tax_types} = useContext(UserContext);


  useEffect(() => {
    
    ChangeMode(ModeEncoder);

    if (positionUser == 'Encoder') {
    removeHistory();
    }
  }, []);


  
const [navigation,setnavigation] = useState([
  { name: 'Dashboard',NavId:0,activeName: 'Dashboard', href: '/dashboard',Mode: null, icon: HomeIcon },
  { name: 'SALES JOURNAL',NavId:0,activeName: 'Sales', href: '#',Mode: 'Sales', icon: FolderIcon },
  { name: 'EXPENSE JOURNAL',NavId:0,activeName: 'Expense', href: '#',Mode: 'Expense',icon: FolderIcon },

  {
    name: 'Tax Compliance',
    NavId:0,
    icon: CurrencyDollarIcon,
    current: false,
    children: [
      { name: '2307 Module',activeName: '2307', href: '/tax/2307' },
      { name: 'Withholding Rates',activeName: 'witholdingrates', href: '/witholding/rates' ,ifvisible:true },
    ],
  
  },
  {
    name: 'Tax Calculator',
    NavId:0,
    icon: CalculatorIcon,
    current: false,
    children: [
      { name: 'Income Tax',activeName: 'IncomeTax', href: '#' ,ifvisible:true 
        , children: [
            { name: 'Annual', activeName: 'Annual', href: '#', ifvisible: true },
            { name: 'Quarterly', activeName: 'Quarterly', href: '#', ifvisible: true },
        ]
      },
      { name: 'Percentage',activeName: 'Percentage', href: '#' ,ifvisible:true},
      { name: 'VAT',activeName: 'VAT', href: '#' ,ifvisible:true},
      { name: 'Expanded Withholding',activeName: 'EXPANDEDWITHHOLDING', href: '#' ,ifvisible:true},

    ],
  
  },
  { name: 'Activity Log',NavId:0,activeName: 'Activity Log', href: '/history',Mode: null, icon: HomeIcon },
  
]);




  
  const ModeEncoding = (Mode) => {
    


    localStorage.setItem('ModeEncoder', Mode);
    setModeEncoder(Mode);
   
    ChangeMode(Mode);
    navigate('/dashboard');
  
  };


  const removeHistory = () => {
    setnavigation(navigation => navigation.filter(item => item.name !== "History"));

    console.log(navigation);
  };

  const isChecked = (id) => {

      
    try {
        // const taxTypesObject = JSON.parse(Tax_types);
        // // Parsing successful, taxTypesObject now contains the parsed object
    

        const item = Tax_types.find(item => item.id === id);
        return item ? item.checked : false; // Return false if item is not found
    
    } catch (error) {

               const taxTypesObject = JSON.parse(Tax_types);
        // Parsing successful, taxTypesObject now contains the parsed object
    

        const item = taxTypesObject.find(item => item.id === id);
        return item ? item.checked : false; // Return false if item is not found
    }

};

  const ChangeMode =(Mode)=> {
    if(Mode=='ChangeMode'){

      setnavigation([
      { name: 'Dashboard',NavId:0,activeName: 'Dashboard', href: '/dashboard',Mode: null, icon: HomeIcon ,ifvisible:true},
      { name: 'SALES JOURNAL',NavId:0,activeName: 'Sales', href: '#',Mode: 'Sales', icon: FolderIcon },
      { name: 'EXPENSE JOURNAL',NavId:0,activeName: 'Expense', href: '#',Mode: 'Expense',icon: FolderIcon },
      {
        name: 'General Ledger',
        NavId:0,
        icon: DocumentTextIcon,
        current: false,
        children: [

          { name: 'General Journal',activeName: 'general_journal', href: '/general/journal',ifvisible: true },
          { name: 'General Ledger',activeName: 'general_ledger', href: '#' ,ifvisible: true},

        ],
      
      },
      {
        name: 'Taxation',
        NavId:0,
        icon: ReceiptPercentIcon,
        current: false,
        children: [
          {
             name: 'Tax Compliance',
              href: '#',
              ifvisible:true,
              children: [
                { name: 'BIR 2307 Module',activeName: '2307', href: '/tax/2307',ifvisible:true },
                { name: 'Withholding Rates',activeName: 'witholdingrates', href: '/witholding/rates' ,ifvisible:true },
              ],

          },
          { name: 'Tax Returns and Filing',

            href: '#',ifvisible:true,
            children: [

              { name: 'INCOME TAX',activeName: 'return_incometax', href: '/tax/payout/return_incometax',ifvisible: isChecked(1) },
              { name: 'PERCENTAGE TAX (PT)',activeName: 'return_PT', href: '/tax/payout/return_PT' ,ifvisible: isChecked(2)},
              { name: 'VALUE ADDED TAX (VT)',activeName: 'return_VT', href: '/tax/payout/return_VT' ,ifvisible: isChecked(3)},
              { name: 'EXPANDED WITHHOLDING (WE)',activeName: 'return_WE', href: '/tax/payout/return_WE',ifvisible: isChecked(4) },
              { name: 'WITHHOLDING ON COMPENSATION (WC)',activeName: 'return_WC', href: '/tax/payout/return_WC',ifvisible: isChecked(5) },
              { name: 'DOCUMENTARY STAMP TAX (DS)',activeName: 'return_DS', href: '/tax/payout/return_DS',ifvisible: isChecked(6) },
              { name: 'FINAL WITHHOLDING TAX (WF)',activeName: 'return_WF', href: '/tax/payout/return_WF',ifvisible: isChecked(7) },
            ],
          },
          { name: 'Tax Calculator',

            href: '#',
            ifvisible:true,
            children: [
              { name: 'Income Tax',activeName: 'IncomeTax', href: '#' ,ifvisible:true 
                  , children: [
                      { name: 'Annual', activeName: 'Annual', href: '#', ifvisible: true },
                      { name: 'Quarterly', activeName: 'Quarterly', href: '#', ifvisible: true },
                  ]
              },
              { name: 'Percentage',activeName: 'Percentage', href: '#' ,ifvisible:true},
              { name: 'VAT',activeName: 'VAT', href: '#' ,ifvisible:true},
              { name: 'Expanded Withholding',activeName: 'EXPANDEDWITHHOLDING', href: '#' ,ifvisible:true},
            ],
           },
      
        ],
      
      },
   
      { name: 'Activity Log',NavId:0,activeName: 'Activity Log', href: '/history',Mode: null, icon: ArrowPathIcon },
      ]);
    }

    if(Mode=='Expense'){
     
      setnavigation([
        { name: 'Back',NavId:0,activeName: 'Expense', href: '#',Mode: 'ChangeMode',icon: ArrowLeftCircleIcon }, 
        {
          name: 'Create',
         NavId:0,
         href: '/expense/receipt', icon: PlusCircleIcon
        },
        {
          name: 'Expense Transactions',
         NavId:1,
         href: '/expense/list', icon: DocumentDuplicateIcon
        },
        {
         name: 'Expense Verification',
        NavId:2,
        href: '/expense/verification', icon: CheckBadgeIcon
       },
           {
        name: 'Reports',
        icon: ChartPieIcon,
        current: false,
        children: [
          { name: 'Expense Archive', NavId:5,activeName: 'Summary', href: '/report/expense' ,ifvisible:true},
          { name: 'Expense Report ', NavId:6,activeName: 'Substantiation', href: '/report/substantiation',ifvisible:true },
          { name: 'Disapproved Expense', NavId:7,activeName: 'Disapproved', href: '/report/disapproved',ifvisible:true },
          { name: 'Summary list of purchase', NavId:8,activeName: 'PurchaseTransaction', href: '/report/purchase/transaction' ,ifvisible:true}
          // { name: 'Sales', href: '#' },
        ],
      },

       
      ]);

      // PrivelegeLoad();
    }
    
    if(Mode=='Sales'){
      setnavigation([
        { name: 'Back',NavId:0,activeName: 'Expense', href: '#',Mode: 'ChangeMode',icon: ArrowLeftCircleIcon ,ifvisible:true},
        {
          name: 'Create',
         NavId:0,
         href: '/sales/receipt', icon: PlusCircleIcon
        },
        {
          name: 'Sales Transactions',
         NavId:3,
         href: '/sales/list', icon: DocumentDuplicateIcon
        },
     
        { name: 'Sales Verification',
        NavId:4,
         href: '/sales/verification', icon: CheckBadgeIcon ,ifvisible:true},
       
       
        {
        name: 'Reports',
        icon: ChartPieIcon,
        current: false,
        children: [

          { name: 'Sales Archive', NavId:8, activeName: 'Sales_approve', href: '/report/sales',ifvisible:true },
          { name: 'Sales Report ', NavId:9, activeName: 'Sales_summary', href: '/report/sales/summary',ifvisible:true },
          { name: 'Disapproved Sales', NavId:10, activeName: 'Sales_Disapproved', href: '/report/sales/disapproved',ifvisible:true },
          { name: 'Summary list of sales', NavId:8,activeName: 'SellTransaction', href: '/report/sell/transaction',ifvisible:true }
          // { name: 'Sales', href: '#' },
        ],
      },
      ]);

      // PrivelegeLoad();
    }
  }

   return(
    <>
     <nav className="flex flex-1 flex-col uppercase print-hide">
        <ul className="flex flex-1 flex-col gap-y-7">
        <li>
            <ul  className="-mx-2 space-y-1">
              <p className='text-center text-zinc-100'>Version: {VersionApp}</p>
            {navigation.map((item) => (
              
                   //first
                <li key={item.name}>
                 {!item.children ? (   
                <a
                    href={item.href}
                    onClick={item.Mode != null ? () => ModeEncoding(item.Mode) : null}
                    
                    className={classNames(
                    item.name===props.activepage
                        ? 'bg-indigo-700 text-white'
                        : 'text-indigo-200 hover:text-white hover:bg-indigo-700',
                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                    )}
                >
                    <item.icon
                    className={classNames(
                        item.current ? 'text-white' : 'text-indigo-200 group-hover:text-white',
                        'h-6 w-6 shrink-0'
                    )}
                    aria-hidden="true"
                    />
                    {item.name}{props.active}
                </a>
                //first
                    ) : (
                        <Disclosure as="div" defaultOpen={(item.name===props.activepage)? true : false}>
                          {({ open }) => (
                            <>
                          
                              <Disclosure.Button
                                className={classNames(
                                    item.name===props.activepage
                                    ? 'bg-indigo-700 text-white'
                                    : 'text-indigo-200 hover:text-white hover:bg-indigo-700',
                                  'flex items-center w-full text-left rounded-md p-2 gap-x-3 uppercase leading-6 font-semibold text-gray-700'
                                )}
                              >


                                <item.icon className="h-6 w-6 shrink-0 text-indigo-200" aria-hidden="true" />
                                {item.name}
                                <ChevronRightIcon
                                  className={classNames(
                                    open ? 'rotate-90 text-white' : 'text-indigo-200 group-hover:text-white',
                                    'ml-auto h-5 w-5 shrink-0'
                                  )}
                                  aria-hidden="true"
                                />
                              </Disclosure.Button>
                      
                              <Disclosure.Panel as="ul" className="mt-1 px-2">

                               <>
                                {item.children.map((subItem) => (
                                    subItem.ifvisible ? (

                                      <Disclosure as="div" defaultOpen={(subItem.name === props.subactivepage)? true : false}>
                                        {({ open }) => (     
                                        <li key={subItem.name}>
                                            
                                            <Disclosure.Button
                                                as="a"
                                                href={subItem.href}
                                                className={classNames(
                                                    subItem.name === props.subactivepage
                                                        ? 'flex bg-indigo-700 text-white text-sm'
                                                        : 'flex text-indigo-200 hover:text-white hover:bg-indigo-700',
                                                    'block rounded-md py-2 pr-2 pl-9 text-sm leading-6 text-gray-700'
                                                )}
                                            >
                                                {subItem.name}

                                                {subItem.children && subItem.children.length > 0 && (
                                                <ChevronRightIcon
                                                    className={classNames(
                                                      open ? 'rotate-90 text-white' : 'text-indigo-200 group-hover:text-white',
                                                      'ml-auto h-5 w-5 shrink-0 '
                                                    )}
                                                    aria-hidden="true"
                                                  />   
                                                  
                                                  )}
                                                    </Disclosure.Button>
                                                    {/* Render sub-children if they exist */}
                                                    {subItem.children && subItem.children.length > 0 && (
                                                        <Disclosure.Panel as="ul" className="ml-4">
                                                            {subItem.children.map((subChildItem) => (
                                                                subChildItem.ifvisible ? (
                                                                    <li key={subChildItem.name}>
                                                                     <Disclosure as="div" defaultOpen={(subItem.name === props.subactivepage)? true : false}>
                                                                          {({ open }) => (
                                                                            <>
                                                                            
                                                                              <Disclosure.Button
                                                                                  as="a"
                                                                                  href={subChildItem.href}
                                                                                  className={classNames(
                                                                                      subChildItem.activeName === props.subsubactivepage
                                                                                          ? 'bg-indigo-700 text-white text-sm'
                                                                                          : 'text-indigo-200 hover:text-white hover:bg-indigo-700',
                                                                                      'flex  rounded-md py-2 pr-2 pl-9 text-sm leading-6 text-gray-700'
                                                                                  )}
                                                                              >
                                                                                  {subChildItem.name}

                                                                                  {subChildItem.children && subChildItem.children.length > 0 && (
                                                                                <ChevronRightIcon
                                                                                    className={classNames(
                                                                                      open ? 'rotate-90 text-white' : 'text-indigo-200 group-hover:text-white',
                                                                                      'ml-auto h-5 w-5 shrink-0 '
                                                                                    )}
                                                                                    aria-hidden="true"
                                                                                  />   
                                                                                  
                                                                                  )}
                                                                                
                                                                              </Disclosure.Button>
                                                                                    {subChildItem.children && subChildItem.children.length > 0 && (
                                                                                <Disclosure.Panel as="ul" className="ml-4">
                                                                                    {subChildItem.children.map((subsChildItem) => (
                                                                                        subChildItem.ifvisible ? (
                                                                                            <li key={subChildItem.name}>
                                                                  
                                                                                                    <>
                                                                                                    
                                                                                                      <Disclosure.Button
                                                                                                          as="a"
                                                                                                          href={subsChildItem.href}
                                                                                                          className={classNames(
                                                                                                            subsChildItem.activeName === props.subsubactivepage
                                                                                                                  ? 'bg-indigo-700 text-white text-sm'
                                                                                                                  : 'text-indigo-200 hover:text-white hover:bg-indigo-700',
                                                                                                              'flex  rounded-md py-2 pr-2 pl-9 text-sm leading-6 text-gray-700'
                                                                                                          )}
                                                                                                      >
                                                                                                          {subsChildItem.name}

                                                                                                          {subsChildItem.children && subsChildItem.children.length > 0 && (
                                                                                                        <ChevronRightIcon
                                                                                                            className={classNames(
                                                                                                              open ? 'rotate-90 text-white' : 'text-indigo-200 group-hover:text-white',
                                                                                                              'ml-auto h-5 w-5 shrink-0 '
                                                                                                            )}
                                                                                                            aria-hidden="true"
                                                                                                          />   
                                                                                                          
                                                                                                          )}
                                                                                                        
                                                                                                      </Disclosure.Button>
                                                                                                      
                                                                                                    </>
                                                                                                
                                                                                                
                                                                                            </li>
                                                                                        ) : null
                                                                                    ))}
                                                                                </Disclosure.Panel>
                                                                        )}
                                                                            </>
                                                                            )}
                                                                          </Disclosure>
                                                                       
                                                                    </li>
                                                                ) : null
                                                            ))}
                                                        </Disclosure.Panel>
                                                    )}
                                                </li>
                                                )}
                                                </Disclosure>

                                            ) : null
                                        ))}
                                        
                                        
                                        </>
                             
                                    </Disclosure.Panel>
                                 
                            </>
                          )}
                        </Disclosure>
                      )}
                </li>
            ))}
            </ul>
        </li>
        {(positionUser !='Encoder' && positionUser !='Bookkeeper' )?

        <li className="mt-auto ">
            <a
            href="/setting/classification/sales"
            className={classNames(
              'Setting'===props.activepage
              ? 'bg-indigo-700 text-white'
              : 'text-indigo-200 hover:text-white hover:bg-indigo-700',
            'flex items-center w-full text-left rounded-md p-2 gap-x-3 uppercase leading-6 font-semibold text-gray-700'
          )}
            >
            <Cog6ToothIcon
                className="h-6 w-6 shrink-0 text-indigo-200 group-hover:text-white"
                aria-hidden="true"
            />
            Settings
            </a>
        </li>
        :''}
        </ul>
    </nav>
    </>
   ) 
}

export default SidebarNavigation;