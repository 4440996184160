
import {
    TagIcon,UserIcon,BuildingStorefrontIcon,Cog6ToothIcon
  } from '@heroicons/react/24/outline'
  import SettingNavigation from '../setting/SettingNavigation.layout'
const UserEdit =(props) =>{
    const secondaryNavigation = [
        { name: 'Classification Expense', href: '/setting/classification', icon: TagIcon, current: false },
        { name: 'Classification Sales', href: '/setting/classification/sales', icon: TagIcon, current: false },
        { name: 'User', href: '/setting/user', icon: UserIcon, current: true },
        { name: 'Supplier', href: '/setting/supplier', icon: BuildingStorefrontIcon, current: false },
      ]
      
      const pages = [
          { name: 'User', href: '#', current: false },
        ]
      
      function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
      }
      
    return (
    <>
         <nav className="flex mt-4" aria-label="Breadcrumb">
            <ol role="list" className="flex items-center space-x-4">
                <li>
                <div>
                    <a href="#" className="text-gray-400 hover:text-gray-500">
                    <Cog6ToothIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                    <span className="sr-only">Home</span>
                    </a>
                </div>
                </li>
            {pages.map((page) => (
            <li key={page.name}>
                <div className="flex items-center">
                <svg
                    className="h-5 w-5 flex-shrink-0 text-gray-300"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                >
                    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                </svg>
                <a
                    href={page.href}
                    className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                    aria-current={page.current ? 'page' : undefined}
                >
                    {page.name}
                </a>
                </div>
            </li>
            ))}
        </ol>
        </nav>
        <div className="flex gap-4">
        <div className="w-1/5">
             <aside className="flex  border-b border-gray-900/5 py-4 lg:block lg:w-64 lg:flex-none lg:border-0 lg:py-20">
                    <SettingNavigation pageIndex={2}/>
                    
                </aside>
             </div>
             <div className="w-4/5">
                            

                <div className={props.isCompletesubmit ? "opacity-20 grid grid-cols-1 gap-x-8 gap-y-8 pt-10" : "grid grid-cols-1 gap-x-8 gap-y-8 pt-10"}>
                    <div className="px-4 sm:px-0">
                        <h2 className="text-base font-semibold leading-7 text-gray-900 lowercase">User Information <span style={{ color: 'green' }}>({props.Email.toLowerCase()})</span></h2>
                    
                    </div>
                    <form onSubmit={props.handleSubmitUpdate} className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2 uppercase">
                        <div className="px-4 py-6 sm:p-8">
                        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-12">
                            <div className="sm:col-span-4">
                            <label htmlFor="firstname" className="block text-sm font-medium leading-6 text-gray-900">
                                First name
                            </label>
                            <div className="mt-2">
                                <input
                                type="text"
                                name="first_name"
                                id="first_name"
                                autoComplete="first_name"
                                value={props.formFields.first_name}
                                required
                                onChange={props.handleChange}
                                className=" p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            </div>

                            <div className="sm:col-span-2">
                            <label htmlFor="middleinitial" className="block text-sm font-medium leading-6 text-gray-900">
                                Middle Name
                            </label>
                            <div className="mt-2">
                                <input
                                type="text"
                                name="middle_name"
                                id="middle_name"
                                autoComplete="middle_name"
                                value={props.formFields.middle_name}
                                
                                onChange={props.handleChange}
                                className=" p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            </div>

                            <div className="sm:col-span-4">
                            <label htmlFor="lastname" className="block text-sm font-medium leading-6 text-gray-900">
                                Last name
                            </label>
                            <div className="mt-2">
                                <input
                                type="text"
                                name="last_name"
                                id="last_name"
                                autoComplete="last_name"
                                value={props.formFields.last_name}
                                required
                                onChange={props.handleChange}
                                className=" p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            </div>

                            
                            <div className="sm:col-span-2">
                            <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                                Suffix
                            </label>
                            <div className="mt-2">
                                <select
                                id="suffix"
                                name="suffix"
                                autoComplete="suffix"
                                value={props.formFields.suffix}
                                
                                onChange={props.handleChange}
                                className=" p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                >   
                                    <option></option>
                                    <option value="Jr">Jr</option>
                                    <option value="Sr">Sr</option>
                                    <option value="I">I</option>
                                    <option value="II">II</option>
                                    <option value="IIIz">III</option>
                                    
                                
                                </select>
                            </div>
                            </div>

                            <div className="sm:col-span-4">
                            <label htmlFor="Position" className="block text-sm font-medium leading-6 text-gray-900">
                                Position
                            </label>
                            <div className="mt-2">
                                <select
                                id="position"
                                name="position"
                                autoComplete="position"
                                value={props.formFields.position}
                                required
                                onChange={props.handleChange}
                                className=" p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                >
                                    <option ></option>
                                    <option value="Manager">Manager</option>
                                    <option value="ACT-Clerk">Accounting Clerk</option>
                                    <option value="Encoder">Encoder</option>
                                    <option value="Bookkeeper">Bookkeeper</option>
                                </select>
                            </div>
                            </div>
                            
                            <div className="sm:col-span-4">
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                Mobile
                            </label>
                            <div className="mt-2">
                                <input
                                id="mobile"
                                name="mobile"
                                type="text"
                                autoComplete="mobile"
                                maxlength="14"
                                value={props.formFields.mobile}
                                required
                                onChange={props.handleChange}
                                className=" p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            </div>



                        
                        </div>
                        
                        </div>
                        <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8 ">
                            <button
                            type="submit"
                            className=" rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                            Save
                            </button>
                        </div>

                    </form>
                    {props.isErrorShow?
                    <div class="rounded-md bg-red-50 p-4 ">
                        <div class="flex">
                            <div class="flex-shrink-0">
                            <svg class="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z" clip-rule="evenodd" />
                            </svg>
                            </div>
                            <div class="ml-3">
                            <div class="mt-2 text-sm text-red-700">
                                <ul role="list" class="list-disc space-y-1 pl-5 ">
                            
                                </ul>
                            </div>
                            </div>
                        </div>
                    </div>
                    :''}
                    
                </div>   
            </div>
        </div>

   
  
    </>
   
  )
}
export default UserEdit;