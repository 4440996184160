import { Fragment, useContext,useState,useEffect,useRef } from 'react'

import TransitionComponent from '../../components/navigation/transition.component'
import SidebarNavigation from '../../components/navigation/navigation.component'
import HeaderComponent from '../../components/navigation/header.component'
import ReceiptEncodingList from '../../layout/expensetransaction/receiptencodinglist.layout'
import './sweetbutton.css'; //
import Swal from 'sweetalert2';
import { UserContext } from '../../contexts/user.context'
import ReceiptEncodingUpdate from '../../layout/expensetransaction/receiptencodingupdate.layout'
import { useNavigate } from 'react-router-dom'
import imageCompression from 'browser-image-compression';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon  } from '@heroicons/react/24/outline';

const ListEncoding =() =>{

  const { is_vat } = useContext(UserContext);
    const [sidebarOpen, setSidebarOpen] = useState(false)

    const { company_id } = useContext(UserContext);

    const cancelButtonRef = useRef(null)
    const [showSummaryCreate, setshowSummaryCreate] = useState(false);
    const [showListPending, setshowListPending] = useState(true);
  
    const { user_id } = useContext(UserContext);
  
    const [TransactionType, setTransactionType] = useState('expense');

    const [open, setOpen] = useState(false)
    const [convertDone, setConvertDone] = useState(false);

    const [selectedFile, setSelectedFile] = useState(null);


    const [convertDoneSupplemental, setConvertDoneSupplemental] = useState(false);


    const [expense_id, SetExpense_id] = useState('');
    const [ModalItemIndex, setModalItemIndex] = useState(0);


    
    const { Tax_types } = useContext(UserContext);


    const CheckTax_Withhold = ()=>{

      const item =JSON.parse(Tax_types).find(item => item.id === 4);

      return item.checked;
     
    }
    



    const SaveItemModal = () => {



      const updatedItem = {
        "particular": formFields.modalParticular,
        "qty": formFields.modalQuantity,
        "vat_class": formFields.modalVatclass,
        "price": formFields.modalPrice.replace('₱', '').replace(/,/g, ''),
        "total": (formFields.modalQuantity *  formFields.modalPrice.replace('₱', '').replace(/,/g, '')),
        "type_purchase":formFields.modalTypePurchase
      };

    
      updateListItemSpecificItem(ModalItemIndex, updatedItem);

      // console.log(ModalItemIndex);
  
     
      setOpen(false);

     

      // console.log(listItem)
      
    };

    const updateListItemSpecificItem = (index, updatedItem) => {
      setListItem(prevList => {
        const newList = prevList.map((item, i) => {
          if (i === index) {
            return updatedItem; // Replace the item at the specified index
          }
          return item; // Keep other items unchanged
        });
        return newList;
      });
    };
    const UpdateItem = (index,particular,qty,price,total,vtclassifcation,typepurchase) => {

      // console.log(vtclassifcation);

        setModalItemIndex(index);

        setSpecificItem('modalParticular',particular)
        setSpecificItem('modalQuantity',qty)
        setSpecificItem('modalPrice',price)

        setSpecificItem('modalVatclass',vtclassifcation)
        setSpecificItem('modalTypePurchase',typepurchase)
        
        setOpen(true);
        
      };



    const defaultFormFields = {
      selectype: '',
      date_encoded:'',
      seller:'',
      particular:'',
      tin1: '',
      tin2: '',
      classification: '',
      vat_sales:'',
      vat_gov:'',
      vat_amount:'',
      vat_zero_rated:'',
      vat_exempt:'',
      TxttotalAmountDue:'',
      imageData:null,
      TxtDate:'',
      TxtOrNum:'',
      TxtPrice:'',
      TxtParticular:'',
      TxtQuantity:'',
      TXTVTclassification:'VT',
      total_amount_due:'',
      modalParticular:'',
      modalQuantity:'',
      modalPrice:'',
      modalVatclass:'',
      supplimental_fileupload:'',
      address:'',
      TypePurchase:'GOODS',
      TotalInputTax:'',
      modalTypePurchase:'',
      TotalAmountItem:'',
      VatPurchase:'',
      NonVatPurchase:''
    };
    const [formFields,setFormFields ] = useState(defaultFormFields);
    const { supplimental_fileupload,selectype,seller,tin1,tin2,classification,vat_sales,vat_amount,vat_zero_rated,vat_exempt,TxtDate,TxtOrNum,TxtPrice,TxtParticular,TxtQuantity,TXTVTclassification,total_amount_due,modalParticular,modalQuantity,modalPrice,modalVatclass,address,TypePurchase,TotalInputTax,modalTypePurchase,TotalAmountItem,vat_gov,VatPurchase,NonVatPurchase} = formFields;

    const [isCompletesubmit, setCompletesubmit] = useState(false);


    const backLoadPendingList = async () => {
      setshowSummaryCreate(false);   
      setshowListPending(true);
    };
    
    

    const handleSubmit = async (event) => {
      event.preventDefault();
  
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
      })
      

      swalWithBootstrapButtons.fire({
        title: 'Are you sure?',
        text: "Do you want to save",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Save',
        cancelButtonText: 'Cancel',
        reverseButtons: true
      }).then(async (result) => {
        if (result.isConfirmed) {


     
          setCompletesubmit(true);
          const postData = {
            "expense_id":expense_id,
            "seller": seller,
            "ornum": TxtOrNum,
            "tin": tin1+''+tin2,
            "address":address,
            "amount": TotalAmount.toString().replace(/,/g, ''),
            "classification": classification,
            "user_id": user_id,
            "company_id":company_id,
            "type":selectype,
            "attachment":base64String,
            "vat_sales":parseFloat(vat_sales.replace('₱', '').replace(/,/g, '')),
            "vat_gov":parseFloat(vat_gov.replace('₱', '').replace(/,/g, '')),
            "vat_amount":parseFloat(vat_amount.replace('₱', '').replace(/,/g, '')),
            "vat_zero_rated":parseFloat(vat_zero_rated.replace('₱', '').replace(/,/g, '')),
            "vat_exempt":parseFloat(vat_exempt.replace('₱', '').replace(/,/g, '')),
            "total_amount_due":parseFloat(total_amount_due.replace('₱', '').replace(/,/g, '')),
            "expense_item":  listItem,
            "suplemental_item":supplemental_item,
            "input_tax": parseFloat(TotalInputTax.replace('₱', '').replace(/,/g, '')),
            "non_vat_purchase":parseFloat(NonVatPurchase.replace('₱', '').replace(/,/g, '')),
            "vat_purchase":parseFloat(VatPurchase.replace('₱', '').replace(/,/g, '')),
          };


          // console.log(postData);
          // return;

          setCompletesubmit(true);
          const requestOptions = {
            method: 'POST',
            headers: 
            { 'Content-Type': 'application/json',
            'Accept': 'application/json', 
             'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
            body: JSON.stringify(postData)
          };
  
          await fetch(process.env.REACT_APP_API_URL+'api/expense_update', requestOptions)
          .then( async response => 
            {
                console.log(response);
              // Check the status code
              if (response.status === 422) {
                // API call succeeded
  
                const data = await response.json();
                // console.log(data);  
                setCompletesubmit(false);
                
              
              }else{
                const data = await response.json();
                console.log(data.message);  
                setCompletesubmit(false);
                

                Swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  title: 'Update entry successfully',
                  showConfirmButton: false,
                  timer: 1500
              })  

              backLoadPendingList();
                
              }
            }
            
          )
          .then(data => {
            // Handle the response
            console.log(data);
          })
          .catch(error => {
            // Handle any errors
            console.error(error);
          });
  
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
  
        }
      })
     
    }; 
    
    const [TotalAmount, setTotalAmount] = useState(0);
 

    const handleBlur = () => {
      if (formFields.quantity !== '' || formFields.price !== '') {
        // Convert to string first to safely use .replace, in case it's not a string
        const quantityStr = String(formFields.quantity);
        const priceStr = String(formFields.price);
    
        // Ensure values are numeric and not empty, default to 0 if not a number
        const quantity = Number(quantityStr) || 0;
        const price = Number(priceStr.replace(/,/g, '')) || 0; // Now safe to use replace
        const totalPrice = quantity * price;
    
        setTotalAmount(totalPrice);
      }
    };
    
    const handleChange = (event) => {
      const { name, value } = event.target;


      if(name==='seller'){
        SearchSupplier();

        const selectedValue = event.target.value.toUpperCase();
        const selectedItem = suggestions.find(item => item.description.toUpperCase() === selectedValue);
        if (selectedItem) {
            const selectedId = selectedItem.id;
            // Now you have the selected ID
            console.log("Selected ID:", selectedId);
            formFields.tin1=selectedItem.tin;
            formFields.tin2=selectedItem.branch;
            formFields.address=selectedItem.address;
            // You can use this selectedId however you need
        }else{
            formFields.tin1='';
            formFields.tin2='';
            formFields.address='';
        }
      }
      if (name === 'fileupload'  && value === null) {
        return;
      }
     
      const formattedValue =
      name === 'vat_sales' ? formatAmount(value) :
      name === 'vat_gov' ? formatAmount(value) :
      name === 'vat_amount' ? formatAmount(value) :
      name === 'vat_zero_rated' ? formatAmount(value) :
      name === 'vat_exempt' ? formatAmount(value) :
      name === 'price' ? formatAmount(value):
      name === 'tin1' ? formatTin(value) :value;
      
      
     

      setFormFields({ ...formFields, [name]: formattedValue });
    };
    const formatTin = (value) => {
      // Remove existing dashes and non-digit characters
      const sanitizedValue = value.replace(/[^0-9]/g, '');
    
      // Add a dash every 4 characters and 7 characters
      const formattedValue = sanitizedValue.replace(/(\d{3})(\d{3})?(\d{0,3})?/, (_, p1, p2, p3) => {
        let result = p1;
        if (p2) result += `-${p2}`;
        if (p3) result += `-${p3}`;
        return result;
      });
    
      return formattedValue;
    };

    const formatAmount = (value) => {
      const cleanedValue = value.replace(/,/g, ''); // Remove commas from the current value
    
      // Check if the cleaned value is a valid number
      if (isNaN(cleanedValue)) {
        // Handle the case when the value is not a valid number (NaN)
        // For example, you can return an empty string or a default value
        return '';
      }
    
      const formattedValue = new Intl.NumberFormat('en-US').format(cleanedValue);
      return formattedValue;
    };

 


    const [base64String, setBase64String] = useState('');


    const handleFileChange = async (event) => {
      const file = event.target.files[0];
     
     
      if (file) {

       
        if (file.type.startsWith('image/') || /\.(jpg|jpeg|png|gif)$/i.test(file.name)) {
          setConvertDone(true);
          try {
            const options = {
              maxSizeMB: 1, // Set your desired maximum file size
              useWebWorker: true,
            };
    
            const compressedFile = await imageCompression(file, options);
    
            // Now you can use the compressedFile as needed
            setSelectedFile(compressedFile);
            setConvertDone(false);
    
            const reader = new FileReader();
            reader.onloadend = () => {
              setBase64String(reader.result);
            };
    
            reader.readAsDataURL(compressedFile);
          } catch (error) {
          



            setConvertDone(false);
          }
        }else{
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Invalid not image',
            
            })
        
            return;
        }
      }
  
      // You can perform additional actions with the selected file if needed.
    };




    const [selectedFileSupplemental, setSelectedFileSupplemental] = useState(null);

   // const [base64StringSupplemental, setBase64StringSupplemental] = useState('');

    // const removeItem = (indexToRemove) => {
    //   const updatedImgSupplemental = imgSupplemental.filter((img, index) => index !== indexToRemove);
    //   setImgSupplemental(updatedImgSupplemental);

    //   console.log(imgSupplemental);
    // };

    const [imgSupplemental, setImgSupplemental] = useState([]);


  
    const [supplemental_item, setSupplemental_item] = useState([]);



    const SupplementalhandleFileChange = async (e) => {
      const files = e.target.files;
  

      setConvertDoneSupplemental(true);
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
  
        if (file.type.startsWith('image/') && /\.(jpg|jpeg|png)$/i.test(file.name)) {
          try {
            const options = {
              maxSizeMB: 1, // Set your desired maximum file size
              useWebWorker: true,
            };
  
            const compressedFile = await imageCompression(file, options);
  
            const reader = new FileReader();
            reader.onload = (event) => {
              const imageUrl = event.target.result; // Data URL of the image
              const newImage = {
                imageUrl,
                name: `Image ${supplemental_item.length + i + 1}`, // Generate a unique name for the new image
              };
  
              // Update state with the new image
              setSupplemental_item(prevItems => [...prevItems, newImage]);
            };
  
            reader.readAsDataURL(compressedFile);
          } catch (error) {
            console.error('Compression failed:', error);
          }
        } else {
          // Handle case where file is not an image
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Invalid file format. Only images (jpg, jpeg, png) are allowed.',
          });
        }
      }

      setConvertDoneSupplemental(false);
    };


    const [listItem, setListItem] = useState([]);

    const removeItem = (indexToRemove) => {
      console.log(indexToRemove);
      const updatedImgSupplemental = supplemental_item.filter((img, index) => index !== indexToRemove);
      setSupplemental_item(updatedImgSupplemental);

      console.log(supplemental_item);
    };
  



    const updateListItem = (newList) => {
      setListItem(newList);

    };
    const [suggestions, setSuggestions] = useState([]);

    const SearchSupplier = async () => {



      const postData = {
        "searchdata":seller,
        "company_id": company_id,
    
      };
     
      
      
      const requestOptions = {
        method: 'POST',
        headers: 
        { 'Content-Type': 'application/json',
        'Accept': 'application/json', 
         'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
        body: JSON.stringify(postData)
      };
  
   await fetch(process.env.REACT_APP_API_URL+'api/setting/supplier/search_list', requestOptions)
      .then( async response => 
        {
           console.log(response);
          // Check the status code
          if (response.status === 422) {

             
          }else{
            const data = await response.json();
      

            setSuggestions(data.data)
            
          }
        }
        
      )
      .then(data => {
        // Handle the response
     
    
      //   setLoad(true);
      })
      .catch(error => {
        // Handle any errors
     
      });
      // setLoad(true);
  } 
    
    const deleteItem = (indexToDelete) => {
      console.log(indexToDelete);
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
      })
      

      swalWithBootstrapButtons.fire({
        title: 'Are you sure?',
        text: "Do you want to delete",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        reverseButtons: true
      }).then(async (result) => {
        if (result.isConfirmed) {


          setListItem(currentItems => currentItems.filter((_, index) => index !== indexToDelete));


          
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
  
        }
      })

      
    };


 
    const AddTable =  () => {
        
      addNewItem();
     
      formFields.TxtPrice='';
      formFields.TxtParticular='';
      formFields.TxtQuantity='';
  }
  const addNewItem = () => {


    if(TxtParticular=='' || TxtQuantity=='' || TxtPrice.replace(/,/g, '')<0){
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Please complete the datas',
          
          })

          return;
      }
    const newItem = { 
    "particular": TxtParticular ,
    "qty": TxtQuantity ,
    "vat_class":TXTVTclassification,
     "price": TxtPrice.replace(/,/g, ''),
     "total":(TxtPrice.replace(/,/g, '') * TxtQuantity),
     "type_purchase":(TXTVTclassification =='VT')?TypePurchase: ''
   }; // Example new item
   setListItem(currentItems => [...currentItems, newItem]);

    
  
  };

  const UpdateTaxes =  () => {


    // console.log(listItem);

    // console.log('texxtt');
    const options = { style: 'currency', currency: 'PHP' };




    setSpecificItem('vat_sales', (getSum("VT")/1.12).toLocaleString('en-PH', options));

    setSpecificItem('vat_exempt', (getSum("VT-EX")).toLocaleString('en-PH', options));

    setSpecificItem('vat_zero_rated', (getSum("VT-Z")).toLocaleString('en-PH', options));

    setSpecificItem('vat_amount', (getSum("VT")*0.12).toLocaleString('en-PH', options));


    setSpecificItem('vat_gov', (getSum("VT-GOV")/1.12).toLocaleString('en-PH', options));

    setSpecificItem('total_amount_due', ((getSum("VT-GOV")/1.12)+(getSum("VT")/1.12)+getSum("VT-EX")+getSum("VT-Z")+getSum("VT")*0.12).toLocaleString('en-PH', options));
    
    
    setSpecificItem('TotalInputTax', ((getSum("VT")/1.12)*0.12).toLocaleString('en-PH', options));

    setSpecificItem('TotalAmountItem', (getTotalSum()).toLocaleString('en-PH', options));


    setSpecificItem('VatPurchase', ((getSum("VT")/1.12)).toLocaleString('en-PH', options));

    setSpecificItem('NonVatPurchase', (getSum("NVT")).toLocaleString('en-PH', options));
 
       
  }



  useEffect(() => {
    UpdateTaxes();

    // console.log(listItem);

  }, [listItem]); // Dependency array includes listItems, so this runs every time listItems changes


  const setSpecificItem = (itemName, itemValue) => {
    setFormFields(prevState => ({
        ...prevState,
        [itemName]: itemValue
    }));
};

  const getSum = (classification) => {

    // console.log(listItem);

    return listItem
        .filter(item => item.vat_class === classification) // Filter items with the specified classification
        .reduce((acc, item) => acc + parseFloat(item.total), 0); // Sum the "total" values
  };  
  const getTotalSum = () => {
    return listItem
        .reduce((acc, item) => acc + parseFloat(item.total), 0); // Sum the "total" values
  };


    const GotoCreateSummary = (type,seller,tin,classification,base64Image,id,user_id,vat_sales,vat_amount,vat_zero_rated,vat_exempt,total_amount_due,expense_item,ornum,date_trans,supplemental_item,address) => {
   
      console.log(address);
     updateListItem(expense_item);
     
      setshowSummaryCreate(true);
      setshowListPending(false);

      //loadimages
      Load_Image(id);

   
      setFormFields({
        ...defaultFormFields,
        seller: seller,
        // date_encoded:datetrans,
        selectype:type,
        tin1: tin.substring(0, 11),
        tin2: tin.slice(-3),
        classification:classification,
        TxttotalAmountDue:total_amount_due,
        TxtDate:date_trans,
        TxtOrNum: ornum,
        address:address
      });
      SetExpense_id(id);
      setBase64String(base64Image);

   
    }

    const Load_Image = async (expense_id) => {



      const postData = {
        "expense_id": expense_id,
    
      };
     
      
      
      const requestOptions = {
        method: 'POST',
        headers: 
        { 'Content-Type': 'application/json',
        'Accept': 'application/json', 
         'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
        body: JSON.stringify(postData)
      };
  
   await fetch(process.env.REACT_APP_API_URL+'api/image/load_image', requestOptions)
      .then( async response => 
        {
           console.log(response);
          // Check the status code
          if (response.status === 422) {

             
          }else{
            const data = await response.json();

            console.log(data);
            setSpecificItem('imageData', data.image);
            setSupplemental_item(data.supplemental);
            setBase64String(data.image);
            
          }
        }
        
      )
      .then(data => {
        // Handle the response
     
    
      //   setLoad(true);
      })
      .catch(error => {
        // Handle any errors
     
      });
      // setLoad(true);
  } 
  
    return(
    <>
      <div>
        <TransitionComponent sidebarOpen={sidebarOpen} Fragment={Fragment} setSidebarOpen={setSidebarOpen}/>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col ">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-indigo-600 px-6 pb-4 ">
            <div className="flex h-16 shrink-0 items-center   mt-10">
              <img
                className="h-20 w-auto mx-auto"
                src={process.env.PUBLIC_URL + '/logoIcon.png'} 
                alt="Your Company"
              />
            </div>
            <SidebarNavigation  activepage={'Expense Transactions'}  subactivepage={'List'}/>
          </div>
        </div>

        <div className="lg:pl-72">
         <HeaderComponent setSidebarOpen={setSidebarOpen} Fragment={Fragment}/>

          <main className="py-2 ">
            <div className="px-4 sm:px-6 lg:px-8">
              <div>
                <div className="space-y-10 divide-y divide-gray-900/10">
                
                   
                
                    {showSummaryCreate? 
                    <ReceiptEncodingUpdate convertDone={convertDone} handleChange={handleChange} backLoadPendingList={backLoadPendingList} formFields={formFields} handleSubmit={handleSubmit} isCompletesubmit={isCompletesubmit} selectedFile={selectedFile} handleFileChange={handleFileChange}
                   handleBlur={handleBlur} classification={classification} listItem={listItem} deleteItem={deleteItem} AddTable={AddTable} UpdateItem={UpdateItem}  
                   TransactionType={TransactionType} supplemental_item={supplemental_item} removeItem={removeItem} SupplementalhandleFileChange={SupplementalhandleFileChange} supplimental_fileupload={supplimental_fileupload} convertDoneSupplemental={convertDoneSupplemental} TotalInputTax={TotalInputTax}
                     address={address} TotalAmountItem={TotalAmountItem} VatPurchase={VatPurchase} CheckTax_Withhold={CheckTax_Withhold} NonVatPurchase={NonVatPurchase}
                      />
                    : <ReceiptEncodingList  GotoCreateSummary={GotoCreateSummary} />}
                </div>
             </div>
            </div>
          </main>
        </div>
      </div>


      <Transition.Root show={open} as={Fragment}>
                    <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                        <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:p-6">
                               <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                <button
                                    type="button"
                                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    onClick={() => setOpen(false)}
                                >
                                    <span className="sr-only">Close</span>
                                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                                </div>
                                <div>
                                    <div className="mt-3 text-center sm:mt-5">
                                         <h1 class="text-base font-semibold leading-6 text-gray-900 text-left " >Update Item</h1>
                                        <div className="mt-2">
                                      

                                        <div className="grid grid-cols-1">
                                          
                                            <div  className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2 ">
                                                <div className="px-4 py-6 sm:p-8">
                                                    <div className="grid grid-cols-1 ">
                                                
                                                        <div className="sm:col-span-2">
                                                            <label htmlFor="date_encoded" className="block text-sm font-medium leading-6 text-gray-900 text-left">
                                                             
                                                              Particular / Item Description
                                                            </label>
                                                            <div className="mt-2">
                                                                <input
                                                                type="text"
                                                                name="modalParticular"
                                                                id="modalParticular"
                                                                autoComplete="modalParticular"
                                                                value={modalParticular}
                                                                required
                                                                onChange={handleChange}
                                                            
                                                               
                                                            
                                                                className=" p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                />
                                                            </div>
                                                        </div>

                                                
                                                        <div className="sm:col-span-2">
                                                            <label htmlFor="date_encoded" className="block text-sm font-medium leading-6 text-gray-900 text-left">
                                                              Quantity
                                                            </label>
                                                            <div className="mt-2">
                                                                <input
                                                                type="text"
                                                                name="modalQuantity"
                                                                id="modalQuantity"
                                                                autoComplete="modalQuantity"
                                                                value={modalQuantity}
                                                                required
                                                                onChange={handleChange}
                                                            
                                                               
                                                            
                                                                className="uppercase p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                />
                                                            </div>
                                                        </div>


                                                             
                                                        <div className="sm:col-span-2">
                                                            <label htmlFor="date_encoded" className="block text-sm font-medium leading-6 text-gray-900 text-left">
                                                              Price
                                                            </label>
                                                            <div className="mt-2">
                                                                <input
                                                                type="text"
                                                                name="modalPrice"
                                                                id="modalPrice"
                                                                autoComplete="modalPrice"
                                                                value={modalPrice.toLocaleString()}
                                                                required
                                                                onChange={handleChange}
                                                            
                                                               
                                                            
                                                                className="uppercase p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                />
                                                            </div>
                                                        </div>


                                                        {(is_vat==1)?
                                                        <div className="sm:col-span-2">
                                                            <label htmlFor="date_encoded" className="block text-sm font-medium leading-6 text-gray-900 text-left">
                                                              Vatable
                                                            </label>
                                                    

                                                            <div className="mt-2">
                                                            <select  
                                                              className="uppercase p-2.5 block w-full
                                                                rounded-md border-0 py-1.5 text-gray-900 
                                                                shadow-sm ring-1 ring-inset ring-gray-300
                                                                placeholder:text-gray-400 focus:ring-2
                                                                focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                name="modalVatclass"
                                                                value={modalVatclass}
                                                                onChange={handleChange}
                                                                required>

                                                                  {(TransactionType=='sales')?
                                                                    <><option value="NVT">NVT</option><option value="VT">VT</option><option value="VT-EX">VT-EX</option><option value="VT-Z">VT-Z</option></>
                                                                  :
                                                                  <><option value="VT">VT</option><option value="NVT">NVT</option></>
                                                                  }
                                                                  
                                                                  
                                                              </select>
                                                    

                                                        </div>

                                                        </div>:''}


                                                        { (modalVatclass=='VT' &&  CheckTax_Withhold())?
                                                        <div className="sm:col-span-2">
                                                            <label htmlFor="date_encoded" className="block text-sm font-medium leading-6 text-gray-900 text-left">
                                                              Type of Purchase
                                                            </label>
                                                    

                                                            <div className="mt-2">
                                                            <select  
                                                              className=" p-2.5 block w-full
                                                                rounded-md border-0 py-1.5 text-gray-900 
                                                                shadow-sm ring-1 ring-inset ring-gray-300
                                                                placeholder:text-gray-400 focus:ring-2
                                                                focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                name="modalTypePurchase"
                                                                value={modalTypePurchase}
                                                                onChange={handleChange}
                                                                required>
                                                              <option value="GOODS">Goods</option>
                                                              <option value="SERVICE">Service</option>
                                                              <option value="CAPITAL GOODS">Capital Goods</option>
                                                              <option value="GOODS OTHER THAN">Goods Other Than</option>     
                                                              </select>
                                                    

                                                        </div>
                                                        
                                                        </div>: ''}

                                                        </div>
                                                    </div>
                                            
                                                <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8 uppercase">
                                                    <button
                                                    type="submit"
                                                    onClick={() => SaveItemModal()}
                                                    className="uppercase rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                    >
                                                    Save
                                                    </button>
                                                </div>

                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
            
                            </Dialog.Panel>
                            </Transition.Child>
                        </div>
                        </div>
                    </Dialog>
         </Transition.Root>
  </>);

}
export default ListEncoding;