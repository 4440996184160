import { UserContext } from '../../contexts/user.context'

import { Fragment, useRef, useState,useEffect,useContext } from 'react'

import { formatAmount,formatTin,Address,SentenceCaseText,formatAmountDisplay } from '../../utils/ValidationField';
import { useNavigate } from 'react-router-dom'
import imageCompression from 'browser-image-compression';
import './sweetbutton.css'; //
import Swal from 'sweetalert2';
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon  } from '@heroicons/react/24/outline'
const ReceiptEncodingCreate =() =>{

    const navigate = useNavigate();
    const { company_id } = useContext(UserContext);
    const { user_id } = useContext(UserContext);

    const { is_vat } = useContext(UserContext);


    const { Tax_types } = useContext(UserContext);



    

    const [TransactionType, setTransactionType] = useState('expense');


    const [allClass, setClass] = useState([]);
    //const [isLoad, setLoad] = useState(false);
    const [open, setOpen] = useState(false)
    const cancelButtonRef = useRef(null)
    // const [Searchdata, setInputValue] = useState('');

    const [convertDone, setConvertDone] = useState(false);

    const [convertDoneSupplemental, setConvertDoneSupplemental] = useState(false);

    const [withholdTaxType, setwithholdTaxType] = useState(false);
    const [suggestions, setSuggestions] = useState([]);



    const [imageData, setImageData] = useState(null);
 
    const [openImage, setOpenImage] = useState(false)


    const OpenImageSupplemental=(image) => {

      setOpenImage(true);
  
        setImageData(image);
      }

   

 
    const CheckTax_Withhold = ()=>{
      const item =JSON.parse(Tax_types).find(item => item.id === 4);
      return item.checked;
     
    }
    


    const defaultFormFields = {
        // selectype: '',
        seller:'',
        particular:'',
        tin1: '',
        tin2: '',
        item_description:'',
        price:'',
        classification: '',
        VTclassification: 'VT',
        vat_sales:'',
        vat_amount:'',
        vat_zero_rated:'',
        vat_exempt:'',
        TxtDate: new Date().toISOString().split('T')[0],
        TxtOrNum:'',
        TxtParticular:'',
        TxtQuantity:'',
        TxtPrice:'',
        fileupload:'',
        supplimental_fileupload:'',
        total_amount_due:'',
        modalParticular:'',
        modalQuantity:'',
        modalPrice:'',
        modalVatclass:'',
        modalTypePurchase:'',
        address:'',
        TypePurchase:'GOODS',
        TotalInputTax:'',
        VatPurchase:'',
        NonVatPurchase:'',
        TotalAmount:''
      };
      const [formFields,setFormFields ] = useState(defaultFormFields);
      const { fileupload,supplimental_fileupload,seller,particular, tin1,tin2,classification,VTclassification,vat_sales,vat_amount,vat_zero_rated,vat_exempt,item_description,TxtDate,TxtOrNum,TxtParticular,TxtQuantity,TxtPrice,total_amount_due,modalParticular,modalQuantity,modalPrice,modalVatclass,address,TypePurchase,modalTypePurchase,TotalInputTax,TotalAmount,VatPurchase,NonVatPurchase} = formFields;
    
      const [isCompletesubmit, setCompletesubmit] = useState(false);


      const setSpecificItem = (itemName, itemValue) => {
        setFormFields(prevState => ({
            ...prevState,
            [itemName]: itemValue
        }));
    };

    

    



      const [selectedFile, setSelectedFile] = useState(null);

      const [base64String, setBase64String] = useState('');


      const handleFileChange = async (e) => {
        const file = e.target.files[0];
  
        if (file) {
  
         
          if (file.type.startsWith('image/') || /\.(jpg|jpeg|png|gif)$/i.test(file.name)) {
           
            
            setConvertDone(true);
            try {
              const options = {
                maxSizeMB: 1, // Set your desired maximum file size
                useWebWorker: true,
              };
      
              const compressedFile = await imageCompression(file, options);
      
              // Now you can use the compressedFile as needed
              setSelectedFile(compressedFile);
              setConvertDone(false);
      
              const reader = new FileReader();
              reader.onloadend = () => {
                setBase64String(reader.result);
              };

              console.log('success');
      
              reader.readAsDataURL(compressedFile);
            } catch (error) {
             
              setConvertDone(false);
            
              console.log('failed');
            }
          }else{
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Invalid not image',
              
              })
          
              return;
          }
        }
      };




      const removeItem = (indexToRemove) => {
        const updatedImgSupplemental = imgSupplemental.filter((img, index) => index !== indexToRemove);
        setImgSupplemental(updatedImgSupplemental);

        console.log(imgSupplemental);
      };
    
   
     // const [supplemental_item, setSupplemental_item] = useState([]);


      const [imgSupplemental, setImgSupplemental] = useState([]);



      const SupplementalhandleFileChange = async (e) => {
     

        setConvertDoneSupplemental(true);

  
        const files = e.target.files;
        console.log(files.length);
        for (let i = 0; i < files.length; i++) {

          
          const file = files[i];
    
          if (file.type.startsWith('image/') && /\.(jpg|jpeg|png)$/i.test(file.name)) {
            try {
              const options = {
                maxSizeMB: 1, // Set your desired maximum file size
                useWebWorker: true,
              };
    
              const compressedFile = await imageCompression(file, options);
    
              const reader = new FileReader();
              reader.onload = (event) => {
                const imageUrl = event.target.result; // Data URL of the image
                const newImage = {
                  imageUrl,
                  name: `Image ${imgSupplemental.length + i + 1}`, // Generate a unique name for the new image
                };
    
                // Update state with the new image
                setImgSupplemental(prevItems => [...prevItems, newImage]);
              };
    
              reader.readAsDataURL(compressedFile);
            } catch (error) {
              console.error('Compression failed:', error);
            }
          } else {
            // Handle case where file is not an image
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Invalid file format. Only images (jpg, jpeg, png) are allowed.',
            });
          }
        }
        setConvertDoneSupplemental(false);
      };




      const handleChange = (event) => {
        const { name, value } = event.target;

        if(name==='seller'){
          SearchSupplier();

          const selectedValue = event.target.value;
          const selectedItem = suggestions.find(item => item.description === selectedValue);
          if (selectedItem) {
              const selectedId = selectedItem.id;
              // Now you have the selected ID
              console.log("Selected ID:", selectedId);
              formFields.tin1=selectedItem.tin;
              formFields.tin2=selectedItem.branch;
              formFields.address=selectedItem.address;
              // You can use this selectedId however you need
          }else{
              formFields.tin1='';
              formFields.tin2='';
              formFields.address='';
          }

        //   console.log(suggestions);
        //   if (suggestions && suggestions.length == 1 && value.length>2) {
            
         
        //   }else{
        //     formFields.tin1='';
        //     formFields.tin2='';
        //     formFields.address='';
        //   }
        //   if(value===''){
        //     formFields.tin1='';
        //     formFields.tin2='';
        //     formFields.address='';
        //   }
        }
      
      const formattedValue =
      name === 'amount' ? formatAmount(value) :
      name === 'vat_sales' ? formatAmount(value) :
      name === 'vat_amount' ? formatAmount(value) :
      name === 'vat_zero_rated' ? formatAmount(value) :
      name === 'vat_exempt' ? formatAmount(value) :
      name === 'price' ? formatAmount(value):
      name === 'total_amount_due' ? formatAmount(value):
      name === 'tin1' ? formatTin(value) :
      name === 'modalPrice' ? formatAmount(value) :
      name === 'TxtPrice' ? formatAmount(value) :value;
     
     
    
      
        setFormFields({ ...formFields, [name]: formattedValue });

      };

    //   const resetFormFields = () => {
    //     setFormFields(defaultFormFields);
    //   };


  
      const [listItem, setListItem] = useState([]);

      const getSum = (classification) => {
        return listItem
            .filter(item => item.vtclassifcation === classification) // Filter items with the specified classification
            .reduce((acc, item) => acc + parseFloat(item.total), 0); // Sum the "total" values
    };

    const getTotalSum = () => {
        return listItem
            .reduce((acc, item) => acc + item.total, 0); // Sum the "total" values
    };

   
      const addNewItem = () => {


        if(TxtParticular=='' || TxtQuantity=='' || TxtPrice.replace(/,/g, '')<0){
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Please complete the data',
              
              })

              return;
          }
        const newItem = { 
        "particular": TxtParticular ,
        "qty": TxtQuantity ,
        "vtclassifcation":VTclassification,
         "price": TxtPrice.replace(/,/g, ''),
         "total":(TxtPrice.replace(/,/g, '') * TxtQuantity),
         "type_purchase":(VTclassification =='VT')?TypePurchase: ''
       }; // Example new item
       setListItem(currentItems => [...currentItems, newItem]);

        
       console.log(listItem);
      };

   
      
      useEffect(() => {
        UpdateTaxes();
      
     

      }, [listItem]);
      
      // Dependency array includes listItems, so this runs every time listItems changes
    useEffect( () => {

        LoadClassList();

    
    }, []); 

    const UpdateTaxes =  () => {

      const options = { style: 'currency', currency: 'PHP' };



      
      setSpecificItem('vat_sales', (getSum("VT")/1.12).toLocaleString('en-PH', options));

      setSpecificItem('vat_exempt', (getSum("VT-EX")).toLocaleString('en-PH', options));

      setSpecificItem('vat_zero_rated', (getSum("VT-Z")).toLocaleString('en-PH', options));

      setSpecificItem('vat_amount', ((getSum("VT")/1.12)*0.12).toLocaleString('en-PH', options));




      setSpecificItem('total_amount_due', ((getSum("VT")/1.12)+getSum("VT-EX")+getSum("VT-Z")+(getSum("VT")/1.12)*0.12).toLocaleString('en-PH', options));
    
      setSpecificItem('TotalInputTax', ((getSum("VT")/1.12)*0.12).toLocaleString('en-PH', options));

      setSpecificItem('TotalAmount', (getTotalSum()).toLocaleString('en-PH', options));
      

      setSpecificItem('VatPurchase', ((getSum("VT")/1.12)).toLocaleString('en-PH', options));

      setSpecificItem('NonVatPurchase', (getSum("NVT")).toLocaleString('en-PH', options));

      
      console.log(listItem);



      
      
      
    }
    const LoadClassList = async () => {



        const postData = {
          "search":'',
          "company_id": company_id,
          "type": 'expense',
      
        };
       
        
        
        const requestOptions = {
          method: 'POST',
          headers: 
          { 'Content-Type': 'application/json',
          'Accept': 'application/json', 
           'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
          body: JSON.stringify(postData)
        };
    
     await fetch(process.env.REACT_APP_API_URL+'api/setting/classification/list', requestOptions)
        .then( async response => 
          {
             console.log(response);
            // Check the status code
            if (response.status === 422) {

               
            }else{
              const data = await response.json();
              console.log(data.data);
              setClass(data.data); 

           
              
            }
          }
          
        )
        .then(data => {
          // Handle the response
       
      
        //   setLoad(true);
        })
        .catch(error => {
          // Handle any errors
       
        });
        // setLoad(true);
    } 


    const SearchSupplier = async () => {



      const postData = {
        "searchdata":seller,
        "company_id": company_id,
    
      };
     
      
      
      const requestOptions = {
        method: 'POST',
        headers: 
        { 'Content-Type': 'application/json',
        'Accept': 'application/json', 
         'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
        body: JSON.stringify(postData)
      };
  
   await fetch(process.env.REACT_APP_API_URL+'api/setting/supplier/search_list', requestOptions)
      .then( async response => 
        {
           console.log(response);
          // Check the status code
          if (response.status === 422) {

             
          }else{
            const data = await response.json();
      

            setSuggestions(data.data)
            
          }
        }
        
      )
      .then(data => {
        // Handle the response
     
    
      //   setLoad(true);
      })
      .catch(error => {
        // Handle any errors
     
      });
      // setLoad(true);
  } 


    const AddTable =  () => {
        
        addNewItem();
        // formFields.TxtDate='';
    
        formFields.TxtPrice='';
        formFields.TxtParticular='';
        formFields.TxtQuantity='';

  
    }

    const deleteItem = (indexToDelete) => {
        console.log(indexToDelete);
         setListItem(currentItems => currentItems.filter((_, index) => index !== indexToDelete));

      
      };


      const [ModalItemIndex, setModalItemIndex] = useState(0);


      const UpdateItem = (index,particular,qty,price,total,vtclassifcation,TypePurchase) => {

        setModalItemIndex(index);

        setSpecificItem('modalParticular',particular)
        setSpecificItem('modalQuantity',qty)
        setSpecificItem('modalPrice',price)

        setSpecificItem('modalVatclass',vtclassifcation)

        setSpecificItem('modalTypePurchase',TypePurchase)
        

        setOpen(true);
        
      };


      const updateListItem = (index, updatedItem) => {
        setListItem(prevList => {
          const newList = prevList.map((item, i) => {
            if (i === index) {
              return updatedItem; // Replace the item at the specified index
            }
            return item; // Keep other items unchanged
          });
          return newList;
        });
      };


      const SaveItemModal = () => {

       
        const updatedItem = {
          "particular": formFields.modalParticular,
          "qty": formFields.modalQuantity,
          "vtclassifcation": formFields.modalVatclass,
          "price": formFields.modalPrice,
          "total": (formFields.modalQuantity *  formFields.modalPrice.replace('₱', '').replace(/,/g, '')),
          "type_purchase":formFields.modalTypePurchase
        };
        updateListItem(ModalItemIndex, updatedItem);

        console.log(ModalItemIndex);
    
       
        setOpen(false);

        
        console.log(formFields.modalVatclass);

        console.log(listItem)
        
      };

      const handleSubmit = async (event) => {
        event.preventDefault();
    
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
          },
          buttonsStyling: false
        })
        
              
        if(seller ==''  || classification =='' || TxtOrNum =='' ||base64String =='' || tin1.length<11 || tin2.length<3){
            if(tin1.length<11 || tin2.length<3){
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Invalid Tin',
                
                })
            }else{
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please Complete The Data',
                
                })
            }
        
            return;
        }
        swalWithBootstrapButtons.fire({
          title: 'Are you sure?',
          text: "Do you want to save",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Save',
          cancelButtonText: 'Cancel',
          reverseButtons: true
        }).then(async (result) => {
          if (result.isConfirmed) {
    
       

            setCompletesubmit(true);
            const postData = {
              "company_id": company_id,
              "particular": particular,
              "seller": seller,
              "tin": tin1+''+tin2,
              "address":address,
              "classification": classification,
              "user_id": user_id,
              "type":'expense',
              "attachment":base64String,
              "ornum":TxtOrNum,
              "date_trans":TxtDate,
              "expense_item": listItem,
              "suplemental_item":imgSupplemental,
              "input_tax":((getSum("VT")/1.12)*0.12),
              "non_vat_purchase":((getSum("VT")/1.12)),
              "vat_purchase":(getSum("NVT")),
            };
        
            setCompletesubmit(true);
            console.log(base64String);
            const requestOptions = {
              method: 'POST',
              headers: 
              { 'Content-Type': 'application/json',
              'Accept': 'application/json', 
               'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
              body: JSON.stringify(postData)
            };
    
            await fetch(process.env.REACT_APP_API_URL+'api/expense_store', requestOptions)
            .then( async response => 
              {
                  console.log(response);
                // Check the status code
                if (response.status === 422) {
                  // API call succeeded
    
                  const data = await response.json();
              
                  setCompletesubmit(false);
    
                
                }else{
                  const data = await response.json();
                  console.log(data.message);  
                  setCompletesubmit(false);
                  
                  Swal.fire({
                      position: 'top-end',
                      icon: 'success',
                      title: 'create new entry successfully',
                      showConfirmButton: false,
                      timer: 1500
                  })  
                  navigate('/expense/list');
    
                  
                }
              }
              
            )
            .then(data => {
              // Handle the response
              console.log(data);
            })
            .catch(error => {
              // Handle any errors
              console.error(error);
            });
    
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
    
          }
        })
       
      }; 
    

    return (
    <>
    
    <div className={isCompletesubmit ? "opacity-20 grid grid-cols-1 gap-x-8 gap-y-8 pt-10" : "grid grid-cols-1 gap-x-8 gap-y-8 pt-10"}>
        <div className="px-4 sm:px-0">
            <h2 className="text-2xl font-semibold leading-7 text-gray-900">NEW ENTRY</h2>
           
        </div>
        <form onSubmit={handleSubmit} className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2 ">
            <div className="px-4 py-6 sm:p-8">


      
            <div className=" md:grid lg:grid 2xl:grid gap-x-6 gap-y-8 sm:grid-cols-6 md:grid-cols-4 lg:grid-cols-12 xl:grid-cols-12 ">
          

                <div className="sm:col-span-6 md:col-span-4 lg:col-span-4 xl:col-span-4 2xl:col-span-4
                ">
                    <label htmlFor="seller" className="block text-sm font-medium leading-6 text-gray-900">
                        Seller
                    </label>
                    <div className="mt-2 ">
                        <input
                        type="text"
                        name="seller"
                        id="seller"
                        autoComplete="seller"
                        value={seller.toLowerCase()}
                        required
                    
                        list="suggestions" 
                        onChange={handleChange}
                        className=" capitalize p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        <datalist id="suggestions">
                        {suggestions.map((item) => (
                          <option key={item.id} title={item.id} value={item.description} />
                        ))}
                      </datalist>
                    </div>
                </div>

                <div className="sm:col-span-4 md:col-span-2 lg:col-span-2 xl:col-span-2 2xl:col-span-2">
                    <label htmlFor="tin1" className="block text-sm font-medium leading-6 text-gray-900">
                        TIN
                    </label>

                    <div className="mt-2">
                    <input
                        type="text"
                        name="tin1"
                        id="tin1"
                        autoComplete="tin1"
                        value={tin1}
                        maxlength="11"
                        required
                        onChange={handleChange}
                        className="uppercase p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />

                    </div>
                </div>
                <div className="sm:col-span-2 md:col-span-2 lg:col-span-2 xl:col-span-2 2xl:col-span-2">
                    <label htmlFor="tin2" className="block text-sm font-medium leading-6 text-gray-900 ">
                        Branch
                    </label>

                    <div className="mt-2">
                    <input
                        type="text"
                        name="tin2"
                        id="tin2"
                        maxlength="3"
                        autoComplete="tin2"
                        value={tin2}
                        required
                        onChange={handleChange}
                        placeholder="001"
                        className="uppercase p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />

                    </div>
                </div>
     
        
                <div className="sm:col-span-6 md:col-span-4 lg:col-span-4 xl:col-span-4 2xl:col-span-4
                ">
                    <label htmlFor="seller" className="block text-sm font-medium leading-6 text-gray-900">
                        Address
                    </label>
                    <div className="mt-2">
                        <input
                        type="text"
                        name="address"
                        id="selleaddressr"
                        autoComplete="address"
                        value={address}
                        required
                        onChange={handleChange}
                        className=" p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>
                <div className="sm:col-span-6 md:col-span-2 lg:col-span-2 xl:col-span-3 2xl:col-span-3">
                        <label htmlFor="classification" className="block text-sm font-medium leading-6 text-gray-900">
                            Classification
                        </label>
                        <div className="mt-2">
                        
                            <select  
                            className=" p-2.5 block w-full
                            rounded-md border-0 py-1.5 text-gray-900 
                            shadow-sm ring-1 ring-inset ring-gray-300
                            placeholder:text-gray-400 focus:ring-2
                            focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 "
                            name="classification"
                            value={classification}
                            onChange={handleChange}
                            required>
                                <option></option>
                                {allClass.map((Classification) => (
                                    <option key={Classification.id} value={Classification.class}>

                                    {Classification.class}
                                    </option>
                                ))}
                            </select>
                
                        
                        </div>
                    </div>
          
            
                    <div className="sm:col-span-6 md:col-span-2 lg:col-span-3 xl:col-span-2 2xl:col-span-2
                ">
                    <label htmlFor="seller" className="block text-sm font-medium leading-6 text-gray-900">
                      Date
                    </label>
                    <div className="mt-2">
                        <input
                        type="date"
                        name="TxtDate"
                        value={TxtDate}
                        onChange={handleChange}
                        required
                        className=" p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                    
                </div>
                <div className="sm:col-span-6 md:col-span-2 lg:col-span-3 xl:col-span-2 2xl:col-span-2
                ">
                    <label htmlFor="seller" className="block text-sm font-medium leading-6 text-gray-900">
                      Invoice No
                    </label>
                    <div className="mt-2">
                        <input
                        type="text"
                        name="TxtOrNum"
                        id="TxtOrNum"
                        autoComplete="TxtOrNum"
                        value={TxtOrNum}
                        onChange={handleChange}
                        required
                        className=" p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                    
                </div>
                <div className='col-span-full '>
                <hr className="w-full h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700"/>
                    <div className="">
                        <div className="sm:flex sm:items-center ">
                            <div className="sm:flex-auto">

                             
                                <div class="md:grid lg:grid xl:grid 2xl:grid grid-cols-12 gap-4">
                   
                             
                                <div class="sm:col-span-8 md:col-span-2 lg:col-span-3 xl:col-span-4 2xl:col-span-3">
                                    <label htmlFor="tin1" className="block text-xs lg:text-xs xl:text-sm 2xl:text-sm font-medium leading-6 text-gray-900 capitalize">
                                    Particular / Description
                                        </label>

                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                name="TxtParticular"
                                                id="TxtParticular"
                                                autoComplete="TxtParticular"
                                                value={TxtParticular}
                                                
                                                onChange={handleChange}
                                                className=" p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                />

                                        </div>
                                </div>
                                <div className="sm:col-span-8 md:col-span-4 lg:col-span-2 xl:col-span-2 2xl:col-span-2 ">
                                    <label htmlFor="tin1" className="block text-xs lg:text-xs xl:text-sm 2xl:text-sm font-medium leading-6 text-gray-900">
                                        quantity
                                        </label>

                                        <div className="mt-2">
                                            <input
                                                type="number"
                                                name="TxtQuantity"
                                                id="TxtQuantity"
                                                autoComplete="TxtQuantity"
                                                value={TxtQuantity}
                                                
                                                onChange={handleChange}
                                                className=" p-2.5 text-right block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                />

                                        </div>
                                </div>
                                <div className="sm:col-span-8 md:col-span-4 lg:col-span-2 xl:col-span-2 2xl:col-span-2 ">
                                    <label htmlFor="price" className="block text-xs lg:text-xs xl:text-sm 2xl:text-sm font-medium leading-6 text-gray-900">
                                        price
                                        </label>

                                        <div className="mt-2">
                                                <input
                                                    type="text"
                                                    name="TxtPrice"
                                                    id="TxtPrice"
                                                    autoComplete="TxtPrice"
                                                    value={TxtPrice}
                                                    
                                                    onChange={handleChange}
                                                    className="text-right  p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    />

                                            </div>
                                </div>
                                {(is_vat==1)?
                                <div className="sm:col-span-8 md:col-span-4 lg:col-span-2 xl:col-span-2 2xl:col-span-1 ">
                                    <label htmlFor="price" className="block text-xs lg:text-xs xl:text-sm 2xl:text-sm font-medium leading-6 text-gray-900">
                                        Vatable
                                        </label>

                                        <div className="mt-2">
                                              <select  
                                                className=" p-2.5 block w-full
                                                  rounded-md border-0 py-1.5 text-gray-900 
                                                  shadow-sm ring-1 ring-inset ring-gray-300
                                                  placeholder:text-gray-400 focus:ring-2
                                                  focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                  name="VTclassification"
                                                  value={VTclassification}
                                                  onChange={handleChange}
                                                  required>
                                                  <option value="VT">VT</option>0
                                              
                                                  <option value="NVT">NVT</option>
                                                  
                                                    
                                                    
                                                    
                                                </select>
                                      

                                          </div>
                                </div> : ''}
                                <div className="sm:col-span-8 md:col-span-4 lg:col-span-6 xl:col-span-6 2xl:col-span-3 ">

                         
                                    <div className="flex">

                                      {(is_vat==1)?
                                        <>
                                          {(VTclassification =='VT' && CheckTax_Withhold())?

                                            <div className='w-9/12'>
                                                                                  
                                            <div >
                                            
                                                <label htmlFor="tin1" className="block text-xs lg:text-xs xl:text-sm 2xl:text-sm font-medium leading-6 text-gray-900">
                                                Type Purchase
                                                  </label>

                                                  <div className="mt-2">
                                                      <select  
                                                        className=" p-2.5 block w-full
                                                          rounded-md border-0 py-1.5 text-gray-900 
                                                          shadow-sm ring-1 ring-inset ring-gray-300
                                                          placeholder:text-gray-400 focus:ring-2
                                                          focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                          name="TypePurchase"
                                                          value={TypePurchase}
                                                          onChange={handleChange}
                                                          required>
                                                        <option value="GOODS">Goods</option>
                                                          <option value="SERVICE">Service</option>
                                                          <option value="CAPITAL GOODS">Capital Goods</option>
                                                          <option value="GOODS OTHER THAN">Goods Other Than</option>
                                                            
                                                            
                                                            
                                                        </select>
                                              

                                                  </div>
                                              </div>
                                            </div> :''

                                           }
                                   
                                        </>
                                     
                                        
                                      
                                      
                                      :''}
                              
                                        <div className='mt-6 ml-2 w-1/4'>
                                          <button
                                              type="button" onClick={AddTable}
                                              className="block rounded-md  bg-green-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 uppercase"
                                          >
                                              Add
                                          </button>
                                        </div>
                                    </div>
                              
                                </div>
                                
                            </div>
                            </div>
                   
                        </div>
                        <div className="mt-8 flow-root">
                            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                <table className="min-w-full divide-y divide-gray-300 ">
                                <thead className='mb-2 '>
                                    <tr>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                                        Particular / item description
                                    </th>
                           
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Quantity
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Price
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Total
                                    </th>

                                    
                                    {(is_vat==1)? 
                                      <>
                                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                              VATABLE
                                          </th> 

                                      {(CheckTax_Withhold())?
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Type Purchase
                                        </th> 
                                        :''}
                                      
                                      </>
                                    : ''}
                                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
                                        <span className="sr-only">Edit</span>
                                    </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white">
                                    {listItem.map((item, index) => (
                                        <tr key={index}>
                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                            <SentenceCaseText text={item.particular} />
                                         
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.qty}</td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">  ₱{formatAmountDisplay(item.price)}</td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">  ₱{formatAmountDisplay(item.total)}</td>
                                            {(is_vat==1)?  
                                           
                                          <>
                                            <td className={`whitespace-nowrap px-3 py-4 text-sm ${item.vtclassifcation === '' && 'bg-gray-500' || item.vtclassifcation === 'NVT' && 'bg-gray-500'}  border-white border w-16`}>{(item.vtclassifcation !='NVT')? item.vtclassifcation: ''}</td>
                                            {  (CheckTax_Withhold())?
                                             <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.type_purchase}</td>: ''
                                            }
                                           
                                            </>
                                              :''}
                                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                                <a onClick={() => UpdateItem(
                                                  index,
                                                  item.particular,
                                                  item.qty,
                                                  formatAmountDisplay(item.price),
                                                  formatAmountDisplay(item.total),
                                                  item.vtclassifcation,
                                                  item.type_purchase
                                                  )}>
                                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer	">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                                                  </svg>

                                                </a>
                                            

                                            </td>
                                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                                <a onClick={() => deleteItem(index)}>
                                                <svg   xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer	">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                    </svg>
                                                </a>
                                            

                                            </td>
                                        </tr>
                                        ))}
                                </tbody>
                                </table>
                            </div>
                            </div>
                        </div>
                        </div>
                        <hr className="w-full h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700"/>

                </div>
                <div className='col-span-3 '>
                   <div className='grid grid-cols-12 mb-2'>
                   {(is_vat==1 && CheckTax_Withhold())?
                   
                       <>
                        <div className='col-span-12 mb-2'>
                            <label htmlFor="vat_sales" className="block text-sm font-medium leading-6 text-gray-900">
                                Non Vat Purchase
                            </label>

                            <div className="mt-2">
                            <input
                                type="text"
                                name="NonVatPurchase"
                                id="NonVatPurchase"
                                autoComplete="NonVatPurchase"
                                value={NonVatPurchase}
                                readOnly
                                onChange={handleChange}
                                className="text-right font-bold uppercase p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />

                        
                            </div>
                          </div>
                        <div className='col-span-12 mb-2'>
                            <label htmlFor="vat_sales" className="block text-sm font-medium leading-6 text-gray-900">
                                Vat Purchase
                            </label>

                            <div className="mt-2">
                            <input
                                type="text"
                                name="VatPurchase"
                                id="VatPurchase"
                                autoComplete="VatPurchase"
                                value={VatPurchase}
                                readOnly
                                onChange={handleChange}
                                className="text-right font-bold uppercase p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />

                        
                            </div>
                          </div>
                            <div className='col-span-12 mb-2'>
                            <label htmlFor="vat_sales" className="block text-sm font-medium leading-6 text-gray-900">
                                Input Tax
                            </label>

                            <div className="mt-2">
                            <input
                                type="text"
                                name="TotalInputTax"
                                id="TotalInputTax"
                                autoComplete="TotalInputTax"
                                value={TotalInputTax}
                                readOnly
                                onChange={handleChange}
                                className="text-right font-bold uppercase p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />

                        
                            </div>
                        </div>
                       
                       </> :''}
                        <div className='col-span-12'>
                            <label htmlFor="vat_sales" className="block text-sm font-medium leading-6 text-gray-900">
                                    Total 
                                </label>

                                <div className="mt-2">
                                <input
                                    type="text"
                                    name="TotalAmount"
                                    id="TotalAmount"
                                    autoComplete="TotalAmount"
                                    value={TotalAmount}
                                    readOnly
                                    onChange={handleChange}
                                    className="text-right font-bold uppercase p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />

                            
                                </div>
                        </div>
                    </div>                       
                </div>
        
                  
           
        
              
                
             {(TransactionType=='sales')?
    
            <div className='col-span-4'>
             <div className="col-span-4">
                 <label htmlFor="vat_sales" className="block text-sm font-medium leading-6 text-gray-900">
                     Vatable Sales
                 </label>

                 <div className="mt-2">
                 <input
                     type="text"
                     name="vat_sales"
                     id="vat_sales"
                     autoComplete="vat_sales"
                     value={vat_sales}
                     readOnly
                     onChange={handleChange}
                     className="text-right uppercase p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                     />

             
                 </div>
         

             </div>

             <div className="col-span-4">
                 <label htmlFor="vat_exempt" className="block text-sm font-medium leading-6 text-gray-900">
                 VAT Exempt
                 </label>

                 <div className="mt-2">
                 <input
                     type="text"
                     name="vat_exempt"
                     id="vat_exempt"
                     autoComplete="vat_exempt"
                     value={vat_exempt}
                     readOnly
                     onChange={handleChange}
                     className="text-right uppercase p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                     />

             
                 </div>
         

             </div>
             <div className="col-span-4">
                 <label htmlFor="vat_zero_rated" className="block text-sm font-medium leading-6 text-gray-900">
                 VAT Zero Rated
                 </label>

                 <div className="mt-2">
                 <input
                     type="text"
                     name="vat_zero_rated"
                     id="vat_zero_rated"
                     autoComplete="vat_zero_rated"
                     value={vat_zero_rated}
                     readOnly
                     onChange={handleChange}
                     className="text-right uppercase p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                     />

             
                 </div>
         

             </div>
             <div className="col-span-4">
                 <label htmlFor="vat_amount" className="block text-sm font-medium leading-6 text-gray-900">
                     VAT Amount
                 </label>

                 <div className="mt-2">
                 <input
                     type="text"
                     name="vat_amount"
                     id="vat_amount"
                     autoComplete="vat_amount"
                     value={vat_amount}
                     readOnly
                     onChange={handleChange}
                     className="text-right uppercase p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                     />

             
                 </div>
         

             </div>


             <div className="col-span-4">
                 <label htmlFor="total_amount_due" className="block text-sm font-medium leading-6 text-gray-900">
                     Total Amount Due
                 </label>

                 <div className="mt-2">
                 <input
                     type="text"
                     name="total_amount_due"
                     id="total_amount_due"
                     autoComplete="total_amount_due"
                     value={total_amount_due}
                     readOnly
                     onChange={handleChange}
                     className="text-right  p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                     />

             
                 </div>
         

             </div>
 
         </div>: ''
         }
  
                           


            

       
            </div>
           <div className='grid grid-cols-12 gap-2'>


              <div className='col-span-6 border border-gray-300'>

              <h2 className="text-base font-semibold leading-7 text-gray-900 text-center">Invoice  Receipt</h2>
  
                      <label for="cover-photo" class="block text-sm font-medium leading-6 text-white">Cover photo</label>
                      <div class="mt-2 flex justify-center rounded-lg border border-dashed border-white/25 px-6 py-10">
                          <div class="text-center">
                          {selectedFile && (
                              <div className="mt-4">
                              <img  onClick={() => OpenImageSupplemental(URL.createObjectURL(selectedFile))}
                                  src={URL.createObjectURL(selectedFile)}
                                  alt="Uploaded preview"
                                  className="cursor-pointer h-40 duration-300 transform hover:scale-125"
                              />
                              </div>
                          )}
                            {!selectedFile && (
                              <div className="mt-4">
                            <svg class="mx-auto h-12 w-12 text-gray-500" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
                                  <path fill-rule="evenodd" d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z" clip-rule="evenodd" />
                              </svg>
                              </div>
                          )}
                        
                          <div className="mt-4">
                          {convertDone?  <><svg aria-hidden="true" class="w-8 h-8 mx-auto text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" /><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" /></svg><span class="sr-only">Loading...</span></> :''}    
                              <label htmlFor="fileupload" className="cursor-pointer relative rounded-md bg-indigo-500 font-semibold text-white focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 focus-within:ring-offset-gray-900">
                                  <span>Upload a file</span>
                                  <input
                                  id="fileupload"
                                  name="fileupload"
                                  type="file"
                                  className="sr-only"
                                  value={fileupload}
                                  onChange={handleFileChange}
                                  />
                              </label>
                            
                
                              <p className="text-xs leading-5 text-gray-400">PNG, JPG, GIF up to 10MB</p>
                              </div>
                          </div>
                      </div>
                 </div>
                <div className='col-span-6 border border-gray-300'>
                   <h2 className="text-base font-semibold leading-7 text-gray-900 text-center">supplemental</h2>


                   <label for="cover-photo" class="block text-sm font-medium leading-6 text-white">Cover photo</label>
                      <div class="mt-2 flex justify-center rounded-lg border border-dashed border-white/25 px-6 py-10">
                          <div class="text-center">
                          <div className="mt-4">
                            <svg class="mx-auto h-12 w-12 text-gray-500" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
                                  <path fill-rule="evenodd" d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z" clip-rule="evenodd" />
                              </svg>
                              </div>
                        
                          <div className="mt-4">
                          {convertDoneSupplemental?  <><svg aria-hidden="true" class="w-8 h-8 mx-auto text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" /><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" /></svg><span class="sr-only">Loading...</span></> :''}    
                              <label htmlFor="supplimental_fileupload" className="cursor-pointer relative rounded-md bg-indigo-500 font-semibold text-white focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 focus-within:ring-offset-gray-900">
                                  <span>Upload a file</span>
                                  <input
                                  id="supplimental_fileupload"
                                  name="supplimental_fileupload"
                                  type="file"
                                  className="sr-only"
                                  multiple
                                //    value={props.supplimental_fileupload}
                                  onChange={SupplementalhandleFileChange}
                                  />
                              </label>
                            
                
                              <p className="text-xs leading-5 text-gray-400">PNG, JPG, GIF up to 10MB</p>
                              </div>
                          </div>
                      </div>


                      <ul role="list" className="mx-auto grid max-w-2xl grid-cols-1 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                        {imgSupplemental.map((img, index) => (
                          <li key={index} className="relative">
                            {/* Container for image and delete button */}
                            <div className="relative">
                              {/* Image */}
                              <img  onClick={() => OpenImageSupplemental(img.imageUrl)} className="cursor-pointer aspect-[3/2] w-full rounded-2xl object-cover duration-300 transform hover:scale-125" src={img.imageUrl} alt="" />
                              {/* Delete button */}
                              <button type="button" onClick={() => removeItem(index)} className="absolute top-0 right-0 -mt-4 -mr-4 text-red-500 bg-white rounded-full p-2">
                                {/* Add a remove icon */}
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                              </button>
                            </div>
                          </li>
                        ))}
                      </ul>
 
                </div>
           </div>
            </div>
            <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8 uppercase">
                <button
                type="submit"
                className="uppercase rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                Save
                </button>
            </div>

        </form>

    </div>      
    {isCompletesubmit?
            <div role="status" class="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2" >
                <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                <span class="sr-only">Loading...</span>
            </div>   
        :''}


          <Transition.Root show={open} as={Fragment}>
                    <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                        <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:p-6">
                               <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                <button
                                    type="button"
                                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    onClick={() => setOpen(false)}
                                >
                                    <span className="sr-only">Close</span>
                                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                                </div>
                                <div>
                                    <div className="mt-3 text-center sm:mt-5">
                                         <h1 class="text-base font-semibold leading-6 text-gray-900 text-left " >Update Item</h1>
                                        <div className="mt-2">
                                      

                                        <div className="grid grid-cols-1">
                                          
                                            <div  className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2 ">
                                                <div className="px-4 py-6 sm:p-8">
                                                    <div className="grid grid-cols-1 ">
                                                
                                                        <div className="sm:col-span-2">
                                                            <label htmlFor="date_encoded" className="block text-sm font-medium leading-6 text-gray-900 text-left">
                                                              PARTICULAR / ITEM DESCRIPTION	
                                                            </label>
                                                            <div className="mt-2">
                                                                <input
                                                                type="text"
                                                                name="modalParticular"
                                                                id="modalParticular"
                                                                autoComplete="modalParticular"
                                                                value={modalParticular}
                                                                required
                                                                onChange={handleChange}
                                                            
                                                               
                                                            
                                                                className=" p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                />
                                                            </div>
                                                        </div>

                                                
                                                        <div className="sm:col-span-2">
                                                            <label htmlFor="date_encoded" className="block text-sm font-medium leading-6 text-gray-900 text-left">
                                                              Quantity
                                                            </label>
                                                            <div className="mt-2">
                                                                <input
                                                                type="text"
                                                                name="modalQuantity"
                                                                id="modalQuantity"
                                                                autoComplete="modalQuantity"
                                                                value={modalQuantity}
                                                                required
                                                                onChange={handleChange}
                                                            
                                                               
                                                            
                                                                className="uppercase p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                />
                                                            </div>
                                                        </div>


                                                             
                                                        <div className="sm:col-span-2">
                                                            <label htmlFor="date_encoded" className="block text-sm font-medium leading-6 text-gray-900 text-left">
                                                              Price
                                                            </label>
                                                            <div className="mt-2">
                                                                <input
                                                                type="text"
                                                                name="modalPrice"
                                                                id="modalPrice"
                                                                autoComplete="modalPrice"
                                                                value={modalPrice.toLocaleString()}
                                                                required
                                                                onChange={handleChange}
                                                            
                                                               
                                                            
                                                                className="uppercase p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                />
                                                            </div>
                                                        </div>


                                                        {(is_vat==1 )?
                                                        <div className="sm:col-span-2">
                                                            <label htmlFor="date_encoded" className="block text-sm font-medium leading-6 text-gray-900 text-left">
                                                              Vatable
                                                            </label>
                                                    

                                                            <div className="mt-2">
                                                            <select  
                                                              className="uppercase p-2.5 block w-full
                                                                rounded-md border-0 py-1.5 text-gray-900 
                                                                shadow-sm ring-1 ring-inset ring-gray-300
                                                                placeholder:text-gray-400 focus:ring-2
                                                                focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                name="modalVatclass"
                                                                value={modalVatclass}
                                                                onChange={handleChange}
                                                                required>
                                                                  
                                                                    <option value="VT">VT</option>
                                                                 
                                                                    <option value="NVT">NVT</option>
                                                                    {/* <option value="VT-EX">VT-EX</option>
                                                                    <option value="VT-Z">VT-Z</option> */}
                                                                  
                                                              </select>
                                                    

                                                        </div>
                                                        {(modalVatclass =='VT' && CheckTax_Withhold())?   
                                                        
                                                        <div className="sm:col-span-2">
                                                            <label htmlFor="date_encoded" className="block text-sm font-medium leading-6 text-gray-900 text-left">
                                                              Type of Purchase
                                                            </label>
                                                    

                                                            <div className="mt-2">
                                                            <select  
                                                              className=" p-2.5 block w-full
                                                                rounded-md border-0 py-1.5 text-gray-900 
                                                                shadow-sm ring-1 ring-inset ring-gray-300
                                                                placeholder:text-gray-400 focus:ring-2
                                                                focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                name="modalTypePurchase"
                                                                value={modalTypePurchase}
                                                                onChange={handleChange}
                                                                required>
                                                                  <option value="GOODS">Goods</option>
                                                          <option value="SERVICE">Service</option>
                                                          <option value="CAPITAL GOODS">Capital Goods</option>
                                                          <option value="GOODS OTHER THAN">Goods Other Than</option>
                                                                  
                                                              </select>
                                                    

                                                        </div>
                                                        
                                                        </div>: ''}
                                                        </div>:''}

                                                        </div>
                                                    </div>
                                            
                                                <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8 uppercase">
                                                    <button
                                                    type="submit"
                                                    onClick={() => SaveItemModal()}
                                                    className="uppercase rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                    >
                                                    Save
                                                    </button>
                                                </div>

                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
            
                            </Dialog.Panel>
                            </Transition.Child>
                        </div>
                        </div>
                    </Dialog>
         </Transition.Root>




          <Transition.Root show={openImage} as={Fragment}>
                    <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpenImage}>
                        <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
                               <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                <button
                                    type="button"
                                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    onClick={() => setOpenImage(false)}
                                >
                                    <span className="sr-only">Close</span>
                                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                                </div>
                                <div>
                                    <div className="mt-3 text-center sm:mt-5">
                            
                                        <div className="mt-2">
                                          
                                            {imageData && <img src={imageData} alt="Cached API Image" />} 
                                        </div>
                                    </div>
                                </div>
            
                            </Dialog.Panel>
                            </Transition.Child>
                        </div>
                        </div>
                    </Dialog>
         </Transition.Root>
         
    </>

    
   
  )
}
export default ReceiptEncodingCreate;