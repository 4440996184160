

import { CheckIcon } from '@heroicons/react/24/solid'
import {  useState,useEffect } from 'react'
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'
import './sweetbutton.css'; //
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom';
const VerifyPage =() =>{

    let { id } = useParams();
    const [ErrorNotSame, setErrorNotSame]= useState(false);

    const navigate = useNavigate();

    const [ErrorComplete, setErrorComplete]= useState(false);

    const [isFound, setisFound]= useState(false);

 
    const defaultFormFields = {

        newpassword: '',
        confirmpassword:''
    
  
      };
      const [formFields,setFormFields ] = useState(defaultFormFields);
      const { newpassword,confirmpassword } = formFields;


      const resetFormFields = () => {
          setFormFields(defaultFormFields);
          //('');
      };

      const [showPasswordButton, setShowPasswordButton] = useState(false);
      const [showConfirmPasswordButton, setshowConfirmPasswordButton] = useState(false);

      


      const handleChange = (event) => {
        const { name, value } = event.target;

        if(name==='newpassword'){

            if(value !=''){
                setShowPasswordButton(true);
            }else{
                setShowPasswordButton(false);
            }

        }
        
        if(name==='confirmpassword'){

            if(value !=''){
                setshowConfirmPasswordButton(true);
            }else{
                setshowConfirmPasswordButton(false);
            }

        }
     
        const formattedValue =  value;
        setFormFields({ ...formFields, [name]: formattedValue });
      };

      const [isPasswordVisible, setPasswordVisible] = useState(false);
      const [isConfirmPasswordVisible, setisConfirmPasswordVisible] = useState(false);


    const togglePassword = (value) => {

        if(value==='newpassword'){

             setPasswordVisible(prevState => !prevState);

        }
        
        if(value==='confirmpassword'){

            setisConfirmPasswordVisible(prevState => !prevState);
        }
        

    }
    const verify = async () => {

        
        setErrorComplete(false);
        setErrorNotSame(false);
        if(newpassword ==''|| confirmpassword==''){

            setErrorComplete(true);
            return;
        }

        if(newpassword != confirmpassword ){

            setErrorNotSame(true);
            return;

        }
       
    const postData = {
      "password": newpassword,
      "link_activation": id,
    };

    console.log(postData);
    const requestOptions = {
      method: 'POST',
      headers: 
      { 'Content-Type': 'application/json',
      'Accept': 'application/json', 
      },
      body: JSON.stringify(postData)
    };

    await fetch(process.env.REACT_APP_API_URL+'api/verify', requestOptions)
    .then( async response => 
      {
          console.log(response.status);
        // Check the status code
        if (response.status === 422) {
          // API call succeeded

          const data = await response.json();
      
          // handleData(data.message);
         
        }else{
          const data = await response.json();
          console.log(response);  

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Verified Successfully',
            showConfirmButton: false,
            timer: 1500
        })  
        navigate('/verified');
        }
      }
      
    )
    .then(data => {
      // Handle the response
      console.log(data);
    })
    .catch(error => {
      // Handle any errors
      console.error(error);
    });
        
      
    }

    useEffect( () => {
        Check_Verify();
       
    }, []); 

    const Check_Verify = async () => {


    
        const postData = {
        "link_activation": id,
        };


        const requestOptions = {
        method: 'POST',
        headers: 
        { 'Content-Type': 'application/json',
        'Accept': 'application/json', 
        },
        body: JSON.stringify(postData)
        };

        await fetch(process.env.REACT_APP_API_URL+'api/verify/check', requestOptions)
        .then( async response => 
        {
            console.log(response.status);
            // Check the status code
            if (response.status === 422) {
            // API call succeeded

            const data = await response.json();
        
            // handleData(data.message);
            
            }else{
            const data = await response.json();
            if(data.is_exist==false){
                setisFound(true)
            }
          
            }
        }
        
        )
        .then(data => {
        // Handle the response
        console.log(data);
        })
        .catch(error => {
        // Handle any errors
        console.error(error);
        });
            
      
    }
  return (
    <>
      <div>
        <div className="uppercase">
        {(isFound==false)?
          <main className='py-6'>
            <div className="px-4 ">
                <div className='grid grid-cols-12 gap-2'>
                    <div className='col-span-2'>
                        <p className='text-sm  px-4'>
                            CREATE A STRONG PASSWORD: USE A COMBINATION OF UPPERCASE AND LOWERCASE LETTERS, NUMBERS, SPECIAL CHARACTERS AND ATLEAST 8 CHARACTER. AVOID USING EASILY GUESSABLE INFORMATION LIKE YOUR NAME OR BIRTHDATE.
                        </p>
                    </div>
                    <div className='col-span-5'>
                        
                    <form className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                            <div className="px-4 py-6 sm:p-8">
                                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-12">
                                    <div className="sm:col-span-6 sm:col-start-1">
                                        <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">New Password</label>
                                        <div className="mt-2 relative">
                                            <input type={isPasswordVisible ? 'text' : 'password'}
                                            name="newpassword" 
                                            id="newpassword" 
                                            onChange={handleChange}
                                            autoComplete="newpassword" className="p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                            <button type="button" id="SHOWnewpassword" className="absolute inset-y-0 right-0 px-3 py-2" onClick={() => togglePassword('newpassword')} style={{ display: showPasswordButton ? 'block' : 'none' }}>
                                                Show
                                            </button>
                                        </div>
                                    </div>
                                    <div className="sm:col-span-6 sm:col-start-1">
                                        <label htmlFor="confirmpassword" className="block text-sm font-medium leading-6 text-gray-900">Confirm Password</label>
                                        <div className="mt-2 relative">
                                            <input  type={isConfirmPasswordVisible ? 'text' : 'password'}
                                             name="confirmpassword"
                                             onChange={handleChange}
                                              id="confirmpassword" autoComplete="confirmpassword" className="p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                            <button type="button" id="SHOWconfirmpassword" className="absolute inset-y-0 right-0 px-3 py-2" onClick={() => togglePassword('confirmpassword')} style={{ display: showConfirmPasswordButton ? 'block' : 'none' }}>
                                                Show
                                            </button>
                                        </div>
                                    </div>
                                    {(ErrorNotSame)?  
                                    <div className="col-span-full">                
                                        <div className="rounded-md bg-red-50 p-4 mt-2 mb-2" id="errorpass1">
                                            <div className="flex">
                                                <div className="flex-shrink-0">
                                                    <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z" clipRule="evenodd" />
                                                    </svg>
                                                </div>
                                                <div className="ml-3">
                                                    <h3 className="text-sm font-medium text-red-800">Please ensure that the confirmed password matches the entered password</h3>
                                                </div>
                                            </div>
                                        </div>
                 
                                    </div>:''
                                    }

                                    {(ErrorComplete)?  
                                            <div className="col-span-full">                
                                                <div className="rounded-md bg-red-50 p-4 mt-2 mb-2" id="errorpass1">
                                                    <div className="flex">
                                                        <div className="flex-shrink-0">
                                                            <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z" clipRule="evenodd" />
                                                            </svg>
                                                        </div>
                                                        <div className="ml-3">
                                                            <h3 className="text-sm font-medium text-red-800">Please complete the data</h3>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>:''
                                            }
                              
                                </div>
                                <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
                                    <button type="button" onClick={() => verify()} className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 uppercase">Save</button>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
              </div>
          </main> :''}
        </div>
        {(isFound)?
         <div className="rounded-md bg-yellow-50 p-4 mt-10">
         <div className="flex">
           <div className="flex-shrink-0">
             <ExclamationTriangleIcon aria-hidden="true" className="h-5 w-5 text-yellow-400" />
           </div>
           <div className="ml-3">
             <h3 className="text-sm font-medium text-yellow-800">Verification Expired</h3>
            
           </div>
         </div>
       </div>
       :''}
      </div>
    </>
   
  )
}
export default VerifyPage;