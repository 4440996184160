

import { UserContext } from '../../contexts/user.context'

import { useState,useEffect,useContext,Fragment,useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon  } from '@heroicons/react/24/outline'
import { formatAmount,formatTin,Address,SentenceCaseText,formatAmountDisplay } from '../../utils/ValidationField';


const ReceiptEncodingUpdate =(props) =>{


    const [allClass, setClass] = useState([]);
    const [isLoad, setLoad] = useState(true);
    const { company_id } = useContext(UserContext);

    const { is_vat } = useContext(UserContext);


    const [imageData, setImageData] = useState(null);
 

    const OpenImageSupplemental=(image) => {

        setOpen(true);
  
        setImageData(image);
      }

    const OpenImage=() => {

      setOpen(true);

      setImageData(props.formFields.imageData);
    }
    const cancelButtonRef = useRef(null)

    const [open, setOpen] = useState(false)

    useEffect( () => {

        LoadClassList();

    }, []); 


    const LoadClassList = async () => {



        const postData = {

          "company_id": company_id,
          "type": 'expense',
        };
       
        
        
        const requestOptions = {
          method: 'POST',
          headers: 
          { 'Content-Type': 'application/json',
          'Accept': 'application/json', 
           'Authorization': `Bearer ${JSON.parse(localStorage.getItem('jwtToken')).value}`, },
          body: JSON.stringify(postData)
        };
    
     await fetch(process.env.REACT_APP_API_URL+'api/setting/classification/list', requestOptions)
        .then( async response => 
          {
          
            // Check the status code
            if (response.status === 422) {
              // API call succeeded
  
             // const data = await response.json();
           
              // handleData(data.message);
            //    setNodata(false);
               
            }else{
              const data = await response.json();
     
              setClass(data.data); 

           
              
            }
          }
          
        )
        .then(data => {
          // Handle the response
       
      
          setLoad(true);
        })
        .catch(error => {
          // Handle any errors
       
        });
        setLoad(true);
    } 
    
    const [isCompletesubmit, setCompletesubmit] = useState(false);
  
    return (
    <>
    
    <div className={isCompletesubmit ? "opacity-20 grid grid-cols-1 gap-x-8 gap-y-8 pt-10" : "grid grid-cols-1 gap-x-8 gap-y-8 pt-10"}>
        <div className="px-4 sm:px-0">
            <h2 className="text-2xl font-semibold leading-7 text-gray-900">Update Entry</h2>
           
        </div>
        <form onSubmit={props.handleSubmit} className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2 ">
            <div className="px-4 py-6 sm:p-8">
            
            
            <div className=" md:grid lg:grid 2xl:grid gap-x-6 gap-y-8 sm:grid-cols-6 md:grid-cols-4 lg:grid-cols-12 xl:grid-cols-12 ">


            {(props.TransactionType=='sales')?
                <div className="sm:col-span-6 md:col-span-2 lg:col-span-2 xl:col-span-2 2xl:col-span-2 ">
                    <label htmlFor="selectype" className="block text-sm font-medium leading-6 text-gray-900">
                        Select Type
                    </label>
                    <div className="mt-2">
                        <select
                        id="selectype"
                        name="selectype"
                        autoComplete="selectype"
                        value={props.formFields.selectype}
                        required
                        onChange={props.handleChange}
                        className="uppercase p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        >
                            <option ></option>
                            <option value="expense">Expense</option>
                            <option value="sales">Sales</option>

                        
                        </select>
                    </div>
                </div> : ''}


                <div className="sm:col-span-6 md:col-span-4 lg:col-span-4 xl:col-span-4 2xl:col-span-4
                ">
                    <label htmlFor="seller" className="block text-sm font-medium leading-6 text-gray-900">
                        Seller
                    </label>
                    <div className="mt-2">
                        <input
                        type="text"
                        name="seller"
                        id="seller"
                        value={props.formFields.seller}
                        required
                        onChange={props.handleChange}
                   
                    
                        className=" p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>

                <div className="sm:col-span-4 md:col-span-2 lg:col-span-2 xl:col-span-2 2xl:col-span-2">
                    <label htmlFor="tin1" className="block text-sm font-medium leading-6 text-gray-900">
                        TIN
                    </label>

                    <div className="mt-2">
                    <input
                        type="text"
                        name="tin1"
                        id="tin1"
                        autoComplete="tin1"
                        value={props.formFields.tin1}
                        onChange={props.handleChange}
                      
                        maxlength="11"
                        required
                     
                        className=" p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />

                    </div>
                </div>
                <div className="sm:col-span-2 md:col-span-2 lg:col-span-2 xl:col-span-2 2xl:col-span-2">
                    <label htmlFor="tin2" className="block text-sm font-medium leading-6 text-gray-900 ">
                    BRANCH
                    </label>

                    <div className="mt-2">
                    <input
                        type="text"
                        name="tin2"
                        id="tin2"
                        maxlength="3"
                        autoComplete="tin2"
                        value={props.formFields.tin2}
                        required
                        onChange={props.handleChange}
                 
                        placeholder="001"
                        className=" p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />

                    </div>
                </div>
     
          
                <div className="sm:col-span-6 md:col-span-4 lg:col-span-4 xl:col-span-4 2xl:col-span-4
                ">
                    <label htmlFor="seller" className="block text-sm font-medium leading-6 text-gray-900">
                        Address
                    </label>
                    <div className="mt-2">
                        <input
                        type="text"
                        name="address"
                        id="address"
                        autoComplete="address"
                        value={props.address}
                        required
                        onChange={props.handleChange}
                        className=" p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>
                <div className="sm:col-span-6 md:col-span-3 lg:col-span-3 xl:col-span-3 2xl:col-span-3">
                        <label htmlFor="classification" className="block text-sm font-medium leading-6 text-gray-900">
                            Classification
                        </label>
                        <div className="mt-2">
                        
                            <select  
                            className=" p-2.5 block w-full
                            rounded-md border-0 py-1.5 text-gray-900 
                            shadow-sm ring-1 ring-inset ring-gray-300
                            placeholder:text-gray-400 focus:ring-2
                            focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 "
                            name="classification"
                            value={props.classification}
                            onChange={props.handleChange}
                            required>
                                <option></option>
                                {allClass.map((Classification) => (
                                    <option key={Classification.id} value={Classification.class}>
                                    
                                    {Classification.class}
                                    </option>
                                ))}
                            </select>
                
                        
                        </div>
                    </div>
                    <div className="sm:col-span-6 md:col-span-2 lg:col-span-3 xl:col-span-2 2xl:col-span-2
                ">
                    <label htmlFor="TxtDate" className="block text-sm font-medium leading-6 text-gray-900">
                      DATE
                    </label>
                    <div className="mt-2">
                        <input
                      type="date"
                      name="TxtDate"
                      value={props.formFields.TxtDate}
                      onChange={props.handleChange}
                 
                        required
                        className="uppercase p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                    
                </div>
                <div className="sm:col-span-6 md:col-span-2 lg:col-span-3 xl:col-span-2 2xl:col-span-2
                ">
                    <label htmlFor="seller" className="block text-sm font-medium leading-6 text-gray-900">
                    INVOICE NO
                    </label>
                    <div className="mt-2">
                        <input
                         type="text"
                         name="TxtOrNum"
                         id="TxtOrNum"
                         autoComplete="TxtOrNum"
                        value={props.formFields.TxtOrNum}
                        onChange={props.handleChange}
                        required
                        className=" p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                    
                </div>

                <div className='col-span-full '>
                <hr className="w-full h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700"/>
                        <div className="">
                        <div className="sm:flex sm:items-center ">
                            <div className="sm:flex-auto">
                                <div class="md:grid lg:grid xl:grid 2xl:grid grid-cols-12 gap-4">
                   
                                                              <div class="sm:col-span-8 md:col-span-6 lg:col-span-6 xl:col-span-4 2xl:col-span-4">
                                    <label htmlFor="tin1" className="block text-sm font-medium leading-6 text-gray-900">
                                   
                                    Particular / Item Description
                                        </label>

                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                name="TxtParticular"
                                                id="TxtParticular"
                                                autoComplete="TxtParticular"
                                                value={props.formFields.TxtParticular}
                              
                                                onChange={props.handleChange}
                                                className=" p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                />

                                        </div>
                                </div>
                                <div className="sm:col-span-8 md:col-span-4 lg:col-span-4 xl:col-span-2 2xl:col-span-2 ">
                                    <label htmlFor="tin1" className="block text-sm font-medium leading-6 text-gray-900">
                                        quantity
                                        </label>

                                        <div className="mt-2">
                                            <input
                                                type="number"
                                                name="TxtQuantity"
                                                id="TxtQuantity"
                                                autoComplete="TxtQuantity"
                                                value={props.formFields.TxtQuantity}
                             
                                                onChange={props.handleChange}
                                                className=" p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                />

                                        </div>
                                </div>
                                <div className="sm:col-span-8 md:col-span-4 lg:col-span-4 xl:col-span-2 2xl:col-span-2 ">
                                    <label htmlFor="tin1" className="block text-sm font-medium leading-6 text-gray-900">
                                        price
                                        </label>

                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                name="TxtPrice"
                                                id="TxtPrice"
                                                autoComplete="TxtPrice"
                                                value={props.formFields.TxtPrice}
        
                                                onChange={props.handleChange}
                                                className=" p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                />

                                        </div>
                                </div>
                                {(is_vat==1)?
                                <div className="sm:col-span-8 md:col-span-4 lg:col-span-2 xl:col-span-2 2xl:col-span-1 ">
                                    <label htmlFor="price" className="block text-xs lg:text-xs xl:text-sm 2xl:text-sm font-medium leading-6 text-gray-900">
                                        Vatable
                                        </label>

                                        <div className="mt-2">
                                              <select  
                                                className=" p-2.5 block w-full
                                                  rounded-md border-0 py-1.5 text-gray-900 
                                                  shadow-sm ring-1 ring-inset ring-gray-300
                                                  placeholder:text-gray-400 focus:ring-2
                                                  focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                  name="TXTVTclassification"
                                                  value={props.formFields.TXTVTclassification}
                                                  onChange={props.handleChange}
                                                  required>
                                                  <option value="VT">VT</option>
                                                  <option value="NVT">NVT</option>
                                                  
                                                    
                                                    
                                                    
                                                </select>
                                      

                                          </div>
                                </div>:''}
                                <div className="sm:col-span-8 md:col-span-4 lg:col-span-6 xl:col-span-6 2xl:col-span-3 ">

                         
                                    <div className="flex">
                           

                                      {(is_vat==1 && props.CheckTax_Withhold())?
                                        <>
                                          {(props.formFields.TXTVTclassification =='VT')?

                                            <div className='w-9/12'>
                                                                                  
                                            <div >
                                            
                                                <label htmlFor="tin1" className="block text-xs lg:text-xs xl:text-sm 2xl:text-sm font-medium leading-6 text-gray-900">
                                                Type Purchase
                                                  </label>

                                                  <div className="mt-2">
                                                      <select  
                                                        className=" p-2.5 block w-full
                                                          rounded-md border-0 py-1.5 text-gray-900 
                                                          shadow-sm ring-1 ring-inset ring-gray-300
                                                          placeholder:text-gray-400 focus:ring-2
                                                          focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                          name="TypePurchase"
                                                          value={props.TypePurchase}
                                                          onChange={props.handleChange}
                                                          required>
                                                          <option value="GOODS">Goods</option>
                                                          <option value="SERVICE">Service</option>
                                                          <option value="CAPITAL GOODS">Capital Goods</option>
                                                          <option value="GOODS OTHER THAN">Goods Other Than</option>  
                                                            
                                                            
                                                        </select>
                                              

                                                  </div>
                                              </div>
                                            </div> :''

                                           }
                                   
                                        </>
                                     
                                        
                                      
                                      
                                      :''}
                              
                                        <div className='mt-6 ml-2 w-1/4'>
                                          <button
                                              type="button" onClick={props.AddTable}
                                              className="block rounded-md  bg-green-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 uppercase"
                                          >
                                              Add
                                          </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                           
                        </div>
                        <div className="mt-8 flow-root">
                            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                <table className="min-w-full divide-y divide-gray-300 ">
                                <thead>
                                    <tr>
                                
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Particular / item description
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Quantity
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Price
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Total
                                    </th>
                                    {(is_vat==1)? 
                                    <>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                         Vatable
                                    </th>
                                    {(props.CheckTax_Withhold())? 
                                       <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                       Type Purchase
                                  </th>
                                    :''}
                                  
                                    </>
                                     : ''}
                                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
                                        <span className="sr-only">Edit</span>
                                    </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white">
                                    {props.listItem.map((item, index) => (
                                            <tr key={index}>
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                               
                                                {item.particular}
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.qty}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">₱{formatAmountDisplay(item.price)}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">₱{formatAmountDisplay(item.total)}</td>
                                                {(is_vat==1)?  
                                               <>
                                               
                                               <td className={`whitespace-nowrap px-3 py-4 text-sm ${item.vat_class === '' && 'bg-gray-500' || item.vat_class === 'NVT' && 'bg-gray-500'}  border-white border w-16`}>{(item.vat_class !='NVT')? item.vat_class: ''}</td>
                                               {(props.CheckTax_Withhold())?
                                                 <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 capitalize">{(item.type_purchase !=null && item.type_purchase !='')? item.type_purchase.toLowerCase() :item.type_purchase}</td>:
                                                 ''
                                            }
                                             
                                               </>
                                                : ''}     
                                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                                <a onClick={() => props.UpdateItem(
                                                  index,
                                                  item.particular,
                                                  item.qty,
                                                  item.price.toLocaleString(),
                                                  item.total.toLocaleString(),
                                                  item.vat_class,
                                                  item.type_purchase
                                                  )}>
                                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer	">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                                                  </svg>

                                                </a>
                                            

                                            </td>
                                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                               
                                             
                                                <a onClick={() => props.deleteItem(index)}>
                                                <svg   xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer	">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                    </svg>
                                                </a>
                                            


                                                </td>
                                            </tr>
                                            ))}
                                </tbody>
                                </table>
                            </div>
                            </div>
                        </div>
                        </div>
                        <hr className="w-full h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700"/>

                </div>
                {(props.TransactionType=='sales')?
  
                <div className='col-span-4'>
                    <div className="col-span-4">
                        <label htmlFor="vat_sales" className="block text-sm font-medium leading-6 text-gray-900">
                            VAT Sales
                        </label>

                        <div className="mt-2">
                        <input
                            type="text"
                            name="vat_sales"
                            id="vat_sales"
                            autoComplete="vat_sales"
                            value={(props.formFields.vat_sales !=null)? props.formFields.vat_sales.toLocaleString() : ''}
                            readOnly
                            onChange={props.handleChange}
                            className="text-right uppercase p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />

                    
                        </div>
                

                    </div>

                    <div className="col-span-4">
                        <label htmlFor="vat_exempt" className="block text-sm font-medium leading-6 text-gray-900">
                        VAT Exempt
                        </label>

                        <div className="mt-2">
                        <input
                            type="text"
                            name="vat_exempt"
                            id="vat_exempt"
                            autoComplete="vat_exempt"
                            readOnly
                            value={(props.formFields.vat_exempt !=null)? props.formFields.vat_exempt.toLocaleString() : ''}
                            onChange={props.handleChange}
                            className="text-right uppercase p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />

                    
                        </div>
                

                    </div>
         
                    <div className="col-span-4">
                        <label htmlFor="vat_zero_rated" className="block text-sm font-medium leading-6 text-gray-900">
                        VAT Zero Rated
                        </label>

                        <div className="mt-2">
                        <input
                            type="text"
                            name="vat_zero_rated"
                            id="vat_zero_rated"
                            autoComplete="vat_zero_rated"
                            readOnly
                            value={(props.formFields.vat_zero_rated !=null)? props.formFields.vat_zero_rated.toLocaleString() : ''}
                            onChange={props.handleChange}
                            className="text-right uppercase p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />

                    
                        </div>
                

                    </div>
                    <div className="col-span-4">
                        <label htmlFor="vat_amount" className="block text-sm font-medium leading-6 text-gray-900">
                            VAT Amount
                        </label>

                        <div className="mt-2">
                        <input
                            type="text"
                            name="vat_amount"
                            id="vat_amount"
                            autoComplete="vat_amount"
                            value={(props.formFields.vat_amount !=null)? props.formFields.vat_amount.toLocaleString() : ''}
                            readOnly
                            onChange={props.handleChange}
                            className="text-right uppercase p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />

                    
                        </div>
                

                    </div>
                    <div className="col-span-4">
                        <label htmlFor="total_amount_due" className="block text-sm font-medium leading-6 text-gray-900">
                            Total Amount Due
                        </label>

                        <div className="mt-2">
                        <input
                            type="text"
                            name="total_amount_due"
                            id="total_amount_due"
                            autoComplete="total_amount_due"
                            value={props.formFields.total_amount_due}
                            readOnly
                            onChange={props.handleChange}
                            className="text-right uppercase p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />

                    
                        </div>
                   
                 </div>



                    
                </div>                    

              :''}
                                            
                <div className='col-span-3 '>
                   <div className='grid grid-cols-12 mb-2'>
                   {(is_vat==1  && props.CheckTax_Withhold())?

                        <>
                        <div className='col-span-12 mb-2'>
                            <label htmlFor="vat_sales" className="block text-sm font-medium leading-6 text-gray-900">
                                Non Vat Purchase
                            </label>

                            <div className="mt-2">
                            <input
                                type="text"
                                name="NonVatPurchase"
                                id="NonVatPurchase"
                                autoComplete="NonVatPurchase"
                                value={props.NonVatPurchase}
                                readOnly
                                onChange={props.handleChange}
                                className="text-right font-bold uppercase p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />

                        
                            </div>
                          </div>
                        <div className='col-span-12 mb-2'>
                            <label htmlFor="vat_sales" className="block text-sm font-medium leading-6 text-gray-900">
                                Vat Purchase
                            </label>

                            <div className="mt-2">
                            <input
                                type="text"
                                name="VatPurchase"
                                id="VatPurchase"
                                autoComplete="VatPurchase"
                                value={props.VatPurchase}
                                readOnly
                                onChange={props.handleChange}
                                className="text-right font-bold uppercase p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />

                        
                            </div>
                          </div>
                        <div className='col-span-12 mb-2'>
                            <label htmlFor="vat_sales" className="block text-sm font-medium leading-6 text-gray-900">
                               Input Tax
                            </label>

                            <div className="mt-2">
                            <input
                                type="text"
                                name="TotalInputTax"
                                id="TotalInputTax"
                                autoComplete="TotalInputTax"
                                value={'₱'+formatAmountDisplay(props.TotalInputTax)}
                                readOnly
                                onChange={props.handleChange}
                                className="text-right font-bold uppercase p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />

                        
                            </div>
                        </div> 

                      
                        </>
                        :''}
                        <div className='col-span-12'>
                            <label htmlFor="vat_sales" className="block text-sm font-medium leading-6 text-gray-900">
                                    Total 
                                </label>

                                <div className="mt-2">
                                <input
                                    type="text"
                                    name="TotalAmountItem"
                                    id="TotalAmountItem"
                                    autoComplete="TotalAmountItem"
                                    value={'₱'+formatAmountDisplay(props.TotalAmountItem)}
                                    readOnly
                                    onChange={props.handleChange}
                                    className="text-right font-bold uppercase p-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />

                            
                                </div>
                        </div>
                    </div>                       
                </div>
       
            </div>

         

            <div className='grid grid-cols-12 gap-2'>
                <div className='col-span-6 border border-gray-300'>
                    <h2 className="text-base font-semibold leading-7 text-gray-900 text-center">INVOICE RECEIPT</h2>
                    <div class="col-span-full">
                    <label for="cover-photo" class="block text-sm font-medium leading-6 text-white">Cover photo</label>
                    <div class="mt-2 flex justify-center rounded-lg border border-dashed border-white/25 px-6 py-10">
                       
                    <div class="text-center ">

                        {props.selectedFile && (
                            <div className="mt-4 mx-auto">

                            <img
                                src={URL.createObjectURL(props.selectedFile)}
                                alt="Uploaded preview"
                                className="h-40 "
                            />
                            </div>
                        )}

                        {!props.selectedFile && ( <a  onClick={() => OpenImage()}> <img
                        src={props.formFields.imageData}
                        alt="Uploaded preview"
                        className="cursor-pointer h-40 duration-300 transform hover:scale-125"
                        /> </a>  )}
                                            
                        <div className="mt-4">
                        {props.convertDone?  <><svg aria-hidden="true" class="w-8 h-8 mx-auto text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" /><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" /></svg><span class="sr-only">Loading...</span></> :''}    
                            <label htmlFor="fileupload" className="cursor-pointer relative rounded-md bg-indigo-500 font-semibold text-white focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 focus-within:ring-offset-gray-900">
                                <span>Upload a file</span>
                                <input
                                id="fileupload"
                                name="fileupload"
                                type="file"
                                className="sr-only"
                                value={props.fileupload}
                                onChange={props.handleFileChange}
                                />
                            </label>
                          
              
                            <p className="text-xs leading-5 text-gray-400">PNG, JPG, GIF up to 10MB</p>
                            </div>
                        </div>
                    </div>
                 </div>
                </div>   
                <div className='col-span-6 border border-gray-300'>
                     <h2 className="text-base font-semibold leading-7 text-gray-900 text-center">supplemental</h2>


                     <label for="cover-photo" class="block text-sm font-medium leading-6 text-white">Cover photo</label>
                      <div class="mt-2 flex justify-center rounded-lg border border-dashed border-white/25 px-6 py-10">
                          <div class="text-center">
                          <div className="mt-4">
                            <svg class="mx-auto h-12 w-12 text-gray-500" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
                                  <path fill-rule="evenodd" d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z" clip-rule="evenodd" />
                              </svg>
                              </div>
                        
                          <div className="mt-4">
                          {props.convertDoneSupplemental?  <><svg aria-hidden="true" class="w-8 h-8 mx-auto text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" /><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" /></svg><span class="sr-only">Loading...</span></> :''}    
                          <label htmlFor="supplimental_fileupload" className="cursor-pointer relative rounded-md bg-indigo-500 font-semibold text-white focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 focus-within:ring-offset-gray-900">
                                  <span>Upload a file</span>
                                  <input
                                  id="supplimental_fileupload"
                                  name="supplimental_fileupload"
                                  type="file"
                                  className="sr-only"
                                  multiple
                                //    value={props.supplimental_fileupload}
                                
                                  onChange={props.SupplementalhandleFileChange}
                                  />
                              </label>
                            
                
                              <p className="text-xs leading-5 text-gray-400">PNG, JPG, GIF up to 10MB</p>
                              </div>
                          </div>
                      </div>
                      <ul role="list" className="mx-auto grid max-w-2xl grid-cols-1 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                        {props.supplemental_item.map((img, index) => (
                          <li key={index} className="relative">
                            {/* Container for image and delete button */}
                            <div className="relative">
                              {/* Image */}
                              <img  onClick={() => OpenImageSupplemental(img.imageUrl)} className="cursor-pointer aspect-[3/2] w-full rounded-2xl object-cover duration-300 transform hover:scale-125" src={img.imageUrl} alt="" />
                              {/* Delete button */}
                              <button type="button" onClick={() => props.removeItem(index)} className="absolute top-0 right-0 -mt-4 -mr-4 text-red-500 bg-white rounded-full p-2">
                                {/* Add a remove icon */}
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                              </button>
                            </div>
                          </li>
                        ))}
                      </ul> 

                </div>
             </div>   
           
            </div>
            <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8 uppercase">
                <button
                type="submit"
                className="uppercase rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                Save
                </button>
            </div>

        </form>
        {props.isErrorShow?
         <div class="rounded-md bg-red-50 p-4 ">
            <div class="flex">
                <div class="flex-shrink-0">
                <svg class="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z" clip-rule="evenodd" />
                </svg>
                </div>
                <div class="ml-3">
                <div class="mt-2 text-sm text-red-700">
                    <ul role="list" class="list-disc space-y-1 pl-5 uppercase">
                    {/* <li>Your password must be at least 8 characters</li> */}
                    <li>{props.isErrorPasswordText}</li>
                    </ul>
                </div>
                </div>
            </div>
        </div>
        :''}
        
    </div>      
   
    {props.isCompletesubmit?
            <div role="status" class="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2" >
                <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                <span class="sr-only">Loading...</span>
            </div>   
        :''}


<Transition.Root show={open} as={Fragment}>
                    <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                        <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
                               <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                <button
                                    type="button"
                                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    onClick={() => setOpen(false)}
                                >
                                    <span className="sr-only">Close</span>
                                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                                </div>
                                <div>
                                    <div className="mt-3 text-center sm:mt-5">
                            
                                        <div className="mt-2">
                                          
                                            {imageData && <img src={imageData} alt="Cached API Image" />} 
                                        </div>
                                    </div>
                                </div>
            
                            </Dialog.Panel>
                            </Transition.Child>
                        </div>
                        </div>
                    </Dialog>

                    
         </Transition.Root>
         
            <Transition.Root show={open} as={Fragment}>
                    <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                        <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
                               <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                <button
                                    type="button"
                                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    onClick={() => setOpen(false)}
                                >
                                    <span className="sr-only">Close</span>
                                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                                </div>
                                <div>
                                    <div className="mt-3 text-center sm:mt-5">
                            
                                        <div className="mt-2">
                                          
                                            {imageData && <img src={imageData} alt="Cached API Image" />} 
                                        </div>
                                    </div>
                                </div>
            
                            </Dialog.Panel>
                            </Transition.Child>
                        </div>
                        </div>
                    </Dialog>
         </Transition.Root>
    </>
   
  )
}
export default ReceiptEncodingUpdate;